.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--primary500);
}

.adminContent {
  height: 100%;
  margin-block: 10rem;
}

.button {
  gap: 12px;
  padding: 10px 24px;
}

.headingStyle {
  background-color: transparent;
  color: var(--grey900);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
}

.suppportStyle {
  max-width: none;
}

@media screen and (max-width: 500px) {
  .content {
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
  }
}
