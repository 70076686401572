.addServices {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--grey700);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  margin-bottom: 24px;
}

.addServicesButton {
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
  background-color: var(--white);
  border: 1px solid var(--primary500);
  border-radius: 32px;
  color: var(--primary500);
  font-size: var(--font-size-md);
  line-height: var(--line-height-15);
  padding: 8px 24px;
  stroke: var(--primary500);
}

.addServicesButton:disabled {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  stroke: var(--grey900);
}

.addServicesButton:disabled.addServicesButton:hover {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  cursor: auto;
  filter: none;
}

.addServicesButton:hover {
  background-color: var(--primary50);
  border-color: var(--primary500);
  filter: none;
}

.customInputsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputCustomService {
  color: var(--grey900);
}

.trashButton {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 34px;
  min-width: 34px;
  max-height: 34px;
  min-height: 34px;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 50%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px;
}

.trashIcon {
  min-width: 18px;
  min-height: 18px;
  flex-shrink: 0;
  stroke: var(--grey900);
  stroke-width: 1px;
}

.customInput {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 0;
  align-items: center;
  padding: 0;
}

.serviceAlreadyExist {
  color: var(--red500);
  font-size: 12px;
  margin-bottom: 24px;
}
