.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px auto;
  align-items: center;
  width: 750px;
  max-height: calc(100% - 64px);
  color: var(--grey700);
  line-height: var(--line-height-16);
  overflow: visible;
  position: fixed;
}

.modalContent::-webkit-scrollbar {
  width: 8px;
}

.dragAndDrop {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modalContentWrapper {
  display: flex;
  flex-direction: column;
  margin: 32px auto 16px auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 8px;
}

.modalContentWrapper::-webkit-scrollbar {
  width: 8px;
}

.mediaTypes {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 32px;
}

.addBtn {
  align-self: center;
  padding: 8px 32px;
}

.cancelBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  cursor: pointer;
  outline: none;
  padding: 8px 32px;
}

.cancelBtn:hover {
  background-color: var(--grey100);
}

.infoIcon {
  width: var(--icon-small);
  aspect-ratio: 1/1;
}

.helperText {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 0;
  align-items: center;
  color: var(--grey700);
  font-size: var(--font-size-xs);
}

.progressWrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  left: 0;
  position: fixed;
  top: 0;
  z-index: 10;
}

.error {
  margin-top: 0.5rem;
  color: var(--red500);
  font-size: var(--font-size-sm);
}
@media screen and (max-width: 768px) {
  .modalContent {
    justify-content: flex-start;
    margin: 0 auto;
    align-items: flex-start;
    width: 100%;
    height: 95dvh;
    max-height: 100%;
    box-sizing: border-box;
    margin-bottom: 48px;
    overflow-y: scroll;
    position: fixed;
    top: 80px;
  }
}

@media screen and (max-width: 450px) {
  .modalContentWrapper {
    margin-top: 1rem;
    height: auto;
  }

  .modalContent {
    top: 4rem;
  }
}
