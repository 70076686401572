.wrapper {
  width: 100%;
  overflow: visible;
  position: relative;
}

.wrapper::-webkit-scrollbar {
  width: 4px;
}

.list {
  width: 100%;
  max-height: 180px;
  background-color: var(--white);
  border: 1px solid var(--primary400);
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  -moz-box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  left: 0;
  overflow-y: auto;
  position: absolute;
  top: 110%;
  z-index: 1;
}

.list::-webkit-scrollbar {
  display: none;
}

.list::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: var(--grey400);
  border-radius: 4px;
  padding-top: 12px;
}

.element {
  color: var(--grey800);
  cursor: pointer;
  padding: 14px 16px;
}

.noUserClick {
  pointer-events: none;
}

.element:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 14px;
}

.element:hover {
  background-color: var(--primary50);
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--grey600);
  position: absolute;
  right: 20px;
  top: 42%;
  transition: all 0.3s ease-in-out;
}

.invertedIcon {
  color: var(--primary500);
  transform: rotate(180deg) translateY(4px) translateX(2px);
  transition: all 0.3s ease-in-out;
}

.selectContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  position: relative;
}

.disabled {
  stroke: var(--grey400);
}

.adminChevron.invertedIcon {
  display: block;
  color: var(--adminOrange500);
}
