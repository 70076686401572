.wrapper {
  display: flex;
  flex-direction: row;
  gap: 10%;
  justify-content: center;
  margin: 0 130px 130px;
}

.title {
  max-width: 100%;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  font-weight: var(--font-weight-regular);
}

.formTitle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.optionalInformation {
  margin-top: 12px;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
}

.leftSide,
.rightSide {
  min-height: 500px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;
}

.rightSide {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-height: 300px;
  align-self: center;
  flex: 0.6;
}

.form {
  max-width: 85%;
}

.input {
  max-width: 100%;
}

.phoneNumberContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.prefix {
  width: clamp(90px, 10%, 100px);
}

.phoneValue {
  flex: 5;
}

@media screen and (max-width: 1300px) {
  .title,
  .form {
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin-top: 30px;
    align-items: flex-start;
    margin-inline: 42px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    margin-inline: 16px;
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    margin: 0;
    margin-bottom: 60px;
  }

  .rightSide {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin: 0;
  }

  .leftSide,
  .rightSide {
    min-height: auto;
  }
  
  .leftSide {
    margin-left: 0;
  }

  .title {
    max-width: 100%;
    font-size: var(--font-size-md);
  }

  .form {
    max-width: 100%;
  }

  .inputs {
    max-width: 100%;
  }

  .formTitle {
    max-width: 100%;
  }

  .optionalInformation {
    max-width: 100%;
  }

  .phoneNumberContainer {
    gap: 8px;
  }

  .prefix {
    flex: 2;
  }

  .phoneValue {
    flex: 4;
  }
}
