.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
}

.row:last-child {
  margin-bottom: 0px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  background-color: var(--grey100);
  border: 1px solid var(--grey300);
  border-radius: 50%;
  cursor: pointer;
  stroke: var(--black);
  stroke-width: 1.2px;
  transform: translateY(35%);
}

.input {
  width: 170px;
  margin-right: 10px;
}

.noUserClick {
  cursor: none;
}

.tooltipStyle {
  width: 200px;
  background-color: var(--white);
  border-radius: 6px;
  bottom: 20px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  z-index: 20;
}

.binIcon {
  width: var(--icon-regular);
  height: var(--icon-regular);
}

.plusIcon {
  width: var(--icon-xsmall);
  height: var(--icon-xsmall);
}

@media screen and (max-width: 450px) {
  .input {
    width: 7.5rem;
  }
}
