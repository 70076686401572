.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: transparent;
  position: relative;
}

.text {
  margin: 0 auto 2rem 0;
  width: 100%;
  color: var(--grey900);
  font-size: var(--display-xs);
  font-weight: var(--font-weight-semi);
  text-align: center;
}

.loader {
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
  z-index: 1;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: 0 8px 32px rgba(17, 17, 26, 0.05), 0 4px 16px rgba(47, 47, 141, 0.09);
  flex-shrink: 0;
  padding: 32px;
}

.subInfoBox,
.checkbox {
  display: flex;
  align-items: flex-start;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  margin-block: 0.5rem;
  margin-right: auto;
}

.checkbox {
  gap: 0.5rem;
  width: fit-content;
}

.checkboxStyle {
  width: 1rem;
  height: 1rem;
  background-color: var(--white);
  border: 1px solid var(--secondary500);
  border-radius: 5px;
  cursor: pointer;
  margin-right: auto;
}

.checkIconStyle {
  transform: translate(2px);
}

.actionBoxContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
  width: 100%;
}

.goBack,
.signInButton,
.remindPassword {
  background-color: var(--grey50);
  border: 1px solid transparent;
  color: var(--grey900);
  padding: 12px 24px;
}

.goBack {
  border-color: var(--grey400);
}

.goBack:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.signInButton {
  background-color: var(--primary500);
  color: var(--white);
  cursor: pointer;
}

.signInButton:hover {
  background-color: var(--primary600);
}

.signInButton:disabled {
  background-color: var(--primary300);
  cursor: auto;
}

.remindPassword {
  margin-top: 4px;
  background-color: transparent;
  color: var(--primary500);
  font-size: var(--font-size-sm);
  margin-block: 1rem;
  padding: 0;
}

.remindPassword:hover,
.remindPassword:focus-visible {
  background-color: transparent;
  color: var(--primary600);
}

.standAloneWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    height: calc(100vh - 60px);
  }
}

@media screen and (max-width: 450px) {
  .text {
    font-size: var(--font-size-xl);
    margin-bottom: 3rem;
  }

  .subInfoBox {
    margin: 0.75rem 0 1.25rem 0;
  }

  .form {
    margin-top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .wrapper {
    align-items: flex-start;
    height: calc(100svh - 80px);
    padding: 2rem 1rem 0 1rem;
  }

  .actionBoxContainer {
    margin: auto 0 2rem 0;
  }
}
