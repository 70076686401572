.wrapper {
  background-color: var(--white);
  border: 1px solid var(--grey100);
  padding: 20px 30px;
}

.seoCard {
  color: var(--grey600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.cardName {
  display: flex;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.arrowBackIcon {
  width: 1.25rem;
  min-width: 1rem;
  height: 1.25rem;
  color: var(--grey800);
  cursor: pointer;
  stroke-width: 2px;
}

.rowContainer {
  display: flex;
  gap: 0.75rem;
  align-items: flex-end;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
}

.tabContainer {
  display: flex;
  gap: 1.5rem;
  margin-top: 10px;
  margin-left: 2.75rem;
}

.tab {
  border-bottom: 2px solid transparent;
  color: var(--grey800);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 6px;
  padding-left: 0;
  text-wrap: nowrap;
}

.activeTab {
  border-color: var(--primary500);
  color: var(--primary500);
}

.noName {
  color: var(--grey600);
}

.cardInformation {
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    padding: 20px;
  }

  .tabContainer {
    overflow-x: scroll;
  }

  .tabContainer::-webkit-scrollbar {
    display: none;
  }

  .cardName {
    font-size: var(--font-size-md);
  }
}
