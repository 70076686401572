.body {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.service {
  color: var(--grey600);
  font-size: 12px;
}

.serviceName {
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  margin-bottom: 24px;
}

.servicePriceBox {
  display: flex;
  flex-direction: column;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.serviceDescription {
  margin: 24px auto 24px auto;
  height: 150px;
}

.descriptionLength {
  bottom: 50px;
  color: var(--grey600);
  font-size: 12px;
  font-weight: 400;
  left: 540px;
  position: relative;
}

.confirmButton {
  display: flex;
  align-self: flex-end;
  border-radius: 32px;
  font-size: var(--font-size-md);
  line-height: var(--line-height-15);
  padding: 10px 24px;
}

.rwdDeleteServiceBtn {
  display: none;
  margin-top: 0;
  width: fit-content;
  background-color: transparent;
  color: var(--primary500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 24px;
}

.rwdDeleteServiceBtn:hover {
  background-color: transparent;
}

.actionBox {
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
}

.cancelButton {
  display: flex;
  align-self: flex-end;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 32px;
  color: var(--grey900);
  font-size: var(--font-size-md);
  line-height: var(--line-height-15);
  padding: 10px 24px;
}

.cancelButton:hover {
  background-color: var(--grey100);
  border: 1px solid var(--grey500);

}
@media screen and (max-width: 768px) {
  .confirmButton {
    width: 100%;
  }

  .cancelButton {
    width: 100%;
  }

  .rwdDeleteServiceBtn {
    display: flex;
  }
}
@media screen and (max-width: 450px) {
  .rowContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .actionBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .cancelButton {
    display: none;
    width: 100%;
    background-color: var(--grey50);
    border: 1px solid var(--grey400);
    color: var(--grey900);
  }

}
