.wrapper {
  display: flex;
  gap: 24px;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    gap: 24px;
  }
}
@media screen and (max-width: 450px) {
  .wrapper {
    gap: 16px;
  }
}
