.wrapper {
  display: grid;
  gap: 1rem;
  margin-top: 1.5rem;
  max-width: 80%;
  grid-template-columns: 1fr 1fr 1fr;
}

.select,
.dateInput {
  flex: 1;
}

@media screen and (max-width: 1400px) {
  .wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    max-width: none;
    margin-bottom: 1rem;
  }
}
