.wrapper {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.servicePill {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: fit-content;
  background-color: var(--white);
  border: 1px solid var(--secondary500);
  border-radius: 32px;
  color: var(--grey800);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  padding: 7px 16px;
  word-break: keep-all;
}

.selectedService {
  background-color: var(--secondary50);
  border: 1px solid var(--secondary500);
  color: var(--grey800);
}

.noUserEvents {
  cursor: auto;
  user-select: none;
}

.subTitle {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  margin-bottom: 12px;
}

.moreHoursExistWrapper {
  margin-bottom: 24px;
}

.tooltipStyle {
  width: 200px;
  background-color: var(--white);
  border-radius: 6px;
  bottom: 20px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  z-index: 20;
}

.displayNone {
  display: none;
}