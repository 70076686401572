.title {
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
}

.address {
  color: var(--grey600);
  font-size: var(--font-size-sm);
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}