.subMenuItem,
.subMenuItem > a {
  display: flex;
  align-items: center;
  color: var(--grey800);
  cursor: pointer;
  line-height: var(--line-height-15);
  padding: 14px 16px;
  user-select: none;
}

.subMenuItem > a {
  padding: 0;
  text-decoration: none
}

.icon {
  width: 18px;
  height: 18px;
  color: var(--grey800);
}

.subMenuItem:hover {
  background-color: var(--primary50);
  overflow: hidden;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(4px);
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1;
}
