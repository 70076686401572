.keyword {
  display: flex;
  flex-direction: column;
}

.keywordName {
  max-width: 90%;
  color: var(--grey900);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.keywordValue {
  color: var(--secondary500);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  text-wrap: nowrap;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
