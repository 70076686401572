.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
}

.subTitle {
  color: var(--grey700);
}

@media screen and (max-width: 450px) {
  .title {
    font-size: var(--font-size-lg);
  }

  .subTitle {
    font-size: var(--font-size-sm);
  }
}
