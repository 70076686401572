.initWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8% 130px;
  height: 100%;
  box-sizing: border-box;
}

.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 2;
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex: 1;
}

.title {
  align-self: flex-start;
  color: var(--grey800);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
  margin-bottom: 16px;
}

.subTitle {
  color: var(--grey800);
  font-size: var(--font-size-md);
  line-height: var(--line-height-15);
  margin-bottom: 14px;
  white-space: pre-wrap;
}

.actionsWrapper {
  display: flex;
  gap: 18px;
  margin-top: 32px;
  align-items: center;
}

.goDashboardBtn {
  width: 100%;
  max-width: 200px;
  background-color: transparent;
  border: none;
  color: var(--primary500);
}

.goDashboardBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.createBusinessProfileBtn {
  width: 100%;
  max-width: 220px;
  padding: 12px 24px;
}

.svgImage {
  width: 100%;
  height: 100%;
}

.svgBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.rwdBtn {
  display: none;
}

.importBtn {
  margin-top: 12px;
  background-color: transparent;
  color: var(--primary500);
  font-size: var(--font-size-sm);
  margin-right: auto;
  padding-left: 0;
}

.importBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.navigateToImport {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  align-items: center;
  color: var(--primary500);
  cursor: pointer;
  font-size: var(--font-size-sm);
  margin-right: auto;
}

.navigateToImport:hover {
  color: var(--primary600);
}

@media screen and (max-width: 1400px) {
  .initWrapper {
    margin-inline: 10%;
  }

  .actionsWrapper {
    gap: 4px;
  }
}

@media screen and (max-width: 1024px) {
  .initWrapper {
    margin-inline: 42px;
  }
}

@media screen and (max-width: 768px) {
  .initWrapper {
    flex-direction: row;
    gap: 20px;
    margin: 0;
    width: 100%;
  }

  .rightSide {
    flex: 1;
  }
}

@media screen and (max-width: 650px) {
  .initWrapper {
    flex-direction: column;
    gap: 0;
    margin-top: 0;
  }

  .importBtn {
    display: none;
  }

  .navigateToImport {
    display: none;
  }

  .actionsWrapper {
    display: none;
  }

  .leftSide {
    justify-content: flex-start;
    flex: 1;
  }

  .rightSide {
    margin-top: 32px;
  }

  .svgImage {
    max-height: 150px;
  }

  .rwdNavigation {
    display: flex;
  }

  .rwdActionBox {
    width: 100%;
    flex: 1;
  }

  .rwdBtn {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-inline: auto;
  }

  .subTitle {
    white-space: normal;
  }
}

@media screen and (max-width: 450px) {
  .initWrapper {
    flex-direction: column;
    gap: 0;
    margin-top: 0;
  }

  .leftSide {
    justify-content: flex-start;
    margin-top: 0;
    flex: auto;
    margin-left: 0;
  }

  .subTitle {
    width: 100%;
    max-width: 100%;
    margin-bottom: 12px;
    white-space: normal;
  }

  .actionsWrapper,
  .title {
    display: none;
  }

  .rwdBtn {
    display: flex;
    max-width: 100%;
    margin-inline: auto;
    z-index: 1;
  }

  .rwdNavigation {
    display: flex;
  }

  .rightSide {
    flex-direction: column;
    margin-bottom: 32px;
  }
}
