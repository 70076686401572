.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 40px;
}

.addCardBtn {
  gap: 0.5rem;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.addCardBtn:hover {
  background-color: var(--adminOrange700);
}

.searchInput {
  height: 40px;
  border: 1px solid var(--adminGreyNormalActive);
  border-radius: 8px;
}

.leftSide {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-basis: 40%;
}

.filterBtn {
  gap: 0.5rem;
  max-height: 40px;
  background-color: var(--white);
  border: 1px solid var(--adminGreyNormalActive);
  border-radius: 0.5rem;
  color: var(--adminGreyDarkActive);
  padding: 10px 16px;
}

.filterBtn:hover {
  background-color: var(--white);
  border-color: var(--adminGreyDark);
  color: var(--adminGreyDarker);
}
