.cardContainer {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  background-color: var(--grey50);
  border-radius: 6px;
  box-sizing: border-box;
  font-weight: var(--font-weight-medium);
  padding-block: 1rem;
  text-align: left;
}

.cardContainer aside {
  color: var(--grey800);
  font-weight: var(--font-weight-semi);
  margin-bottom: 0.5rem;
  padding-bottom: 6px;
}

.payuCardForm {
  background-color: var(--white);
  border: 1px solid var(--grey300);
  border-radius: 6px;
  font-size: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
}
