.modalWrapper {
  max-width: 30vw;
}

.modalTitleStyle {
  color: var(--adminRed500);
}

.customIconStyle {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  color: var(--black);
}

.customIconStyle:hover {
  background-color: transparent;
}

.contentWrapper {
  border: 1px solid var(--adminGreyNormal);
  border-left: none;
  border-right: none;
  color: var(--black);
  font-weight: var(--font-weight-medium);
  margin-block: 1rem;
  padding-block: 1rem;
  text-align: justify;
  white-space: pre-wrap;
}

.confirmBtn {
  width: fit-content;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  margin-left: auto;
  padding: 0.5rem 1.5rem;
}

.confirmBtn:hover {
  background-color: var(--adminOrange700);
}
