.wrapper {
  background-color: var(--grey50);
  border-radius: 6px;
  padding: 2rem;
}

.title {
  color: var(--grey900);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

.changeDataBtn {
  background-color: transparent;
  border: none;
  color: var(--primary500);
  font-size: var(--font-size-sm);
  padding: 0;
}

.changeDataBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.select {
  margin-block: 1.5rem;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    padding: 1rem;
  }
}
