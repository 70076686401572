.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.spinner {
  width: 56px;
  height: 56px;
  animation: spinner 1s infinite linear;
  background: radial-gradient(farthest-side, #fe8130 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #fe8130);
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
}

@keyframes spinner {
  100% {
    transform: rotate(1turn);
  }
}
