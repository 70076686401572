.modelWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 320px;
  border: 1px solid var(--grey300);
  border-radius: 1.5rem;
  box-shadow: 0px 5px 27px 0px #64646f14;
  box-sizing: border-box;
  padding: 1.5rem;
}

.popularWrapper {
  border: 2px solid var(--secondary500);
}

.featureTextBold {
  font-weight: var(--font-weight-semi);
}

.modelTitle {
  background-color: var(--primary50);
  border-radius: 6px;
  color: var(--primary500);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
  padding: 10px 26px;
}

.modelTitle.popular {
  background-color: var(--secondary50);
  color: var(--secondary500);
}

.row {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
}

.comingSoonWrapper > .button {
  margin-top: 0;
  background-color: var(--primary300);
  cursor: initial;
  pointer-events: none;
  user-select: none;
}

.comingSoonWrapper .popularLabel {
  display: none;
}

.comingSoonWrapper .row,
.comingSoonWrapper .subTitle,
.comingSoonWrapper .price,
.comingSoonWrapper .modelFeaturesList {
  opacity: 0.6;
}

.popularLabel {
  background-color: var(--secondary500);
  border-radius: 6px;
  color: var(--white);
  font-size: var(--font-size-xs);
  padding: 0.5rem 0.75rem;
}

.subTitle {
  margin: 1rem 0 28px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}

.price {
  color: var(--primary900);
  font-size: var(--display-lg);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-14);
}

.price > span {
  color: var(--grey600);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

.button {
  margin-top: auto;
  width: 100%;
  background-color: var(--primary500);
  border-radius: 8px;
  color: var(--white);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  padding: 0.75rem 2rem;
}

.button.popularBtn {
  background-color: var(--secondary500);
}

.button.popularBtn:hover {
  background-color: var(--secondary600);
}

.modelFeaturesList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 3rem 0 4.375rem 0;
}

.featureText {
  display: flex;
  gap: 14px;
  align-items: center;
  color: var(--primary900);
  font-size: var(--font-size-sm);
}

.checkIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  background-color: var(--primary500);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
}

.checkIconWrapper.popular {
  background-color: var(--secondary500);
}

.checkIconWrapper > svg {
  min-width: 1rem;
  transform: translate(10%, 20%);
}

.featureText > p {
  margin: 0;
}

.comingSoon {
  margin-top: auto;
  color: var(--primary500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 0.5rem;
  z-index: 2;
}

.popularWrapper .comingSoon {
  color: var(--secondary500);
}

.comingSoonWrapper .popularBtn {
  background-color: var(--secondary200);
}

.comingSoonWrapper.popularWrapper {
  border-color: var(--secondary200);
}

.showDetailsBtn {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.25rem;
  margin-top: 2rem;
  align-items: center;
  width: fit-content;
  background-color: transparent;
  color: var(--primary500);
  padding: 0;
  stroke-width: 0.5px;
}

.showDetailsBtn:hover {
  background-color: transparent;
  border: none;
  color: var(--primary500);
}

.chevronExpanded {
  transform: rotate(180deg);
}

.displayNone {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.showDetails {
  height: fit-content;
  opacity: 1;
  transition: all 0.3s ease;
}
@media screen and (max-width: 600px) {
  .modelWrapper {
    width: auto;
    max-width: none;
    box-sizing: border-box;
    margin-inline: 1rem;
  }

  .modelFeaturesList {
    margin-bottom: 60px;
  }

  .mobileWrapper {
    margin: 0;
  }

  .button {
    margin-top: 2rem;
    padding: 6px 16px;
  }
}
