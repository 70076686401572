.gridItem:not(:first-child)::before {
  width: 100%;
  height: 12rem;
  background-color: var(--primary50);
  border-radius: 0;
  content: '';
  left: 0;
  position: absolute;
  top: -4rem;
  z-index: -1;
}

.gridItem:nth-last-of-type(-n + 5)::before {
  height: 8rem;
  border-radius: 0 0 9px 9px;
}

.gridItem {
  max-width: 140px;
  background-color: var(--primary50);
  border-bottom: 1px solid var(--primary300);
  box-sizing: border-box;
  margin-block: 1.5rem;
  position: relative;
  text-align: center;
  z-index: 1;
}

.checkIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  background-color: var(--primary500);
  border-radius: 100%;
  color: var(--white);
  margin-inline: auto;
  position: relative;
}

.checkIcon {
  transform: translate(10%, 20%);
}

.minusIconWrapper {
  width: 1.5rem;
  color: var(--primary800);
  margin-inline: auto;
}

.cardTitle,
.gridCardItem {
  margin-top: 3rem;
}

.googleBusienssCards,
.seoCards {
  color: var(--grey900);
  font-size: var(--display-xs);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
  margin-bottom: 2.5rem;
  position: absolute;
  top: 8rem;
}

.seoCards {
  margin: 0;
  position: static;
}

@media screen and (max-width: 1440px) {
  .gridItem {
    margin-top: 2rem;
    width: auto;
  }
}
