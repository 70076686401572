.wrapper {
  display: flex;
  flex-direction: column;
  margin: 24px 32px;
  height: calc(100vh - 240px);
  background-color: var(--white);
  border-radius: 12px;
  box-sizing: border-box;
  margin-right: 16px;
  padding: 24px;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
}

.listWrapper::-webkit-scrollbar {
  width: 4px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}

.mobileList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.loaderWrapper {
  backdrop-filter: blur(4px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

.checkboxStyle {
  display: flex;
  gap: 6px;
  width: 16px;
  height: 16px;
  border: 1px solid var(--secondary500);
  border-radius: 6px;
  overflow: hidden;
}

.checkboxWrapper {
  display: flex;
  justify-content: flex-start;
  margin-right: 12px;
}

.checkbox {
  display: flex;
  gap: 8px;
  width: 1rem;
  max-width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
  border-radius: 0.25rem;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  margin-right: 2rem;
}

.pagination {
  width: 100%;
}

.pill {
  background-color: var(--green50);
  border: 1px solid var(--green500);
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
}

.cell {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-right: auto;
  text-transform: capitalize;
}

.duplicate {
  background-color: var(--grey50);
  border-color: var(--grey600);
  color: var(--grey900);
}

.suspended {
  background-color: var(--yellow50);
  border-color: var(--yellow500);
  color: var(--yellow700);
}

.notVerified {
  background-color: var(--red50);
  border-color: var(--red500);
  color: var(--red700);
}

.external {
  background-color: transparent;
  border-color: transparent;
}

.checkIcon {
  width: 16px;
  transform: translate(1px, 1px);
}

.icon {
  width: 18px;
  height: 18px;
  background-color: var(--grey100);
  color: var(--grey900);
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 38px;
  max-height: 38px;
  aspect-ratio: 1/1;
  background-color: var(--grey100);
  border: 1px solid var(--grey400);
  border-radius: 50%;
  box-sizing: border-box;
}

.businessCard {
  max-width: 90%;
  overflow: hidden;
}

.processing {
  background-color: var(--grey50);
  border-color: var(--grey600);
  color: var(--grey900);
}

.actions {
  display: flex;
  gap: .5rem;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin: 0;
    height: calc(100svh - 220px);
    border-radius: 0;
    padding: 16px;
  }

  .listWrapper {
    height: calc(100% - 80px);
  }
}

@media screen and (max-width: 450px) {
  .listWrapper {
    height: calc(100% - 152px);
  }

  .header {
    padding: 0;
  }

  .title {
    font-size: var(--font-size-md);
    line-height: var(--line-height-16);
  }

  .disconnectBtn {
    width: 100%;
  }
}