.wrapper {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    color: var(--secondary500);
    width: 2rem;
    height: 2rem;
}

.title {
    margin: 0 1.5rem 0 0.75rem;
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-semi);
    line-height: var(--line-heiht-12);
    color: var(--adminOrange500);
}

.assistantStatusPill {
    background-color: var(--red50);
    color: var(--red500);
    border-radius: 2.125rem;
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-regular);
    padding: 0.25rem 0.75rem;
}

.active {
    background-color: var(--green50);
    border: 0.1px solid var(--green400);
    color: var(--green600);
}

.header {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.header>h3 {
    font-size: var(--display-xs);
    font-weight: var(--font-weight-semi);
    line-height: var(--display-sm);
    text-align: center;
}

.header>h3>span {
    color: var(--primary500);
}