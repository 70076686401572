.modalContent {
  max-width: 50%;
}

.actionsWrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
  align-items: flex-end;
}

.reasonInformation {
  margin: 1rem 0 1.5rem;
  color: var(--black);
  font-weight: var(--font-weight-medium);
}

.textArea {
  margin-top: 0.25rem;
  min-height: 10rem;
  padding-top: 1rem;
}

.actionsWrapper > * {
  background-color: transparent;
  border: 1px solid var(--adminOrange600);
  border-radius: 0.5rem;
  color: var(--adminOrange600);
  cursor: pointer;
  padding: 0.5rem 1.5rem;
}

.actionsWrapper > *:hover {
  background-color: transparent;
  border-color: var(--adminOrange700);
  color: var(--adminOrange700);
}

.actionsWrapper > *:disabled {
  background-color: var(--adminGreyNormalHover);
  border-color: var(--adminGreyLight);
  color: var(--adminGreyDarkHover);
}

.loaderWrapper {
  height: auto;
  margin-block: 4rem;
}

.saveBtn {
  background-color: var(--adminOrange600);
  color: var(--white);
}

.saveBtn:hover {
  background-color: var(--adminOrange700);
  color: var(--white);
}

.saveBtn:disabled:hover {
  background-color: var(--adminGreyNormalHover);
  border-color: var(--adminGreyLight);
  color: var(--adminGreyDarkHover);
}

.desactivateModalTitleStyle {
  color: var(--adminRed500);
}

.closeIcon {
  background-color: transparent;
  border: none;
  color: var(--black);
}
