.questionnaire {
    padding: 4rem 2rem 2rem;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 1.125rem;
    box-shadow: var(--shadowMD);
    position: relative;
}

.headerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semi);
    line-height: var(--line-height-15);
    padding-bottom: 0.75rem;
}

.questionnaireSection {
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.question {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-15);
}

.radioWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 1rem;
}

.radio {
    display: flex;
    align-items: center;
}

.radioText {
    color: var(--grey600);
    padding-left: 1rem;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
}

.radioInput {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    column-gap: 0.5rem;
    border: 0.063rem solid var(--adminOrange500);
    border-radius: 1rem;
    height: 2.25rem;
    line-height: var(--line-height-16);
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.circle {
    width: 1rem;
}

.keywordsWrapper {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
}

.addBtn {
    background-color: var(--adminOrange500);
    height: 2.5rem;
}

.addBtn:hover {
    background-color: var(--adminOrange400);
}


.pill {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
    border: 0.063rem solid var(--secondary500);
    border-radius: 2rem;
    color: var(--grey800);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    padding: 0.375rem 1rem;
}

.closeIcon {
    width: 0.875rem;
    height: 0.875rem;
    fill: var(--grey600);
}

.closeIcon:hover {
    fill: var(--grey800);
    filter: scale(1.2);
}

.labelsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-top: 0.5rem;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
}

.backButton {
    color: var(--grey900);
    background-color: var(--grey100);
    padding: 10px 24px;
    border: 1px solid var(--grey400);
    min-width: 11rem;
}

.backButton:hover {
    background-color: var(--grey200);
}

.submitButton {
    background-color: var(--secondary500);
    column-gap: 0.5rem;
    padding: 10px 24px;
}

.submitButton:disabled {
    background-color: var(--secondary200);
    cursor: not-allowed;
}

.submitButton:hover:not(:disabled) {
    background-color: var(--secondary600);
}

.deleteBtn {
    background-color: transparent;
    color: var(--red500);
    font-size: var(--font-size-sm);
    padding: 0;
    margin-right: auto;
}

.deleteBtn:hover {
    background-color: transparent;
    color: var(--red600);
}

.overlay {
    position: fixed;
    inset: 0;
    backdrop-filter: blur(2px);
    z-index: 2;
}