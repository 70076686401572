.indicator {
  margin-right: 12px;
  position: absolute;
}

.body {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.pin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--primary50);
  border: 2px solid var(--primary300);
  border-radius: 50%;
  color: var(--primary500);
  position: absolute;
}

.mapWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 264px;
  aspect-ratio: 1.1;
  border: 1px solid var(--grey200);
  border-radius: 15px;
  flex: 1;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  user-select: none;
}

.map {
  margin: 10px;
  width: 100%;
  height: 360px;
  aspect-ratio: 1.1;
  object-fit: cover;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
@media screen and (max-width: 450px) {
  .body {
    flex-direction: column;
  }

  .inputs {
    order: 1;
  }
}
