.plannedBox {
  display: flex;
  gap: 24px;
}

.date,
.time {
  flex: 1 0 1;
}

.error {
  margin-top: 12px;
  color: var(--red500);
  font-size: var(--font-size-sm);
}

.date {
  flex: 4;
}

.timeInput {
  gap: 8px;
  flex: 3;
}

@media screen and (max-width: 450px) {
  .plannedBox {
    flex-direction: column;
    height: 54px;
  }
}