.container {
  min-height: 450px;
}

.container:has(.buttonDetailContainer) .buttonSelect {
  margin-bottom: 16px;
}

.sectionName {
  margin-top: 24px;
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  margin-bottom: 16px;
}

.sectionName:first-of-type {
  margin-top: 30px;
}

.sectionName:last-of-type {
  margin: 0;
}

.input {
  margin: 0;
  margin-bottom: 16px;
}

.eventDescription {
  margin: 0;
  margin-top: 1rem;
  min-height: 200px;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 0.75rem;
}

.sectionBox {
  margin: 0;
  min-height: 210px;
  position: relative;
}

.textLength {
  bottom: 30px;
  color: var(--grey600);
  font-size: 12px;
  font-weight: 400;
  left: 550px;
  position: relative;
}

.customTimeInput {
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.customInput::-webkit-calendar-picker-indicator {
  display: none;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.timeInput {
  margin: 0;
  flex: 3;
}

.dateInput {
  margin: 0;
  height: 44px;
  border: 1px solid var(--grey300);
  flex: 5;
}

.buttonSelect {
  margin: 0;
  margin-bottom: 24px;
}

.rowContainer:last-of-type {
  margin-bottom: 0;
}

.chevronStyle {
  top: 22px;
  transition: all 0.3s ease-in-out;
}

.error {
  margin: 0;
  color: var(--red500);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.body:has(.error) .rowContainer .dateInput {
  margin-bottom: 4px;
}

.dragAndDrop {
  margin-bottom: 16px;
}

.aiWrapper {
  height: 100%;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  z-index: 2;
}

.aiWrapperOpen {
  position: static;
}

@media screen and (max-width: 450px) {
  .rowContainer {
    flex-direction: column;
    gap: 16px;
  }

  .timeInput {
    flex: auto;
  }

  .dateInput {
    flex: auto;
  }
}
