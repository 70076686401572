.modalContent {
  max-width: 40vw;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 0 0;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.actions > * {
  background-color: var(--adminOrange500);
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.actions > *:hover {
  background-color: var(--adminOrange600);
}

.cancelBtn {
  background-color: transparent;
  border: 1px solid var(--adminOrange500);
  color: var(--adminOrange500);
}

.cancelBtn:hover {
  background-color: transparent;
  border-color: var(--adminOrange600);
  color: var(--adminOrange600);
}

.clientCancelBtn {
  background-color: var(--grey100);
  border: 1px solid var(--grey300);
  border-radius: 2rem;
  color: var(--grey900);
  padding: 0.25rem 1rem;
}

.clientCancelBtn:hover {
  background-color: var(--grey200);
  border-color: var(--grey400);
  color: var(--grey900);
}

.clientConfirmBtn {
  background-color: var(--primary500);
  border-radius: 2rem;
}

.clientConfirmBtn:hover {
  background-color: var(--primary600);
}

@media screen and (max-width: 768px) {
  .modalContent {
    max-width: 100vw;
  }
}