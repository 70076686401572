.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 13.375rem);
  align-content: center;
}

.headingStyle {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
}

.supportingTextStyle {
  width: 100%;
  max-width: none;
  color: var(--grey600);
  font-size: var(--font-size-regular);
}

.button {
  gap: 8px;
  margin-top: 1.5rem;
  width: fit-content;
  border: 1px solid var(--primary500);
  margin-inline: auto;
  padding: 10px 24px;
}

.button:disabled {
  border: none;
}

@media screen and (max-width: 450px) {
  .button {
    font-size: var(--font-size-sm);
    padding: 10px 16px;
  }

  .headingStyle {
    font-size: var(--font-size-lg);
  }

  .supportingTextStyle {
    font-size: var(--font-size-regular);
  }
}
