.content {
  max-width: 40vw;
}

.modalTitleStyle {
  width: 100%;
  border-bottom: 1px solid var(--adminGreyNormal);
  color: var(--black);
  font-family: var(--manrope);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  padding-bottom: 1rem;
}

.input {
  height: 40px;
}

.inputContainer {
  position: relative;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-bottom: 1px solid var(--adminGreyNormal);
  margin-block: 1.5rem;
}

.savePasswordBtn {
  display: flex;
  width: fit-content;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  margin-left: auto;
  padding: 0.5rem 1.5rem;
}

.savePasswordBtn:hover {
  background-color: var(--adminOrange700);
}

.savePasswordBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.customIconStyle {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
}

.customIconStyle:hover {
  background-color: transparent;
}

.inputTitle {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 4px;
}

.eyeIcon {
  width: 16px;
  height: 16px;
  color: var(--adminGreyDarkActive);
  cursor: pointer;
  position: absolute;
  right: 3%;
  top: 55%;
}

.passwordHelper {
  display: flex;
  gap: 5px;
  align-items: center;
  align-self: flex-start;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  margin-bottom: 24px;
}

.infoIcon {
  width: 16px;
  height: 16px;
  color: var(--grey600);
  stroke: var(--grey600);
}

.loaderWrapper {
  height: 10rem;
}
