.rowTitle {
  color: var(--grey700);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-16);
  margin-bottom: 12px;
}

.statusIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.rowValue {
  margin: 0;
  margin-left: 24px;
  padding: 0;
}

.rowItem {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.rowItemName {
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  white-space: nowrap;
}

.navigateBtn {
  cursor: pointer;
}

.rowItemValue {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  margin-left: 8px;
}

.statusIcon.positiveValue {
  color: var(--green500);
}

.statusIcon.negativeValue {
  color: var(--red500);
}

@media screen and (max-width: 768px) {
  .rowItemName {
    white-space: wrap;
  }
}
