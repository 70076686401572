.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  pointer-events: none;
}

.input {
  display: none;
  cursor: pointer;
}

.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: all;
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--white);
  transform: translate(10%, 20%);
}

.input:checked ~ .checkbox {
  background-color: var(--secondary500);
}

.input:checked ~ .checkbox .icon {
  display: block;
  color: var(--white);
}

.noUserClick {
  cursor: auto;
  pointer-events: none;
}
