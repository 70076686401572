.wrapper {
  margin: 2rem;
}

.redirectLinks {
  margin: 2rem;
  max-width: 50vw;
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.linksWrapper {
  margin: 0 2rem;
  max-width: 50%;
  left: 2rem;
}

.rowWrapper {
  max-width: 70%;
  margin-left: 2rem;
}

.clientLink > * {
  font-weight: 400;
}
