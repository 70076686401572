.subscriptionDetails {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
}

.twoColumns {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
}

.value {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
  text-wrap: nowrap;
}

.value span {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
}

@media screen and (max-width: 768px) {
  .subscriptionDetails {
    gap: 2.5rem;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    row-gap: 1.5rem;
  }
}
@media screen and (max-width: 600px) {
  .subscriptionDetails > :first-child {
    grid-column: 1 / -1;
  }
}
@media screen and (max-width: 450px) {
  .subscriptionDetails {
    row-gap: 1rem;
  }
}
