.linkStyle {
  background-color: var(--primary500);
  border-radius: 2rem;
  color: var(--white);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  padding: 0.625rem 1.5rem;
  text-decoration: none;
}

.linkStyle:hover {
  background-color: var(--primary600);
}
