.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background-color: var(--adminOrange100);
  border-radius: 50%;
}

.icon {
  width: 36px;
  height: 36px;
  color: var(--adminOrange600);
  transform: translate(0.25rem, 0.5rem);
}

.text {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  align-items: center;
  max-width: 68%;
  color: var(--black);
  font-weight: var(--font-weight-semi);
  text-align: center;
}
