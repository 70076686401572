.sectionWrapper {
  display: flex;
  gap: 130px;
  justify-content: center;
  max-width: 70vw;
  margin-inline: auto;
  padding: 100px 70px 120px 120px;
}

.featureList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.selectedFeture {
  display: flex;
  flex-direction: column;
}

.sectionName {
  margin: 0 0 1rem 0;
  color: var(--primary500);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.sectionHeading {
  margin: 0;
  max-width: 85%;
  margin-bottom: 40px;
}

.image {
  max-width: 600px;
}

.leftSide {
  width: 100%;
  max-width: clamp(300px, 50%, 600px);
  min-width: 50%;
}

.mobileFeatureListWrapper {
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 1440px) {
  .sectionWrapper {
    gap: 5rem;
  }

  .image {
    max-width: 60%;
  }
}
@media screen and (max-width: 1024px) {
  .featureList,
  .image {
    display: none;
  }

  .sectionWrapper,
  .sectionHeading {
    max-width: none;
  }

  .sectionName {
    font-size: var(--font-size-lg);
  }

  .sectionHeading {
    font-size: var(--display-xs);
    margin-bottom: 0;
  }

  .sectionWrapper {
    flex-direction: column;
    gap: 60px;
    padding-top: 80px;
  }

  .leftSide {
    max-width: 490px;
  }
}

@media screen and (max-width: 768px) {
  .mobileFeatureListWrapper {
    display: flex;
    flex-direction: column;
  }

  .sectionWrapper {
    gap: 5rem;
    padding-inline: 3rem;
  }
}

@media screen and (max-width: 450px) {
  .sectionWrapper {
    gap: 3rem;
    padding-inline: 1rem;
  }

  .sectionName {
    font-size: var(--font-size-sm);
  }

  .sectionHeading {
    font-size: var(--font-size-lg);
    margin-bottom: 0;
  }
}
