.wrapper {
  border: 2px solid var(--primary300);
  border-radius: 16px;
  box-shadow: 0px 1px 3px 0px #1018281a;
  box-sizing: border-box;
  cursor: pointer;
  padding: 1rem;
  position: relative;
}

.wrapper.highlighted {
  background-color: var(--primary50);
  border: 2px solid var(--primary300);
  margin-right: 0.75rem;
  overflow: visible;
}

.length {
  color: var(--secondary500);
  font-size: 14px;
  font-weight: 500;
}

.visibilityText {
  font-weight: 500;
}

.row {
  display: flex;
  flex-direction: row;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: flex-start;
}

.types {
  font-size: var(--font-size-sm);
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.rating {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 0.5rem;
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  -webkit-line-clamp: 1;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.address {
  color: var(--grey600);
  font-size: 14px;
}

.noImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 100%;
  background-color: var(--gray200);
  border-radius: 16px;
  margin-right: 24px;
}

.stars {
  margin-left: 8px;
}

.visibility {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 8px;
  align-items: center;
  border-top: 1px solid var(--primary200);
  color: var(--grey900);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
  padding: 8px 0px;
}

.pill {
  background-color: var(--green50);
  border: 1px solid var(--green400);
  border-radius: 2rem;
  box-sizing: border-box;
  color: var(--green800);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regulr);
  margin-bottom: 8px;
  padding: 4px 12px;
}

.horizontalLine {
  margin-top: 22px;
  width: 100%;
  height: 1px;
  background-color: var(--grey200);
  margin-bottom: 8px;
  position: absolute;
  right: 0;
}

.visibilityRWD {
  display: none;
}

@media screen and (max-width: 1110px) {
  .visibility {
    margin-top: 12px;
    border-top: none;
    overflow: hidden;
    padding-top: 0;
  }
}

@media screen and (max-width: 1200px) {
  .info {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 1024px) {
  .image {
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    margin-right: 12px;
  }

  .wrapper {
    padding: 12px;
  }

  .rowContainer {
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .info {
    width: 100%;
    margin-left: 12px;
  }

  .checkIconWrapper {
    width: 24px;
    height: 24px;
    bottom: 92%;
    left: 95%;
    overflow: visible;
  }

  .visibilityRWD {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    margin-top: 12px;
    align-items: center;
    border-top: none;
    color: var(--grey600);
    font-size: 14px;
    padding: 8px 0px;
    padding-top: 0;
  }

  .visibility {
    display: none;
  }

  .pill {
    font-weight: 400;
    margin-bottom: 0;
  }

  .visibilityRWDPlacement {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    font-size: var(--font-size-sm);
  }

  .visibilityRWDText {
    flex: 1;
    white-space: nowrap;
  }

  .mobilePill {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .image {
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;
  }

  .mobilePill {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .rating {
    order: 1;
  }

  .info {
    width: 100%;
  }

  .name {
    font-size: var(--font-size-md);
    margin-bottom: 5px;
  }

  .wrapper {
    max-height: 150px;
    background: var(--white);
    border: 2px solid var(--primary300);
    border-radius: 16px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    box-sizing: content-box;
    overflow: hidden;
    padding: 12px;
    padding-bottom: 20px;
  }

  .checkIconWrapper {
    width: 24px;
    height: 24px;
    bottom: 92%;
    left: 90%;
    overflow: visible;
    transform: translateX(50%);
  }

  .pill {
    display: none;
  }

  .mobilePill {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    word-break: keep-all;
  }

  .wrapper.highlighted {
    margin-right: 0;
  }
}
