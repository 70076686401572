

.monthName {
  font-weight: var(--font-weight-bold);
}

.monthNameWrapper {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  cursor: initial;
  margin-bottom: 24px;
}

.monthNameWrapper:nth-of-type(2) {
  border-left: 1px solid var(--grey300);
  padding-left: 24px;
}
