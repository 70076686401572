.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 2rem;
}

.priceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--grey900);
  flex-wrap: wrap;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
  row-gap: 1rem;
}

.consentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  border-bottom: 1px solid var(--grey200);
  padding-bottom: 2rem;
}

.btn {
  margin-top: 1.5rem;
  padding: 10px 1.5rem;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
}

.consent {
  display: flex;
  gap: 0.5ch;
  align-items: center;
}

.checkboxStyle {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
}

.checkbox {
  display: flex;
  gap: 8px;
  margin: 0;
  color: var(--grey700);
  cursor: pointer;
  font-size: var(--font-size-sm);
  white-space: pre-wrap;
}

.checkIconStyle {
  display: none;
  transform: translate(1.25px, 0.5px);
}

.pricePeriod {
  color: var(--grey600);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

.taxIncluded {
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
}

.termsOfService a {
  color: var(--primary500);
  cursor: pointer;
  font-weight: var(--font-weight-semi);
  pointer-events: all;
  text-decoration: none;
}

.termsOfService a:hover {
  color: var(--primary600);
}

.aboutCyclicMethod {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}

.loaderWrapper {
  height: auto;
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

.cyclicSubInfo {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
  margin-block: 0;
  padding-block: 0;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-block: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .btn {
    width: 100%;
  }


}