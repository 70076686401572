.wrapper {
  display: flex;
  flex-direction: column;
  margin: 24px 24px 0 24px;
  background-color: var(--white);
  border-radius: 16px;
  flex: 1;
  font-family: var(--poppins);
  overflow: hidden;
  padding: 24px;
  position: relative;
}

.wrapper::-webkit-scrollbar {
  width: 8px;
}

.wrapper::-webkit-scrollbar-track {
  background: var(--grey100);
  border-radius: 8px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: var(--grey300);
  border-radius: 8px;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  left: 0;
  position: fixed;
  top: 0;
  z-index: 5;
}

.RWDTable {
  display: none;
}

.export {
  height: 44px;
  padding-left: 25px;
  padding-right: 25px;
}

.keywordWrap {
  display: block;
  max-width: 150px;
  height: fit-content;
  background-color: var(--primary50) !important;
  border: none;
  box-sizing: border-box;
  color: var(--primary900);
  overflow: hidden;
  padding: 4px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.positionPill {
  padding: 2px 12px;
}

.downloadIcon {
  margin-left: auto;
}

.adminPreview {
  margin: 0 1.5rem 0.5rem 1.5rem;
  border-radius: 0;
  overflow: hidden;
  padding: 0;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 420px);
  flex: 1;
  margin-bottom: 12px;
}

.adminPreview::-webkit-scrollbar {
  display: none;
}

.adminPreview .listWrapper {
  height: calc(100vh - 400px);
  max-height: calc(100vh - 400px);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-right: 20px;
}

.pill {
  background-color: var(--secondary50) !important;
  border: none;
  border-radius: 16px;
  font-size: var(--font-size-sm);
  margin-left: 10px;
  padding: 4px 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--grey800);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  padding-bottom: 12px;
}

.list {
  flex: 1;
  overflow: hidden;
}

.pagination {
  margin-top: auto;
  width: 100%;
}

.newReportBtn {
  padding: 10px 24px;
}

.headerRWD {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    overflow-y: scroll;
    padding: 1.5rem 1rem 0 1rem;
  }

  .listWrapper {
    max-height: none;
  }

  .pagination {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .newReportBtn {
    padding: 8px 16px;
    text-wrap: nowrap;
  }

  .row {
    padding-right: 0;
  }
}

@media screen and (max-width: 600px) {
  .RWDTable {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  .RWDRow {
    display: flex;
    flex-direction: column;
  }

  .listWrapper {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .newReportBtn {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin: 0;
    height: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    overflow-y: scroll;
    padding: 1rem .5rem 0 .5rem;
  }

  .header {
    align-items: center;
    flex-wrap: wrap;
  }

  .newReportBtn {
    display: none;
  }

  .row {
    width: 100%;
    padding-right: 0px;
  }

  .pagination {
    width: 100%;
  }
}