.modalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  background-color: var(--white);
  border: 1px solid var(--adminGreyNormal);
  border-radius: 18px;
  box-shadow: var(--shadowMD);
  box-sizing: border-box;
  left: 50%;
  padding: 1.5rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--adminGreyNormal);
  color: var(--black);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  padding-bottom: 1rem;
}

.closeIcon {
  width: 1.25rem;
  color: var(--black);
  cursor: pointer;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  align-items: flex-start;
  width: 100%;
}

.applyFiltersBtn,
.clearFiltersBtn {
  display: flex;
  align-self: flex-end;
  background-color: var(--adminOrange500);
  border-radius: 0.5rem;
}

.clearFiltersBtn {
  background-color: var(--adminGreyNormal);
  color: var(--adminGreyHover);
}

.clearFiltersBtn:hover {
  background-color: var(--adminGreyNormalHover);
}

.applyFiltersBtn:hover {
  background-color: var(--adminOrange600);
}

.label {
  color: var(--black);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.optionList {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  width: 100%;
  border-bottom: 1px solid var(--adminGreyNormal);
  padding-block: 1.5rem;
}

.optionList > * {
  background-color: transparent;
  border: 1px solid var(--adminGreyNormalHover);
  border-radius: 0.5rem;
  color: var(--black);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 0.75rem 1rem;
}

.optionList > *:hover {
  background-color: transparent;
  opacity: 0.8;
}

.filterField {
  width: 100%;
}

.calendarStyle {
  transform: translate(0, -15%);
}

.active {
  border-color: var(--adminOrange500);
  color: var(--adminOrange500);
}

.dateInput {
  margin-top: 1rem;
}

.actionsWrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
  width: 100%;
}
