.userData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: flex-start;
    box-sizing: border-box;
    box-shadow: var(--shadowMD);
    height: 7.875rem;
    border-radius: 1.125rem;
    margin-bottom: 1.5rem;
}

.iconWrapper {
    height: 3.125rem;
    width: 3.125rem;
    display: flex;
    justify-content: center;
    background-color: var(--primary200);
    border-radius: 50%;
    align-items: center;
    margin-right: 0.5rem;
}

.icon {
    height: 2.125rem;
    width: 2.125rem;
    object-fit: cover;
}

.leftContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--primary50);
    border-right: 0.063rem solid var(--primary400);
    border-top-left-radius: 1.125rem;
    border-bottom-left-radius: 1.125rem;
    padding: 0 2rem 0 2rem;
    flex: none;
}

.plan {
    display: flex;
    flex-direction: column;
}

.type {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.375rem;

}

.typeText {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-15);
    padding-right: 0.375rem;
    color: var(--grey800);
}

.typeIcon {
    background-color: var(--primary200);
    width: 3.5rem;
    color: var(--primary700);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semi);
    line-height: var(--line-height-16);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    text-transform: capitalize;
}

.statusWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.statusIconWrapper {
    height: 1rem;
    width: 1rem;
    display: flex;
    justify-content: center;
    object-fit: cover;
    margin-right: 0.188rem;
}

.statusIcon {
    height: 0.813rem;
    width: 0.813rem;
    color: var(--primary600);
}

.statusText {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
    color: var(--grey900);
    line-height: var(--line-height-16);
}

.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
}

.title {
    color: var(--grey900);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semi);
    line-height: var(--line-height-15);
}

.address {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-md);
    color: var(--grey700);
    line-height: var(--line-height-15);
}