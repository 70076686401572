.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--white);
  overflow: hidden;
  padding: 2rem 0;
}

.headerText {
  margin: 0 auto 40px auto;
  width: 50%;
  color: var(--grey900);
  font-size: var(--display-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-12);
  text-align: center;
}

.boxes {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .headerText {
    margin: 0;
    white-space: pre-wrap;
  }

  .boxes {
    flex-direction: column;
    align-items: center;
  }

  .boxInfo {
    width: 250px;
  }

  .wrapper {
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    background-color: transparent;
    overflow-y: auto;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .headerText {
    width: 100%;
    font-size: var(--font-size-xl);
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    justify-content: flex-start;
    margin-top: 40px;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 1rem;
  }

  .headerText {
    font-size: var(--font-size-lg);
  }

  .boxName {
    font-size: var(--font-size-md);
  }

  .boxes {
    gap: 1.5rem;
  }
}
