.footer {
  display: block;
  background: linear-gradient(180deg, #0050e3 0%, #226ef9 100%);
  box-sizing: border-box;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px;
}

.logoIcon {
  width: 190px;
  height: 43px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.leftSide > ul > li {
  border-bottom: 1px solid transparent;
  padding-bottom: 2px;
}

.leftSide > ul {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  margin: 0;
  align-items: center;
  height: 100%;
  list-style: none;
  overflow: hidden;
  padding-inline: 0 1rem;
}

.leftSide > ul > li:hover {
  border-bottom: 1px solid var(--white);
  padding-bottom: 2px;
}

.leftSide > ul > li > a {
  color: var(--white);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  text-wrap: nowrap;
}

.copyRightsWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 60px;
}

.copyRightsWrapper > p {
  margin: 0;
}

.copyRightsText {
  color: var(--white);
  font-size: var(--font-size-sm);
}

.contactUs {
  margin: 0 0 40px;
  color: var(--white);
  font-size: var(--display-lg);
  font-weight: var(--font-weight-semi);
}

.contactUsAddress {
  margin: 0;
  color: var(--white);
  font-size: var(--display-xs);
  line-height: var(--line-height-15);
}

.contactWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.contactWrapper > a {
  border-bottom: 1px solid transparent;
  color: var(--white);
  text-decoration: none;
}

.contactWrapper > a:hover {
  border-bottom: 1px solid var(--white);
}

.externalSubpagesWrapper {
  display: flex;
  gap: 2rem;
}

@media screen and (min-width: 2000px) {
  .wrapper {
    padding: 0;
  }

  .footer {
    padding: 60px 14vw;
  }

  .copyRightsWrapper {
    margin-top: 80px;
    padding-inline: 0;
  }
}
@media screen and (max-width: 1024px) {
  .wrapper {
    flex-direction: column-reverse;
    gap: 3rem;
    align-items: flex-start;
  }

  .contactUs {
    font-size: var(--font-size-xl);
    margin-bottom: 1.5rem;
  }

  .contactWrapper > a {
    font-size: var(--font-size-lg);
  }
}
@media screen and (max-width: 768px) {
  .logoIcon {
    width: 145px;
    height: 32px;
  }

  .leftSide > ul {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .wrapper {
    flex-direction: column;
    gap: 90px;
    align-items: flex-start;
    padding: 2rem 1rem;
  }

  .contactUs {
    font-size: var(--font-size-xl);
  }

  .leftSide {
    gap: 2rem;
  }

  .copyRightsText {
    font-size: var(--font-size-xs);
  }

  .copyRightsWrapper {
    padding-inline: 1rem;
  }
}
