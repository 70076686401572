.wrapper {
  overflow: hidden;
  overflow-y: auto;
  padding: 2rem 1.5rem;
  padding-inline: 0;
}

.wrapper::-webkit-scrollbar {
  width: 4px;
}

.searchInput {
  width: 35%;
  height: 40px;
  border: 1px solid var(--adminGreyNormalActive);
  border-radius: 4px;
  margin-left: 1.5rem;
  padding-inline: 1rem;
}

.listWrapper {
  height: calc(100vh - 20rem);
  overflow-y: auto;
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.listWrapper::-webkit-scrollbar {
  width: 4px;
}

.paginationWrapper {
  margin: 0 1.5rem;
  width: auto;
  padding-top: 1rem;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 2;
}
