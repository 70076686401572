.modalWrapper {
  max-width: 555px;
}

.modalActions {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 40px;
  width: 100%;
}

.modalSubinfo {
  margin-top: 28px;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  line-height: var(--line-height-15);
}

.cancelBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  padding: 10px 24px;
}

.resignBtn {
  background-color: var(--primary500);
  padding: 10px 24px;
}

.cancelBtn:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

@media screen and (max-width: 768px) {
  .modalWrapper {
    max-width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .modalSubinfo {
    font-size: var(--font-size-md);
  }

  .modalActions {
    flex-direction: column;
    gap: 12px;
  }
}
