.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-inline: auto;
  user-select: none;
}

.list {
  max-height: 140px;
  background-color: white;
  border-radius: 15px;
  bottom: calc(100% + 10px);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  -moz-box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  overflow-y: auto;
  padding: 15px 10px;
  position: absolute;
  right: 0;
  z-index: 1;
}

.option {
  border-bottom: 1px solid var(--grey400);
  cursor: pointer;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.option:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.option:hover {
  color: var(--grey800);
}

.arrows {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}

.limit {
  display: flex;
  align-items: center;
  color: var(--grey600);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.limitAmountBox {
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 4px;
  margin-left: 4px;
  padding: 4px 8px;
}

.total {
  color: var(--grey600);
  font-size: 14px;
  font-weight: 500;
  margin-inline: auto;
}

.arrow {
  cursor: pointer;
  stroke: var(--grey800);
  transform: rotateZ(90deg);
  transition: transform 0.3s ease;
}

.arrow.down {
  margin-left: 5px;
  stroke: var(--grey600);
  transform: rotateZ(180deg);
}

.arrow.right {
  width: 8px;
  height: 8px;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 50%;
  padding: 8px;
  transform: rotateZ(90deg);
}

.arrow.left {
  width: 8px;
  height: 8px;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 50%;
  padding: 8px;
  transform: rotateZ(-90deg);
}

.arrow.up {
  transform: rotateZ(0deg);
}

.arrow:hover {
  stroke: var(--primary500);
}

.exportDataBtn {
  background-color: transparent;
  color: var(--primary500);
}

.exportDataBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

@media screen and (max-width: 450px) {
  .arrows {
    margin-left: auto;
  }

  .exportDataBtn {
    margin-left: auto;
  }
}
