.questionnaireSection {
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.question {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-15);
}

.keywordsWrapper {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
}

.addBtn {
    background-color: var(--secondary500);
    height: 2.5rem;
}

.addBtn:hover {
    background-color: var(--secondary600);
}

.addBtn:disabled {
    background-color: var(--grey300);
    color: var(--grey500);
}

.closeIcon {
    width: 0.875rem;
    height: 0.875rem;
    color: var(--grey800);
    cursor: pointer;
}

.limitReached {
    color: var(--grey700);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-15);
    margin-bottom: 0;
}

.closeIcon:hover {
    color: var(--grey900);
    filter: scale(1.2);
}

.labelsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-top: 0.5rem;
}

.pill {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
    border: 0.063rem solid var(--secondary500);
    border-radius: 2rem;
    color: var(--grey800);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    padding: 0.375rem 1rem;
}