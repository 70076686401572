.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

.select {
  width: 100%;
}

.pill {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--secondary500);
  border-radius: 32px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: 500;
  padding: 6px 16px;
}

.pickedAreasTitle {
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  margin-bottom: 16px;
}

.closeIcon {
  width: 14px;
  height: 14px;
  fill: var(--grey600);
}

.editIcon {
  cursor: pointer;
  fill: var(--grey900);
}

.closeIcon:hover {
  fill: var(--grey800);
  filter: scale(1.2);
}

.pickedAreasList {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.tooltipStyle {
  width: 200px;
  background-color: var(--white);
  border-radius: 6px;
  bottom: 20px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  z-index: 20;
}

.tooltipStyle.displayNone,
.closeIcon.displayNone {
  display: none;
}
