.wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  margin-bottom: 24px;
}

.editBtn {
  background-color: transparent;
  color: var(--primary500);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 5px 16px;
}

.editBtn:hover {
  background-color: transparent;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--grey100);
  border-radius: 15px;
  padding: 12px 16px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--grey800);
  flex: 32;
  font-size: var(--font-size-sm);
  list-style: none;
  padding: 0;
  padding-top: 10px;
  text-align: justify;
}

.sectionName {
  width: calc(100% - 32px);
  background-color: var(--grey50);
  padding: 12px 16px;
}

.wrapperStyle {
  display: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.suggestionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.suggestionRWD {
  display: none;
  border-left: 1px solid var(--grey-border);
  flex: 362;
}

.indicator {
  margin-right: 12px;
}

.suggestion {
  border-left: 1px solid var(--grey-border);
  flex: 362;
}

.form {
  flex: 612;
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    gap: 24px;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--grey50);
    border-radius: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 12px 16px;
    padding-top: 0px;
  }

  .suggestion {
    display: none;
  }

  .suggestionRWD {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--grey50);
    border-radius: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-top: 0px;
  }

  .wrapperStyle {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
}
