.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.info {
  width: calc(100% - 41px);
  max-width: calc(100% - 41px);
}

.checkbox {
  border: 1px solid var(--secondary500);
  border-radius: 5px;
  margin-right: 18px;
}

.name {
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address {
  color: var(--grey600);
  font-size: var(--font-size-sm);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.imported {
  margin-top: 4px;
  color: var(--grey600);
}