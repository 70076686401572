.title {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: 28px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.closeIcon {
  width: 24px;
  border: 1px solid var(--grey300);
  border-radius: 12px;
  cursor: pointer;
  fill: var(--grey700);
  padding: 8px;
}

.content {
  display: flex;
}

.modalContent {
  width: 675px;
  max-width: 675px;
  max-height: 90%;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .modalContent {
    max-height: 90%;
  }
}

@media screen and (max-width: 768px) {
  .modalContent {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

@media screen and (max-width: 450px) {
  .modalContent {
    width: 100%;
  }
}
