.singlePaymentInfo {
  display: flex;
  gap: 0.5rem;
  margin-top: 3rem;
  align-items: flex-start;
}

.infoIcon {
  width: 20px;
  height: 20px;
  color: var(--grey800);
}

.rightSideWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--grey800);
  font-size: var(--font-size-sm);
}

.rowContainer {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  color: var(--grey900);
  flex-wrap: wrap;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  user-select: text;
}

.rowContainer > a {
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  text-decoration: none;
  user-select: text;
}
