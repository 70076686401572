.wrapper {
  width: 100%;
  height: calc(100vh - 10%);
}

.element {
  margin-bottom: 24px;
}

.element:first-of-type {
  padding-top: 0px;
}

.element:last-child {
  border-bottom: 1px solid white;
  padding-bottom: 0px;
}

.all {
  justify-content: flex-end;
  border-bottom: 1px solid var(--grey200);
  border-top: 1px solid var(--grey200);
  margin-bottom: 32px;
  padding-bottom: 16px;
  padding-top: 16px;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  color: var(--grey500);
  font-size: 20px;
}

.wrapper:has(.empty) .list {
  height: 100px;
  max-height: 100px;
  min-height: 100px;
}

.list {
  max-height: 241px;
  overflow-y: auto;
  padding-right: 12px;
}

.list::-webkit-scrollbar {
  width: 4px;
}

.list::-webkit-scrollbar-track {
  background: var(--grey200);
}

.list::-webkit-scrollbar-thumb {
  background: var(--grey400);
}

.row {
  margin-bottom: 24px;
}

@media screen and (max-width: 1024px) {
  .list {
    height: 200px;
  }
}
