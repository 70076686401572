.reviewerBox {
  display: flex;
  gap: 12px;
  align-items: center;
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.opinion {
  border-bottom: 1px solid var(--grey300, #e0e0e0);
  margin-bottom: 12px;
}

.opinionAndStarsBox {
  display: flex;
  gap: 12px;
  margin-top: 4px;
  align-items: center;
  color: var(--grey500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  margin-left: 36px;
}

.opinionText {
  margin-top: 12px;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  margin-left: 36px;
  padding-bottom: 12px;
}

.reviewerPhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: var(--grey300);
  border-radius: 50%;
  box-shadow: 0px 16px 56px 0px rgba(194, 203, 216, 0.2);
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .opinion {
    padding-block: 12px;
  }
}
@media screen and (max-width: 450px) {
  .opinion {
    margin-bottom: 12px;
  }
}
