.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: var(--shadowMD);
  padding: 1.5rem 2rem;
}

.statisticBtn {
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  color: var(--white);
  padding: 4px 16px;
}

.statisticBtn:hover {
  background-color: var(--adminOrange700);
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  max-width: min(30rem, 35%);
  overflow: hidden;
}

.leftSide > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.linkName {
  color: var(--black);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.linkCode {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.link {
  color: var(--black);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
}

.rightSide {
  display: flex;
  gap: 4rem;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.lastUsedTitle {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--adminGreyDarker);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  position: relative;
}

.lastUsedValue {
  color: var(--black);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.columnWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.circleInfoIcon {
  width: 1rem;
  height: 1rem;
}

.tooltipStyle {
  width: 19rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0px 7px 29px 0px #64646f33;
  color: var(--black);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  left: 0;
  padding: 4px 12px;
  position: absolute;
  right: 60px;
  top: 1rem;
  z-index: 2;
}

.statisticBtn:disabled {
  background-color: var(--adminGreyNormal);
  color: var(--adminGreyDarker);
}

.clientLinkWrapper {
  border-bottom: 1px solid var(--grey200);
  border-radius: 0;
  box-shadow: none;
}

.clientLinkWrapper .leftSide {
  gap: 0;
}

.clientLinkWrapper .leftSide .linkName {
  color: var(--grey900);
  font-size: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.clientLinkWrapper .leftSide .linkCode,
.clientLinkWrapper .leftSide .link {
  color: var(--grey700);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
}

.clientLinkWrapper .rightSide .lastUsedTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
}

.clientBtn {
  background-color: var(--primary500);
  border-radius: 2rem;
  padding: 10px 1.5rem;
}

.clientBtn:hover {
  background-color: var(--primary600);
}

.clientBtn:disabled {
  background-color: var(--grey100);
}

@media screen and (max-width: 1600px) {
  .leftSide {
    max-width: 30%;
    min-width: 30%;
    margin-right: 2rem;
  }

  .lastUsedTitle {
    gap: 0;
  }
}
@media screen and (max-width: 1440px) {
  .clientLinkWrapper {
    padding-inline-start: 0.5rem;
  }

  .rightSide {
    gap: 1rem;
  }
}
@media screen and (max-width: 1024px) {
  .rightSide {
    gap: 2rem;
  }

  .linkWrapper {
    padding-inline: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .linkWrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--grey100);
    border: 1px solid var(--grey200);
    border-radius: 1rem;
    padding: 1rem;
  }

  .rightSide {
    flex-direction: column;
    align-items: flex-start;
  }

  .leftSide {
    width: 100%;
    max-width: 100%;
  }

  .btnWrapper,
  .clientBtn {
    width: 100%;
  }

  .clientBtn:disabled {
    border: 1px solid var(--grey300);
  }
}
