.modalStyle {
  max-width: 780px;
  max-height: 90vh;
  overflow: hidden;
  overflow-y: auto;
}

.title {
  color: var(--grey800);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 16px;
}

.howItWorks {
  background-color: var(--grey50);
  border-radius: 10px;
  margin-block: 24px;
  padding: 24px;
}

.description {
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  margin-top: 1rem;
  text-align: justify;
}

.completionScaleBox {
  display: flex;
  gap: 32px;
}

.dataTitle {
  color: var(--grey700);
  font-size: var(--font-size-md);
  line-height: var(--line-height-16);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
}

.dataItemTitle {
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
}

.reasonsWrapper {
  background-color: var(--grey100);
  padding-block: .5rem;
  border: .5rem;
}

.description>ul>li {
  list-style: none;
}

.description>p {
  margin: 0;
  font-weight: var(--font-weight-semi);
}


.reasonsWrapper>li {
  font-weight: var(--font-weight-medium);
  color: var(--grey800);
  margin-block: .25rem;
}

@media screen and (max-width: 450px) {
  .modalStyle {
    max-height: calc(100vh - 114px);
  }
}