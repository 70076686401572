.wrapper {
  width: 50%;
  border-bottom: 1px solid var(--adminGreyNormalHover);
  padding-bottom: 1.5rem;
}

.clientDataHeading {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  margin-bottom: 1rem;
}

.clientData {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.label {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 0.25rem;
}
