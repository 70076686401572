.wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: fit-content;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  margin-block: 1.5rem;
  overflow: hidden;
  padding: 1.5rem 2rem;
}

.wrapper:first-of-type {
  margin-top: 0;
}

.businessCard {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  max-width: 60%;
  flex: 1;
  margin-right: 1.5rem;
  overflow: hidden;
}

.businessCardText {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 100%;
  overflow: hidden;
}

.businessCardName {
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.businessCardAddress {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.showBusinessCardBtn {
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  flex-shrink: 0;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.showBusinessCardBtn:hover {
  background-color: var(--adminOrange700);
}

.seoCardPill {
  background-color: var(--adminOrange300);
  border-color: var(--adminOrange300);
  border-radius: 5rem;
  color: var(--black);
  flex-shrink: 0;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-12);
  padding: 6px 12px;
}

.rightSide {
  display: flex;
  gap: 10%;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  flex-wrap: nowrap;
}

.businessImage {
  width: 62px;
  height: 62px;
  border-radius: 1rem;
  object-fit: cover;
}

.showBusinessCardBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.loaderWrapper {
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
  z-index: 3;
}

.subPlanPill {
  background-color: var(--primary50);
  border-radius: 5rem;
  color: var(--primary700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-12);
  padding: 6px 12px;
}
