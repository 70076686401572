.wrapper {
  display: flex;
  gap: 24px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }
}
