
.buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;
    
  }
  
  .button {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-16);
    padding: 6px 16px;
   
  }

  .cancel {
    background-color: var(--grey50);
    border: 1px solid var(--grey400);
    color: var(--grey900);
  }

  .cancel:hover {
    background-color: var(--grey100);
    border: 1px solid var(--grey500);
  }

  @media screen and (max-width: 768px) {
    .buttons {
      width: 100%;
    }

    .button {
      padding: 10px 24px;
    }
  }
  @media screen and (max-width: 450px) {
    .button {
      width: 100%;
    }
    
  }