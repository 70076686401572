.header {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: var(--white);
  border: 1px solid var(--grey100);
  border-left: none;
  border-top: none;
  padding: 1.25rem 1.5rem;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  margin-block: 0;
}

.body {
  margin: 1.5rem;
  height: calc(100vh - 14rem);
  background-color: var(--white);
  border-radius: 0.75rem;
  padding: 1.5rem;
}


.filterButton {
  gap: 0.75rem;
  max-height: 3rem;
  background-color: var(--grey100);
  border: 1px solid var(--grey500);
  border-radius: 2rem;
  box-sizing: border-box;
  color: var(--grey900);
  padding: 0.75rem 1.5rem;
}

.filterButton:hover {
  background-color: var(--grey200);
}

.dateWrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.dateWrapper>span {
  text-wrap: nowrap;
}

.notViewedDot {
  display: flex;
  width: 10px;
  min-width: 10px;
  height: 10px;
  min-height: 10px;
  background-color: var(--secondary500);
  border-radius: 50%;
  flex-shrink: 0;
}

.checkbox {
  width: 1rem;
  height: 1rem;
  border-color: var(--secondary500);
  border-radius: 6px;
}

.checkboxStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
}

.pill {
  gap: 0.25rem;
  background-color: var(--primary50);
  border: 1px solid var(--primary500);
  border-radius: 2rem;
  color: var(--primary800);
  padding: 0.25rem 0.75rem;
}

.connectedBusinessProfile {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.connectedBusinessProfile>span {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.system {
  background-color: var(--grey50);
  border: 1px solid var(--grey600);
  color: var(--grey900);
}

.opinieo {
  background-color: var(--secondary50);
  border-color: var(--secondary600);
  color: var(--secondary900);
}

.notificationList {
  height: calc(100vh - 21rem);
  margin-bottom: 1rem;
}

.pagination {
  margin-top: auto;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.deleteBtn {
  padding: 0.5rem 1.5rem;
}

@media screen and (max-width: 1024px) {
  .body {
    margin: 1rem;
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: var(--font-size-lg);
  }
}

@media screen and (max-width: 600px) {
  .body {
    margin: 0;
    padding: 1rem;
  }

  .filterButton {
    padding: .5rem 1.5rem;
  }
}

@media screen and (max-width: 450px) {
  .body {
    margin: 0 1rem;
    border-radius: 0;
    padding: 0;
  }
}