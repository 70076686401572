.body {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-block: 12px;
  text-align: center;
}

.icon {
  display: flex;
  align-items: center;
  width: var(--icon-xlarge);
  height: var(--icon-xlarge);
  background-color: var(--primary200);
  border: 8px solid var(--primary50);
  border-radius: 100%;
  box-sizing: border-box;
  color: var(--primary500);
  justify-self: center;
  padding: 6px;
  stroke: var(--primary500);
}

.adminIcon {
  background-color: var(--adminOrange200);
  border-color: var(--adminOrange100);
  color: var(--adminOrange500);
}

.bodyHeading {
  color: var(--grey800);
  font-size: 20px;
  font-weight: 600;
  line-height: var(--line-height-14);
  text-align: center;
}

.supportingText {
  max-width: 260px;
  color: var(--grey500);
  font-size: 16px;
  font-weight: 400;
  line-height: var(--line-height-15);
  text-align: center;
}

@media screen and (max-width: 450px) {
  .supportingText {
    font-size: var(--font-size-sm);
  }
}
