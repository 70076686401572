.headerWrapper {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--grey300);
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}

.leftSideRow {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.logoIcon {
  width: 24px;
  height: 24px;
  background-color: var(--primary100);
  border: 0.5rem solid var(--primary50);
  border-radius: 50%;
  color: var(--primary500);
  padding: 0.5rem;
}

.planDetails {
  display: flex;
  gap: 6px;
  align-items: baseline;
  height: fit-content;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.subscriptionPill {
  background-color: var(--primary50);
  border: none;
  border-radius: 0.25rem;
  color: var(--primary500);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  padding: 0.25rem 1rem;
  text-transform: capitalize;
}

.statusWrapper {
  display: flex;
  gap: 6px;
  margin-top: 6px;
  align-items: center;
}

.checkIcon {
  width: 1rem;
  color: var(--primary500);
}

.planStatusInfo {
  color: var(--grey900);
  font-size: var(--font-size-sm);
}

.priceTag {
  color: var(--grey900);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-15);
}

.priceTag span {
  color: var(--grey600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

.mobileVisible {
  display: none;
}
@media screen and (max-width: 600px) {
  .headerWrapper {
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .rightSideRow {
    display: none;
    margin-top: 0.75rem;
  }

  .rightSideRow.mobileVisible {
    display: flex;
  }
}
