.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--grey700);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  text-align: center;
}

.wrapper > div > svg {
  width: 2rem;
  height: 2rem;
  color: var(--primary500);
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background-color: var(--primary100);
  border: 0.5rem solid var(--primary50);
  border-radius: 50%;
}

.wrapper > p {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}
