.answerWithTemplateModal {
  max-width: 750px;
}

.answerWithTemplateModal:has(.template) {
  max-width: 950px;
  max-height: calc(100vh - 120px);
}

.answerWithTemplateModalContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 52px;
  width: 100%;
}

.answerWithTemplateModalContent:has(.template) {
  margin-top: 28px;
}

.template {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100% * (1 / 2) - 60px);
  height: 280px;
  align-self: center;
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
  border-radius: 10px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  flex: 0 1 43%;
  overflow: hidden;
  padding: 24px;
  word-break: break-all;
}

.templatesWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.longTemplate {
  overflow-y: scroll;
}

.longTemplate::-webkit-scrollbar {
  width: 4px;
}

.longTemplate::-webkit-scrollbar-thumb {
  background: var(--grey300);
  border-radius: 4px;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  background-color: transparent;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

@media screen and (max-width: 450px) {
  .templatesWrapper {
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: scroll;
  }

  .templatesWrapper::-webkit-scrollbar {
    display: none;
  }

  .template {
    width: 100%;
    max-width: 100%;
    min-height: 50%;
    box-sizing: border-box;
    flex: 0 1 100%;
  }

  .answerWithTemplateModal:has(.template) {
    max-width: 100%;
    max-height: 100dvh;
  }
}
