.navigationWrapper {
  display: flex;
  background-color: var(--grey50);
  box-shadow: var(--shadowMD);
  margin-bottom: 40px;
  padding: 0 20px;
}

.expandableIcon {
  width: 16px;
  height: 16px;
  transition: all 0.3s ease-in-out;
}

.subMenuWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.25rem;
  background-color: var(--white);
  border: 1px solid var(--adminGreyNormal);
  border-radius: 0.5rem;
  box-shadow: var(--shadowMD);
  left: 0;
  padding: 0.5rem;
  position: absolute;
  top: 100%;
}

.inverted {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}
