.moreHoursWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid var(--primary300);
  border-radius: 6px;
  margin-bottom: 24px;
  padding: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.moreHourName {
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.editMoreHours {
  background-color: transparent;
  color: var(--primary500);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
}

.editMoreHours:hover {
  background-color: transparent;
  color: var(--primary600);
}

.moreHours {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
}

.hour {
  flex: 1;
  margin-right: 16px;
}

.moreHoursPeriod {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-block: 16px;
}

.periodName {
  width: 40%;
}

.moreHoursHour {
  display: flex;
}

.tooltipStyle {
  width: 200px;
  background-color: var(--white);
  border-radius: 6px;
  bottom: 20px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  z-index: 20;
}

@media screen and (max-width: 768px) {
  .periodName {
    max-width: 40%;
  }

}
