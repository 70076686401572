.wrapper {
  margin: 0 auto;
  width: 30rem;
  border-radius: 12px;
  box-shadow: var(--shadowMD);
  padding: 32px;
}

.loginText {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem 0 1.5rem;
}

.input {
  height: 40px;
  border-radius: 8px;
}

.recoverPasswordBtn {
  background-color: transparent;
  color: #212121e5;
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  padding: 0;
}

.recoverPasswordBtn:hover {
  background-color: transparent;
  color: var(--black);
}

.signInBtn {
  margin-top: 2.5rem;
  width: 100%;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  color: var(--white);
  font-weight: var(--font-weight-semi);
  padding: 0.5rem 1.5rem;
}

.signInBtn:hover {
  background-color: var(--adminOrange700);
}

.signInBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.passwordInputWrapper {
  position: relative;
}

.inputTitle {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 4px;
}

.showPasswordIcon {
  width: 1rem;
  color: var(--adminGreyDarkActive);
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 60%;
}

.showPasswordIcon:hover {
  color: var(--black);
}

.loaderWrapper {
  gap: 1rem;
  margin-top: 1rem;
  height: 300px;
}
