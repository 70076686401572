.dataTitle {
  color: var(--adminGreyDarker);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.dataValue {
  color: var(--black);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}
