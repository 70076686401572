.videoContainer {
  width: 100%;
  height: 100%;
  border: 1px solid var(--grey300);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  box-sizing: border-box;
  object-fit: cover;
  object-position: center;
  user-select: none;
}
