.wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  background-color: var(--white);
  border: 1px solid var(--grey100);
  box-sizing: border-box;
  padding: 14px 24px;
}

.header {
  display: flex;
  flex-direction: column;
  color: var(--grey800);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
}

.actions {
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
}

.dashboardRefreshBtn {
  padding: 10px 24px;
}

.dashboardRefreshRWDBtn {
  display: none;
  padding: 8px 20px;
}

.openCalendar {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: var(--grey100);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding: 10px 24px;
  position: relative;
  z-index: 0;
}

.openCalendar:hover {
  background-color: var(--grey200);
}

.calendarIcon {
  width: 20px;
  height: 20px;
  color: var(--black);
  stroke-width: 1.4px;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(4px);
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

@media screen and (max-width: 450px) {
  .wrapper {
    gap: 1rem;
    max-height: none;
    flex-wrap: wrap;
    padding: 14px 16px;
  }

  .flexDirCol {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    max-height: none;
  }

  .header {
    font-size: var(--font-size-lg);
  }

  .openCalendar {
    padding: 8px 20px;
  }
}
