.deleteModal {
  max-width: 675px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 24px;
  align-items: flex-start;
  color: var(--grey800);
  font-size: 20px;
  line-height: 30px;
}

.modalActions {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 52px;
  align-items: center;
}

.modalButton {
  background-color: var(--primary500);
  font-size: 16px;
  font-weight: 500;
  padding: 10px 24px;
}

.modalButton.cancel {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
}

.cancel:hover {
  background-color: var(--grey100);
  border: 1px solid var(--grey500);
}

@media screen and (max-width: 450px) {
  .modalActions {
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .modalButton {
    width: 100%;
  }

  .deleteModal {
    display: none;
  }
}
