.modal {
  max-width: 50%;
  overflow: hidden;
}

.changeDataSubInfo {
  margin: 2.5rem 0 1.5rem;
  color: var(--grey800);
  line-height: var(--line-height-15);
}

.inoviceDataWrapper {
  margin-top: 2.5rem;
}

.basicData {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  white-space: wrap;
}

.adressData {
  color: var(--grey600);
  line-height: var(--line-height-15);
}

.additionalInformation {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
  margin-block: 2.5rem;
}

.additionalInformation>svg {
  width: var(--icon-regular);
}

.changeDataBtn {
  width: fit-content;
  margin-left: auto;
  padding: 10px 24px;
}

.loader {
  height: auto;
  backdrop-filter: blur(2px);
  border-radius: 16x;
  inset: 0;
  overflow: hidden;
  position: absolute;
  z-index: 2;
}


@media screen and (max-width: 1240px) {
  .modal {
    max-width: 90vw;
  }
}

@media screen and (max-width: 450px) {
  .changeDataBtn {
    width: 100%;
  }

}