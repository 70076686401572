.tab {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  border-bottom: 2px solid var(--adminGreyNormalHover);
  color: var(--adminGreyDarker);
  cursor: pointer;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding: 10px 24px;
  position: relative;
  text-wrap: nowrap;
}

.activeTab {
  border-bottom-color: var(--black);
  color: var(--black);
}

.subTab {
  border-bottom: none;
  color: var(--black);
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  text-wrap: nowrap;
}

.subTab:hover {
  background-color: var(--adminGreyNormal);
}
