.wrapper {
  margin: 24px;
  width: calc(100% - 48px);
  height: 100%;
  background-color: var(--white);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
}

.verified {
  fill: var(--green600);
  transform: translateY(15%);
}

.duplicate {
  color: var(--grey600);
}

.verified .duplicate {
  color: var(--green600);
}

.title.verified {
  color: var(--green600);
}

.title.suspended {
  color: var(--yellow500);
}

.suspended .icon {
  color: var(--yellow600);
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 76px;
  box-sizing: border-box;
  clip-path: circle();
  padding: 12px;
}

.iconWrapper.verified {
  background-color: var(--green100);
  color: var(--green500);
}

.iconWrapper.verified .icon {
  transform: translate(15%, 20%);
}

.iconWrapper.suspended {
  background-color: var(--yellow100);
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.subTitle {
  margin-top: 12px;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-block: auto;
}

.processing {
  color: var(--yellow600);
  font-weight: var(--font-weight-medium);
}

.suspendedForm {
  margin-top: 4px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .wrapper {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90svh;
  }
}
