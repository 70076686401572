.subHeader {
  color: var(--grey700);
  font-weight: var(--font-weight-regular);
  margin-bottom: 24px;
}

.outOfReports {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}
