.title {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  margin-bottom: 8px;
}

.standAloneWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px;
  height: 100%;
  background-color: var(--white);
  border-radius: 16px;
  padding: 0;
  padding-block: 32px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  height: 100%;
  box-sizing: border-box;
  margin-inline: 130px;
}

.subTitle {
  color: var(--grey800);
  margin-bottom: 32px;
  white-space: pre-wrap;
}

.pinInput {
  width: 60%;
  height: 56px;
}

.verificationNameWrapper {
  display: flex;
  flex-direction: column;
  margin: 24px;
  width: calc(100% - 48px);
  height: 100%;
  background-color: var(--white);
  border-radius: 8px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .body {
    justify-content: flex-start;
    margin: 24px;
    width: calc(100% - 48px);
  }

  .pinInput {
    width: 100%;
  }

  .standAloneWrapper {
    margin: 0;
    width: 100%;
    height: calc(100vh - 64px);
    border-radius: 0;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 450px) {
  .body {
    margin-top: 0;
    width: calc(100% - 24px);
    margin-inline: 12px;
  }

  .standAloneWrapper {
    height: 90svh;
  }

  .subTitle {
    white-space: initial;
  }

  .verificationNameWrapper {
    width: 100%;
  }
}
