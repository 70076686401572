.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  border-radius: 30px;
  margin-bottom: 16px;
}

.button {
  height: 60px;
  border-radius: 30px;
  flex: 1;
  margin-left: 10px;
  padding: 14px 32px;
}

.input {
  height: 60px;
  border-radius: 8px;
}

.charCounter {
  bottom: 30px;
  color: var(--grey700);
  font-size: 12px;
  font-weight: 400;
  left: 90%;
  position: relative;
}

.inputWithCounter {
  width: 100%;
  height: 60px;
  flex:3;
}
