.wrapper {
  width: 560px;
  background-color: var(--white);
  border: 1px solid var(--grey400);
  border-radius: 18px;
  box-shadow: 0px 5px 20px 0px rgba(127, 122, 122, 0.1);
  padding: 24px;
  position: absolute;
  right: 20%;
  top: 100%;
  z-index: 20;
}

.title {
  max-width: 90%;
  color: var(--grey800);
  line-height: var(--line-height-15);
}

.monthsWrapper {
  display: flex;
  gap: 8px;
  margin-top: 32px;
}

.monthPill {
  background-color: var(--grey50);
  border: 1px solid var(--grey200);
  border-radius: 32px;
  cursor: pointer;
  flex-basis: calc(33%);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  padding: 6px 12px;
}

.monthPill:hover {
  background-color: var(--grey100);
  border-color: var(--grey400);
}

.actionsWrapper {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 50px;
  align-items: center;
  width: 100%;
}

.btn {
  padding: 10px 24px;
}

.cancel {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
}

.cancel:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.selectedMonthsTitle {
  margin-top: 26px;
  color: var(--grey800);
  line-height: var(--line-height-15);
}

.selectedPill {
  border-color: var(--grey900);
}

.emptySelectedMonths {
  margin-top: 12px;
  color: var(--grey900);
  font-weight: var(--font-weight-semi);
}

.selectedMonths {
  margin-top: 12px;
  color: var(--grey900);
  font-weight: var(--font-weight-semi);
}

.pillWrapper {
  width: fit-content;
}

.RWDTitle {
  display: none;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
}

.closeIcon {
  width: 20px;
  height: 18px;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
}

.closeIcon:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.RWDHeader {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100svh;
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 120px;
  }

  .monthsWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .RWDTitle {
    display: block;
  }
}

@media screen and (max-width: 450px) {
  .actionsWrapper {
    flex-direction: column;
    margin-top: 1.5rem;
    width: 100%;
  }

  .actionsWrapper > * {
    width: 100%;
  }
}
