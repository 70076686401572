.wrapper {
  display: flex;
  flex-direction: column;
  margin: 24px;
  height: 100%;
  background-color: var(--white);
  border: 1px solid var(--grey200);
  border-radius: 14px;
  box-shadow: 0px 5px 20px rgba(127, 122, 122, 0.1);
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  padding: 24px;
  padding-top: 32px;
}

.wrapper::-webkit-scrollbar {
  width: 8px;
}

.wrapper::-webkit-scrollbar-track {
  background: var(--grey100);
}

.wrapper::-webkit-scrollbar-thumb {
  background: var(--grey300);
}

.noScroll {
  overflow: hidden;
}

.body {
  display: flex;
  flex-direction: column;
}

.RWDTitle {
  display: none;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}
@media screen and (min-width: 1900px) {
  .wrapper {
    justify-content: space-between;
    padding-block: 70px;
  }

  .wrapper > * {
    width: 100%;
    max-width: 80vw;
    margin-inline: auto;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    margin: 0;
    width: 100%;
    height: calc(100dvh - 72px);
    border: none;
    border-radius: 0;
    overflow-y: auto;
    padding-inline: 16px;
    z-index: 1;
  }

  .wrapper::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin: 0;
    width: 100%;
    height: calc(100dvh - 72px);
    border: none;
    border-radius: 0;
    overflow-y: auto;
    padding-inline: 16px;
    padding-top: 0;
  }

  .RWDTitle {
    display: flex;
    color: var(--grey800);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semi);
    line-height: var(--line-height-15);
    margin-block: 16px;
  }

  .body {
    height: 90dvh;
  }
}
