.businessCardStatusBox {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  box-sizing: border-box;
  flex: 1 0 0;
  flex-shrink: 0;
  padding: 24px;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--grey800);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.status {
  margin-top: 12px;
  color: var(--green500);
  font-weight: var(--font-weight-semi);
}

.icon {
  width: 40px;
  height: 40px;
  color: var(--green500);
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--green100, #e6fbd9);
  box-sizing: border-box;
  clip-path: circle();
  margin-block: 24px;
  padding: 12px;
}

.subInfo {
  width: 100%;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  text-align: center;
}

.wrapper {
  display: flex;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.duplicate {
  color: var(--grey700);
}

.suspended,
.processing {
  color: var(--yellow600);
}

.notVerified {
  color: var(--red600);
}

.icon.verified {
  color: var(--green600);
  transform: translate(6px, 6px);
}

.iconWrapper .verified {
  background-color: var(--green100);
}

.iconWrapper.duplicate {
  background-color: var(--grey100);
}

.iconWrapper.notVerified {
  background-color: var(--red100);
}

.icon.duplicate {
  width: 38px;
  aspect-ratio: 1/1;
  color: var(--grey800);
  stroke-width: 2px;
}

.iconWrapper.suspended,
.iconWrapper.processing {
  background-color: var(--yellow100);
}

.icon.suspended {
  background-color: var(--yellow100);
  border-radius: 50%;
  color: var(--yellow500);
  padding: 6px;
}

.icon.notVerified {
  background-color: var(--red100);
  border-radius: 50%;
}

.suspendedForm {
  margin-top: 4px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  text-align: center;
  text-decoration: none;
}

.verifyButton {
  background-color: transparent;
  color: var(--primary500);
}

.verifyButton:hover {
  background-color: transparent;
  color: var(--primary600);
}

.flexRow {
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .businessCardStatusBox {
    padding: 16px;
  }
}
