.wrapper > * {
  margin: 0;
  padding: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 13.375rem);
}

.statusInfo {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
}

.statusSubInfo {
  color: var(--grey600);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
}

.statusInfo,
.statusSubInfo {
  line-height: var(--line-height-15);
  text-align: center;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--red200);
  border: 0.5rem solid var(--red50);
  border-radius: 50%;
  clip-path: circle();
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--red500);
}

.suspended .icon {
  color: var(--yellow500);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
}

.iconWrapper.suspended {
  background: var(--yellow200);
  border-color: var(--yellow50);
}

.iconWrapper.duplicate {
  background: var(--grey200);
  border-color: var(--grey50);
}

.duplicate .icon {
  color: var(--grey900);
  stroke-width: 1.5px;
}

.suspendedForm {
  margin-top: 4px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  text-align: center;
  text-decoration: none;
}

.verifyButton {
  background-color: transparent;
  color: var(--primary500);
}

.verifyButton:hover {
  background-color: transparent;
  color: var(--primary600);
}
