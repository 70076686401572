.postponedCover {
  width: 100%;
  height: 100%;
}

.postponedCover::after {
  max-height: clamp(100px, 20vw, 200px);
  background-color: #0000004d;
  border-radius: 8px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.userImage {
  width: 100%;
  height: 100%;
  max-height: clamp(100px, 20vw, 200px);
  aspect-ratio: 1/1;
  border: 1px solid var(--grey300);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  box-sizing: border-box;
  object-fit: cover;
  object-position: center;
  user-select: none;
}

.borderSelected {
  border: 2px solid var(--secondary500);
}

.postponedPill {
  display: flex;
  align-items: center;
  background-color: #3f3f3f99;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  bottom: 1.5rem;
  color: var(--white);
  cursor: pointer;
  font-size: 10px;
  list-style: var(--line-height-16);
  position: absolute;
  z-index: 1;
}

.pillIconStyle {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-left: 8px;
  stroke-width: 1.5px;
  transform: translateY(-1px);
}
