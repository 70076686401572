.closeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 32px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.container {
  margin-top: 6px;
  position: relative;
}

.image {
  width: 100%;
  max-height: 150px;
  aspect-ratio: 1/1;
  border: 1px solid var(--grey300);
  border-radius: 6px;
  object-fit: cover;
  position: relative;
}

@media screen and (max-width: 768px) {
  .image {
    width: 100%;
    aspect-ratio: 1/1;
    border: 1px solid var(--grey300);
    border-radius: 6px;
    object-fit: cover;
    transform: translate(0, 10%);
  }
}
