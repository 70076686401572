.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 32px;
}

.standAloneContainer {
  margin-bottom: 32px;
}

.verifyLaterBtn {
  background-color: transparent;
  color: var(--primary500);
}

.verifyLaterBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.verifyBtn {
  padding: 12px 24px;
}

@media screen and (max-width: 450px) {
  .container {
    flex-direction: column;
    margin: 12px auto;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 12px;
  }

  .verifyBtn {
    width: 100%;
  }
}
