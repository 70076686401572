.list {
  padding-inline: 24px;
  padding-top: 24px;
  user-select: none;
}

.sectionName {
  color: var(--grey900);
  font-size: var(--font-size-sm);
}

.expandableSectionName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  font-weight: var(--font-weight-regular);
}

.element {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  height: 48px;
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 13px;
  padding-right: 13px;
}

.element.selected {
  background-color: var(--primary100);
  border-radius: 8px;
}

.selected.mobileSelected {
  background-color: var(--grey50);
}

.chevronInverted {
  color: var(--primary600);
  transform: rotate(180deg) translateY(4px) translateX(2px);
}

.iconColor {
  stroke: var(--primary100);
}

.icon.selected {
  stroke: var(--primary500);
}

.icon {
  width: 18px;
  height: 18px;
  color: var(--grey700);
  stroke: var(--grey700);
}

.icon:focus {
  stroke: var(--grey900);
}

.chevronIcon {
  width: var(--icon-small);
  height: var(--icon-small);
}

.chevronIcon.iconColor {
  stroke: var(--primary500);
}

.hiddenSection {
  border-radius: 8px;
  color: var(--grey900);
  cursor: pointer;
  font-size: var(--font-size-sm);
  margin-left: 24px;
  padding: 10px 16px;
}

.activeSection {
  background-color: var(--primary100);
  color: var(--grey900);
}

@media screen and (max-width: 1024px) {
  .list {
    display: none;
    width: 100%;
    height: 100vh;
    background-color: var(--white);
    padding-left: 16px;
    padding-right: 16px;
  }

  .listVisible {
    display: block;
    max-width: 35%;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .list {
    max-width: 60%;
    height: 100vh;
    background-color: var(--white);
    padding-inline: 1rem;
  }
}
