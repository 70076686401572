.row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  background-color: var(--grey100);
  border: 1px solid var(--grey300);
  border-radius: 50%;
  cursor: pointer;
  stroke: var(--grey900);
  transform: translateY(35%);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.action.disabled {
  background: var(--grey100);
  border: 1px solid var(--grey300);
  cursor: auto;
  stroke: var(--grey500);
}

.tooltipStyle {
  width: 200px;
  background-color: var(--white);
  border-radius: 6px;
  bottom: 20px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  z-index: 20;
}

.binIcon {
  width: var(--icon-regular);
  height: var(--icon-regular);
}

.plusIcon {
  width: var(--icon-xsmall);
  height: var(--icon-xsmall);
}
