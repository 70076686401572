:root {
  --grey50: #fafafa;
  --grey100: #f5f5f5;
  --grey200: #eeeeee;
  --grey300: #e0e0e0;
  --grey400: #c6c6c6;
  --grey500: #9e9e9e;
  --grey600: #757575;
  --grey700: #616161;
  --grey800: #424242;
  --grey900: #212121;

  --white: #ffffff;
  --black: #000000;

  --primary50: #e9f0fe;
  --primary100: #cfdffe;
  --primary200: #bbd2ff;
  --primary300: #a8c6fd;
  --primary400: #71a2fb;
  --primary500: #226ef9;
  --primary600: #1b5fdb;
  --primary700: #1853be;
  --primary800: #123e8e;
  --primary900: #0b2657;

  --secondary50: #fff2ea;
  --secondary100: #ffe3d2;
  --secondary200: #ffcdae;
  --secondary300: #feae79;
  --secondary400: #ff9650;
  --secondary500: #fe8130;
  --secondary600: #e07028;
  --secondary700: #c26122;
  --secondary800: #91491a;
  --secondary900: #241206;

  --red50: #fff4ec;
  --red100: #ffe8d7;
  --red200: #ffccb0;
  --red300: #ffa988;
  --red400: #ff886b;
  --red500: #ff513a;
  --red600: #db302a;
  --red700: #b71d23;
  --red800: #931222;
  --red900: #7a0b21;

  --green50: #f6fff0;
  --green100: #e6fbd9;
  --green200: #c9f8b4;
  --green300: #a0ec8a;
  --green400: #79d969;
  --green500: #44c13c;
  --green600: #2ba52e;
  --green700: #1e8a29;
  --green800: #0f5b1d;
  --green900: #073e16;

  --yellow50: #fffeec;
  --yellow100: #fff9cf;
  --yellow200: #fff19f;
  --yellow300: #ffe86f;
  --yellow400: #ffde4b;
  --yellow500: #ffcf0f;
  --yellow600: #dbad0a;
  --yellow700: #b78d07;
  --yellow800: #7b5c03;
  --yellow900: #4f3903;

  --adminOrange100: #ffefe9;
  --adminOrange200: #ffd9c9;
  --adminOrange300: #febb9f;
  --adminOrange400: #fe9b72;
  --adminOrange500: #fd7d47;
  --adminOrange600: #fd611f;
  --adminOrange700: #d7521a;
  --adminOrange800: #b44516;
  --adminOrange900: #903712;
  --adminOrange950: #722c0e;

  --adminGreyLight: #fefefe;
  --adminGreyLightHover: #fdfdfd;
  --adminGreyLightActive: #fbfbfb;
  --adminGreyNormal: #f2f2f2;
  --adminGreyNormalHover: #dadada;
  --adminGreyNormalActive: #c2c2c2;
  --adminGreyDark: #b6b6b6;
  --adminGreyDarkHover: #919191;
  --adminGreyDarkActive: #6d6d6d;
  --adminGreyDarker: #555555;

  --adminRed50: #fef3f2;
  --adminRed100: #fee4e2;
  --adminRed200: #fecdca;
  --adminRed300: #fda29b;
  --adminRed400: #f97066;
  --adminRed500: #f04438;
  --adminRed600: #d92d20;
  --adminRed700: #b42318;
  --adminRed800: #912018;
  --adminRed900: #7a271a;

  --shadowMD: 0px 8px 24px 0px #959DA533;
}
