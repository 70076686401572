.gridItem {
  border-bottom: 1px solid var(--primary300);
  box-sizing: border-box;
  color: var(--primary800);
  font-size: 10px;
  line-height: var(--line-height-16);
  padding-bottom: 1rem;
  text-align: center;
  z-index: 1;
}

.checkIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  min-width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  background-color: var(--primary500);
  border-radius: 100%;
  color: var(--white);
  margin-inline: auto;
  position: relative;
}

.checkIcon {
  min-width: 1rem;
  transform: translate(10%, 20%);
}

.minusIcon {
  min-width: 1rem;
}

.minusIconWrapper {
  width: 1.25rem;
  color: var(--primary800);
  margin-inline: auto;
}
