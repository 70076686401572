.dataWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}

.personalData {
  color: var(--grey900);
  font-weight: var(--font-weight-semi);
}

.checkboxStyle {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
}

.checkbox {
  display: flex;
  gap: 8px;
  margin: 0;
  color: var(--grey700);
  cursor: auto;
  font-size: var(--font-size-sm);
}

.checkIconStyle {
  display: none;
  transform: translate(1.25px, 0.5px);
}

.pill {
  margin: 2rem 0 1rem;
  background-color: var(--primary50);
  border: 1px solid var(--primary500);
  color: var(--primary900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}

.infoIcon {
  width: 20px;
  height: 20px;
  color: var(--grey900);
}

.aboutInvoice {
  display: flex;
  gap: 0.5rem;
  margin-top: 2rem;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .aboutInvoice {
    margin-top: 0;
  }
}
