.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--primary500);
  margin-inline: auto;
}

.button {
  gap: 12px;
  margin-inline: auto;
  padding: 10px 24px;
}

.headingStyle {
  background-color: transparent;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.supportingText {
  width: max(40%, 400px);
  max-width: none;
}
@media screen and (max-width: 500px) {
  .content {
    justify-content: center;
    align-items: flex-start;
    max-height: calc(100svh - 200px);
    overflow-y: hidden;
  }
}
