.title {
  max-width: 100%;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.subTitle {
  max-width: 80%;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
}
@media screen and (max-width: 1300px) {
  .title {
    max-width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .subTitle {
    white-space: normal;
  }
}

@media screen and (max-width: 450px) {
  .subTitle {
    width: 100%;
    max-width: 100%;
    margin-bottom: 12px;
    white-space: normal;
  }

  .title {
    max-width: 80%;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-15);
  }
}
