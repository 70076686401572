.wrapper {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 150px;
  align-self: center;
}

.spinner {
  width: 40%;
  height: 40%;
  animation: spin 1.2s infinite linear;
  background: conic-gradient(
    from 90deg at 50% 50%,
    rgba(34, 110, 249, 0) 0deg,
    rgba(254, 129, 48, 0) 0.04deg,
    rgba(254, 129, 48, 0.328058) 118.12deg,
    #fe8130 360deg
  );
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 5px), black 0);
  mask: radial-gradient(farthest-side, transparent calc(100% - 5px), black 0);
}

@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}
