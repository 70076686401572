.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  width: 100%;
}

.invoiceTypeWrapper {
  display: flex;
  gap: 2rem;
}

.radio {
  width: 1rem;
  height: 1rem;
}

.radioWrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.radioWrapper span {
  color: var(--grey700);
  line-height: var(--line-height-15);
}

.btn {
  padding: 0.5rem 1.5rem;
}

.cancelBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
}

.cancelBtn:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.checkboxStyle {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
}

.checkbox {
  display: flex;
  gap: 8px;
  margin: 0;
  color: var(--grey700);
  cursor: auto;
  font-size: var(--font-size-sm);
}

.checkIconStyle {
  display: none;
  transform: translate(1.25px, 0.5px);
}

.emailSubInfo {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}

.secondaryEmailBtn {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  align-items: center;
  background-color: transparent;
  color: var(--primary500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding-inline: 0;
}

.secondaryEmailBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.chevronIconStyle {
  width: 1rem;
  height: 1rem;
  stroke-width: 1px;
  transition: all 0.3s ease;
}

.chevronIconStyleActive {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  align-items: flex-start;
  border-top: 1px solid var(--grey200);
  padding-top: 1.5rem;
}

.row {
  display: flex;
  gap: 1.5rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
  width: 100%;
}

.loaderWrapper {
  height: auto;
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  top: -3rem;
  z-index: 2;
}

.displayNone {
  display: none;
}

.changeDataInfo {
  display: flex;
  margin-top: 2rem;
  align-items: center;
  background-color: var(--primary50);
  border-radius: 0.5rem;
  color: var(--black);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
  padding: 1rem;
}

.circleInfo {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

@media screen and (max-width: 450px) {
  .actions {
    margin-top: 0;
  }

  .row {
    flex-direction: column-reverse;
    margin-top: 1.5rem;
    padding-top: 0;
  }
}
