.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  background-color: var(--primary500);
  overflow: hidden;
  overflow-x: hidden;
}

.businessProfileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 72px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  color: var(--secondary500);
  cursor: pointer;
  padding: 1rem;
}

.logoWrapper {
  position: relative;
}

.logoSign {
  position: absolute;
  right: -10%;
  top: 5%;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.list::-webkit-scrollbar {
  width: 4px;
}

.list::-webkit-scrollbar-track {
  background-color: var(--grey50);
  border-radius: 4px;
}

.list::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: var(--grey400);
  border-radius: 4px;
}

.add {
  display: flex;
  justify-content: center;
  margin-top: 26px;
  align-items: center;
  width: 44px;
  min-width: 44px;
  height: 44px;
  min-height: 44px;
  background-color: var(--white);
  border: 1px solid var(--white);
  border-radius: 12px;
  color: var(--black);
  cursor: pointer;
  order: 0;
}

.plusIcon {
  width: var(--icon-small);
  height: var(--icon-small);
  color: var(--black);
  transition: scale 0.3s;
}

.plusIcon:hover {
  transform: scale(1.1);
}

.loader {
  margin-top: 26px;
  max-width: 44px;
  max-height: 44px;
  background-color: var(--primary300);
  border: 6px solid var(--primary500);
  border-radius: 12px;
  cursor: pointer;
}

.loader.selected {
  border: 6px solid var(--primary300);
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(4px);
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    display: none;
  }
}
