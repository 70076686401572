.wrapper {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  height: calc(100vh - 18rem);
  background-color: var(--white);
  border-radius: 0.75rem;
  overflow: hidden;
  padding: 1.5rem;
}

.body {
  overflow-y: auto;
}

.body::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--primary200);
  flex-wrap: wrap;
  padding-bottom: 1rem;
}

.title {
  margin: 0;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.addDataBtn {
  gap: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}

.emptyStateWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 18rem);
}

.invoiceDataList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
  max-width: 80%;
  overflow: visible;
}

@media screen and (max-width: 1440px) {
  .invoiceDataList {
    max-width: none;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin: 0;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    padding: 1rem;
  }

  .title {
    font-size: var(--font-size-md);
  }
}
