.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 25px;
  height: 25px;
  background-color: var(--red500);
  border-radius: 50%;
  fill: var(--white);
  margin-right: 8px;
  padding: 3px;
}

.message {
  color: var(--secondary500);
  font-size: 20px;
  font-weight: 500;
}