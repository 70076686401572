.wrapper {
  padding-block: 1.5rem;
}

.headingWrapper {
  max-width: 70%;
}

.label {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.cardBasicDataForm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 60%;
  padding-bottom: 1.5rem;
}

.detailsFromHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-block: 1.5rem;
}

.detailTitle,
.detailNumber {
  color: var(--black);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.detailNumber {
  color: var(--adminGreyDarker);
}

.cardLinkDetailsForm {
  border-top: 1px solid var(--adminGreyNormal);
}

.addLinkBtn,
.saveCardBtn {
  gap: 0.5rem;
  margin-top: 1.5rem;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.addLinkBtn:hover,
.saveCardBtn:hover {
  background-color: var(--adminOrange700);
}

.addLinkBtn:disabled,
.saveCardBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.linkFormWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.saveCardBtn {
  margin-top: 1.5rem;
  margin-left: auto;
}

.form {
  border-bottom: 1px solid var(--adminGreyNormal);
  padding-bottom: 1.5rem;
}

.error {
  color: var(--red600);
  font-size: var(--font-size-xs);
}
