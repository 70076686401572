.input {
  margin: 0px;
  width: 100%;
  height: 16px;
  -webkit-appearance: none;
  position: absolute;
}

.input::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: var(--grey200);
  border: none;
  border-radius: 4px;
}

.input::-webkit-slider-thumb {
  margin-top: -4px;
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  background-color: var(--secondary500);
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
}

.input::-webkit-slider-thumb:hover {
  background-color: var(--secondary500);
}

.progress {
  height: 4px;
  background-color: var(--secondary500);
  border-radius: 2px;
  left: 4px;
  pointer-events: none;
  position: absolute;
  top: 6px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.inputWrapper {
  height: 16px;
  flex: 1;
  position: relative;
}
