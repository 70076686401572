.content {
  max-width: 690px;
  padding-bottom: 32px;
}

.wrapper {
  display: grid;
  margin-top: 40px;
  max-width: max-content;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  row-gap: 24px;
}

.keywordTitle {
  max-width: 80%;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 70%;
}

.keywordValue {
  color: var(--secondary500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
}

.progressBarWrapper {
  margin-top: 8px;
  max-width: 70%;
}

.keyword {
  width: 360px;
}

.tooltip {
  width: 350px;
  height: fit-content;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  color: var(--grey900);
  font-size: var(--font-size-sm);
  padding: 12px;
  position: absolute;
  text-align: left;
  z-index: 2;
}
@media screen and (max-width: 450px) {
  .content {
    max-width: 100%;
  }

  .wrapper {
    margin-top: 16px;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }

  .keyword {
    width: 100%;
    max-width: 100%;
  }

  .rowContainer {
    max-width: 90%;
  }

  .progressBarWrapper {
    max-width: 90%;
  }
}
