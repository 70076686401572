.wrapper {
  height: 100%;
  min-height: calc(100vh - 80px);
  font-family: var(--poppins);
}

.blurredWrapper {
  filter: blur(3.5px);
}

.select {
  width: 100%;
}

.generateButton {
  padding-left: 20px;
  padding-right: 20px;
}

.noDisplay {
  display: none;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);
}

.blurredReport {
  filter: blur(3.5px);
}

.rwdFilters {
  display: none;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    height: 100vh;
    overflow: hidden;
  }
}
@media screen and (max-width: 450px) {
  .select {
    display: none;
  }

  .rwdFilters {
    display: block;
    width: 100%;
    margin-bottom: 24px;
  }

  .report {
    height: calc(100svh - 140px);
  }

  .wrapper {
    height: 100dvh;
    overflow: hidden;
    padding: 12px;
  }
}
