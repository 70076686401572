.mapContainer {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  position: relative;
}

.marker {
  height: 50px;
  color: var(--secondary500);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
