.modalTitle {
  color: var(--adminRed500);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.modalWrapper {
  max-width: 40vw;
}

.subInformation {
  line-height: var(--line-height-15);
  white-space: pre-wrap;
}

.label {
  margin: 1.5rem 0 0.25rem;
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  padding: 0;
}

.separator {
  margin: 1rem 0;
  border-bottom: 1px solid var(--grey300);
}

.confirmBtn {
  width: fit-content;
  align-self: flex-end;
  background-color: var(--adminOrange500);
  border-radius: .5rem;
}

.confirmBtn:hover {
  background-color: var(--adminOrange600);
}

.confirmBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}