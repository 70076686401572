.wrapper {
  width: 100%;
}

.checkbox {
  justify-content: space-between;
  width: 100%;
  border-radius: 0;
  margin-bottom: 15px;
}

.label {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
}

.list {
  max-height: 300px;
  overflow-y: auto;
  padding-top: 24px;
}

.list::-webkit-scrollbar {
  display: none;
}

.checkbox:last-child {
  margin-bottom: 0px;
}

.empty {
  color: var(--grey500);
}

.optionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  margin-bottom: 20px;
}
