.formContainer {
  display: flex;
  gap: 10%;
  align-items: center;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 130px 90px;
  align-items: flex-start;
  height: 100%;
}

.leftSide,
.rightSide {
  min-height: 500px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex: 0.7;
}

.formTitle {
  max-width: 60%;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 24px;
}

.svgImage {
  width: 100%;
  height: 100%;
  bottom: 20%;
  position: relative;
  right: 25%;
}

.svgBackground {
  width: 100%;
  height: 100%;
  bottom: 20%;
  left: 25%;
  position: relative;
}

.svgBox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: fit-content;
}

.input {
  margin-bottom: 24px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

.formSubinfo {
  margin-top: 12px;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 50%;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 1;
}
@media screen and (min-width: 1900px) {
  .wrapper {
    align-items: center;
  }
}
@media screen and (max-width: 1300px) {
  .formTitle {
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin-inline: 42px;
  }

  .formContainer {
    margin-top: 30px;
  }

  .leftSide,
  .rightSide {
    min-height: auto;
  }

  .formWrapper {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    justify-content: flex-start;
    margin-inline: 16px;
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    margin-bottom: 60px;
    margin-inline: 0;
  }

  .formContainer {
    flex-direction: row;
    margin-top: 0;
  }

  .rightSide {
    display: none;
  }

  .leftSide {
    z-index: 1;
  }

  .formWrapper {
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .formContainer {
    flex-direction: column;
    margin: 0px;
  }

  .leftSide {
    justify-content: flex-start;
    width: 100%;
    flex: auto;
    margin-left: 0;
  }

  .formTitle {
    max-width: 80%;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-15);
  }

  .rightSide {
    display: none;
  }

  .wrapper {
    margin-bottom: 0;
    margin-inline: 0;
  }
}
