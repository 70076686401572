.wrapper {
  width: 100%;
  height: 40%;
  backdrop-filter: blur(4px);
  background-color: var(--white);
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  padding: 24px 16px;
  position: fixed;
  z-index: 100;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
}

.modalBody {
  color: var(--grey600);
  font-weight: var(--font-weight-medium);
  margin-bottom: 32px;
}

.modalActions {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.modalButton {
  width: 100%;
  background-color: var(--primary500);
  font-size: 16px;
  font-weight: 500;
  padding: 10px 24px;
}

.cancel {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey800);
}

.cancel:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
  color: var(--grey900);
}

.closeIcon {
  width: 18px;
  height: 18px;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
}

.closeIcon:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
