.box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  background-color: var(--white);
  border: 2px solid transparent;
  border-radius: 1rem;
  box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05), 0px 4px 16px 0px rgba(47, 47, 141, 0.09);
  box-sizing: border-box;
  cursor: pointer;
  padding: 3rem 2rem;
  position: relative;
  transition: all 0.2s;
}

.box:hover {
  background-color: var(--secondary50);
  border: 2px solid var(--secondary500);
  box-shadow: 0px 5px 20px 0px rgba(127, 122, 122, 0.1);
  transition: all 0.2s;
}

.boxIcon,
.checkIconWrapper {
  width: var(--icon-xlarge);
  height: var(--icon-xlarge);
}

.boxInfo {
  max-width: 265px;
}

.checkIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: var(--secondary500);
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(45%, -50%);
}

.boxName {
  color: var(--grey900);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 8px;
}

.boxText {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
}

.checkIcon {
  width: var(--icon-small);
  height: var(--icon-small);
  color: var(--white);
  padding-top: 4px;
  transform: translate(2px, 1px);
}

.hideCheck {
  background-color: transparent;
  color: transparent;
}

@media screen and (max-width: 1024px) {
  .box {
    box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05), 0px 4px 16px 0px rgba(47, 47, 141, 0.09);
    padding: 48px 32px;
  }
}

@media screen and (max-width: 768px) {
  .box {
    box-shadow: 0px 2px 4px -2px #1018280f, 0px 4px 8px -2px #1018281a;
    padding: 40px 16px;
  }

  .checkIconWrapper {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .boxName {
    font-size: var(--font-size-md);
  }
}
