.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  margin-bottom: 12px;
}

.subTitle {
  color: var(--adminGreyDarker);
  font-weight: var(--font-weight-medium);
  margin-bottom: 2rem;
}

.input {
  height: 40px;
}

.inputTitle {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 4px;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
}

.recoverPasswordBtn {
  width: 100%;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  color: var(--white);
  font-weight: var(--font-weight-semi);
  padding: 0.5rem 1.5rem;
}

.recoverPasswordBtn:hover {
  background-color: var(--adminOrange700);
}

.recoverPasswordBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.goBackBtn {
  background-color: transparent;
  color: var(--black);
  font-weight: var(--font-weight-semi);
}

.goBackBtn:hover {
  background-color: transparent;
}
