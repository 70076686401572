.questionnaireSection {
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.question {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-15);
}

.radioWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    cursor: pointer;
}

.radio {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: initial;
}

.radio>*,
.radio>*>* {
    user-select: none;
}

.radioText {
    color: var(--grey600);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
    user-select: text;
    cursor: initial;
}

.radioInput {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid var(--adminOrange500);
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    max-height: 2.25rem;
    gap: .5rem;
}

.radioInput>* {
    font-size: var(--font-size-sm);
    color: var(--line-height-16);
    font-weight: var(--font-weight-medium);
    color: var(--grey800);
}

.active {
    background-color: var(--secondary50);
}