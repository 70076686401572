.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(54, 54, 54, 0.3);
  inset: 0;
  overflow: hidden;
  position: fixed;
  z-index: 10;
}

.serviceItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--grey900);
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  overflow: hidden;
  padding: 15px 0px;
  white-space: nowrap;
}

.serviceItemActiveDescription {
  overflow: visible;
  white-space: pre-wrap;
}

.serviceDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.actions {
  display: flex;
  gap: 12px;
}

.serviceName {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 240;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin-right: 15px;
  overflow: hidden;
}

.serviceDescription {
  display: flex;
  max-width: 330px;
  color: var(--grey600);
  font-size: 12px;
  overflow: hidden;
}

.trashIcon {
  width: var(--icon-small);
  height: var(--icon-small);
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 50%;
  color: var(--grey900);
  cursor: pointer;
  padding: 6px;
}

.disabledActions .trashIcon {
  background-color: var(--grey100);
  border: 0.5px solid var(--grey300);
  color: var(--grey500);
  cursor: auto;
  pointer-events: none;
}

.servicePrice {
  display: flex;
  flex-direction: column;
  flex: 150;
  font-weight: 500;
}

.horizontalLine {
  margin: 16px 0px;
  width: 100%;
  height: 1px;
  background-color: var(--grey200);
}

.showMore {
  width: fit-content;
  cursor: pointer;
  font-size: var(--font-size-sm);
}

.showMore:hover {
  text-decoration: underline;
}

.serviceItem:first-child {
  margin-top: 12px;
}

.iconsWrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  align-self: flex-start;
  margin-right: 12px;
}

.content {
  width: 675px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.rwdEditService {
  display: none;
  gap: 4px;
  align-items: center;
  width: fit-content;
  background-color: transparent;
  color: var(--primary500);
  cursor: pointer;
  flex: 150;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding-right: 0;
  stroke: var(--primary500);
}

.rwdEditService:disabled {
  background-color: transparent;
  color: var(--primary300);
  cursor: auto;
  stroke: var(--primary300);
}

.rwdEditService:hover {
  background-color: transparent;
}

.noUserClick {
  color: var(--grey600);
  cursor: auto;
}
@media screen and (max-width: 1024px) {
  .modalWrapper {
    z-index: 20;
  }
}
@media screen and (max-width: 768px) {
  .content {
    width: 100%;
    max-height: calc(100%);
    overflow-y: auto;
  }

  .servicePrice {
    max-width: 45%;
    text-align: right;
  }

  .actions {
    display: none;
  }

  .rwdEditService {
    display: block;
    align-self: flex-end;
  }
}
@media screen and (max-width: 500px) {
  .content {
    width: 100%;
  }

  .rwdRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .iconsWrapper {
    display: none;
  }

  .serviceDescription {
    max-width: 80%;
  }

  .serviceHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .serviceName {
    max-width: 195px;
    height: fit-content;
    color: var(--grey900);
    flex: 150;
    font-weight: var(--font-weight-regular);
    margin-right: 0px;
    overflow: hidden;
    word-wrap: break-word;
  }

  .servicePrice {
    max-width: 100%;
    text-align: right;
  }
}

@media screen and (max-width: 450px) {
  .rwdButtonServiceWrapper {
    display: flex;
  }

  .serviceDetails {
    display: flex;
    flex-direction: row;
  }

  .servicePrice {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
  }
}
