.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 70%;
  background-color: var(--adminGreyLightActive);
  border: 1px solid var(--adminGreyNormal);
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.clientWrapper {
  max-width: none;
}

.rowContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
}

.input {
  width: 100%;
}

.cardNameInput {
  width: calc(100% / 2 - 18px);
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

@media screen and (max-width: 500px) {
  .rowContainer {
    flex-direction: column;
  }

  .wrapper {
    margin-top: 2rem;
    padding: 1rem;
  }

  .cardNameInput {
    width: 100%;
  }
}
