.wrapper {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 2rem 1.5rem;
}

.wrapper::-webkit-scrollbar {
  width: 4px;
}

.rowContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.saveBtn {
  background-color: var(--adminOrange500);
  border-radius: 0.5rem;
  color: var(--white);
  padding: 0.5rem 1.5rem;
}

.saveBtn:hover {
  background-color: var(--adminOrange600);
}

.saveBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 1;
}
