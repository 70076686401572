.heading {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  align-self: center;
  color: var(--grey800);
  font-size: 28px;
  font-weight: 600;
}

.pill {
  margin-top: 5px;
  background-color: var(--secondary100);
  border: 0px;
  border-radius: 16px;
  color: var(--secondary500);
  font-size: 14px;
  font-weight: 500;
  padding: 4px 12px;
}

.subHeading {
  margin-top: 12px;
  width: 500px;
  color: var(--grey700);
  font-size: 16px;
  font-weight: 400;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}

.addTemplateBtnIcon {
  justify-content: flex-end;
  color: var(--white);
  stroke-width: 2px;
}

.addTemplateBtn {
  display: flex;
  gap: 8px;
  border-radius: 32px;
  margin-left: auto;
  padding: 12px 32px;
}

.templateLimitReached {
  display: flex;
  gap: 8px;
  margin-top: 36px;
  align-items: center;
  width: fit-content;
  color: var(--primary800);
  font-size: 14px;
  font-weight: 500;
}

.infoIcon {
  width: var(--icon-small);
  height: var(--icon-small);
}

@media screen and (max-width: 768px) {
  .rowContainer {
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .heading {
    font-size: var(--font-size-xl);
  }

  .subHeading {
    width: 100%;
  }

  .addTemplateBtn {
    justify-content: flex-end;
    background-color: transparent;
    color: var(--primary500);
    margin-left: 0px;
  }

  .addTemplateBtn:hover {
    background-color: transparent;
    color: var(--primary600);
  }

  .addTemplateBtnIcon {
    display: none;
  }
}


@media screen and (max-width: 450px) {
  .rowContainer {
    flex-direction: column;
    gap: 4px;
  }

  .addTemplateBtn {
    display: none;
  }

}

