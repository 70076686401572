.image {
  max-width: 8rem;
  max-height: 8rem;
  aspect-ratio: 1;
  background-color: var(--grey400);
  border-radius: 1rem;
  box-sizing: border-box;
  object-fit: cover;
}

.loader {
  margin-inline: auto;
}

.noImage {
  margin: 0 auto;
  width: 128px;
  height: 128px;
  color: var(--grey500);
}

@media screen and (max-width: 1024px) {
  .image {
    width: 115px;
    height: 115px;
    right: 12px;
  }

  .loader,
  .noImage {
    width: 90px;
    height: 90px;
    right: 12px;
  }
}
@media screen and (max-width: 768px) {
  .image {
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;
    margin-right: 12px;
  }

  .noImage {
    width: 60px;
    height: 60px;
  }
}
