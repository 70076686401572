.seoStatusIcon {
  width: 24px;
  height: 24px;
}

.tableWrapper {
  max-height: calc(100vh - 19rem);
  margin-bottom: 1rem;
  padding-inline: 1.5rem;
}

.tableStyle {
  display: block;
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}
