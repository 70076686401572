.options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 18px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
}

.options > div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.optionIcon {
  width: 18px;
  height: 18px;
  color: var(--grey700);
  stroke: var(--grey700);
}
