.navWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  min-width: 100vw;
  background-color: transparent;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 1.5rem 40px;
  position: relative;
  z-index: 2;
}

.navWrapper > ul > li {
  border-bottom: 1px solid transparent;
  list-style: none;
  padding-bottom: 2px;
}

.logoWrapper {
  position: relative;
}

.logoSign {
  position: absolute;
  right: -20%;
  top: -15%;
}

.navLinks {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  margin: 0;
  align-items: center;
  height: 100%;
  overflow: hidden;
  padding-inline: 1rem;
}

.navWrapper > ul > li:hover {
  border-bottom: 1px solid var(--white);
  padding-bottom: 2px;
}

.navWrapper > ul > li > a {
  color: var(--white);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  text-wrap: nowrap;
}

.navWrapper > div {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.button {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}

.button:hover {
  background-color: transparent;
  color: var(--grey300);
}

.signUpBtn {
  background-color: var(--white);
  border-radius: 0.5rem;
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  padding: 0.75rem 2rem;
}

.signUpBtn:hover {
  background-color: var(--primary50);
  color: var(--primary500);
}

.logoIcon {
  width: 9.125rem;
  min-width: 9.125rem;
  height: 2rem;
  min-height: 2rem;
  color: var(--white);
  cursor: pointer;
}

.burgerMenu {
  display: none;
  width: 2.5rem;
  height: 2.5rem;
  color: var(--white);
  position: relative;
  z-index: 3;
}

@media screen and (max-width: 1200px) {
  .navWrapper > ul {
    display: none;
  }

  .burgerMenu {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .navWrapper {
    padding: 20px 20px 0;
  }

  .actionsWrapper > .button {
    display: none;
  }

  .container > div > p {
    padding-inline: 1rem;
  }
}
