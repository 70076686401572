.wrapper {
    padding: 4rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 1.125rem;
    box-shadow: var(--shadowMD);
    position: relative;
}

.header {
    margin-bottom: 1.5rem;
}

.wrapper p {
    color: var(--grey700);
    font-size: var(--font-size-sm);
    padding: 0;
    margin: 0;
}

.actionsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
}

.actionsWrapper>* {
    padding: 10px 24px;
    max-height: 2.75rem;
    box-sizing: border-box;
    min-width: 11rem;
}

.goBackBtn {
    background-color: var(--grey50);
    color: var(--grey900);
    border: 1px solid var(--grey300);
}

.goBackBtn:hover {
    background-color: var(--grey100);
    border-color: var(--grey400);
}

.confirmBtn {
    background-color: var(--secondary500);
    width: fit-content;
    gap: .5rem;
    display: flex;
    align-items: center;
}

.confirmBtn:hover {
    background-color: var(--secondary600);
}

.title {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semi);
    line-height: var(--line-height-lg);
    color: var(--grey900);
    margin: 0;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.exampleContent,
.boxTypeWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .75rem;
    margin-bottom: .5rem;
}

.boxTypeWrapper>* {
    margin: 0
}

.review {
    border: 1px solid var(--grey300);
    border-radius: 10px;
    padding: .75rem;
}

.reviewAuthor {
    color: var(--grey900);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    margin-bottom: .25rem;
}

.reviewText {
    color: var(--grey700);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-16);
    margin-top: .5rem;
}

.rocketIcon {
    width: 1.125rem;
    height: 1.125rem;
}

.overlay {
    position: fixed;
    inset: 0;
    backdrop-filter: blur(2px);
}

.starIcon {
    fill: var(--yellow500);
    width: 1.5rem;
    height: 1.5rem;
}

.reviewType {
    display: flex;
    align-items: center;
    gap: .25rem;
    color: var(--grey900);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semi);
    margin-bottom: .5rem;
}