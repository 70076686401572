.wrapper {
  width: 100%;
}

.sectionName {
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.additionalInformation {
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
  margin-bottom: 16px;
}

.highlightedText {
  font-weight: var(--font-weight-medium);
}

.input {
  display: flex;
  gap: 10px;
  width: 100%;
}

.keywordInput {
  margin: 0;
  height: 54px;
  box-shadow: none;
  padding: 0;
}

.horizontalLine {
  margin: 24px auto;
  width: 100%;
  border-bottom: 1px solid var(--grey300);
}

.keywordsWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 12px;
  width: 100%;
  flex-wrap: wrap;
}

.sugggestedKeyword {
  display: flex;
  gap: 0.25rem;
  align-items: baseline;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

.pill {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: fit-content;
  background-color: var(--white);
  border: 1px solid var(--secondary500);
  border-radius: 32px;
  color: var(--grey800);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  padding: 7px 16px;
  word-break: keep-all;
}

.selectedPill {
  background-color: var(--secondary50);
  border: 1px solid var(--secondary500);
  color: var(--grey800);
}

.questionMarkInfo {
  width: 18px;
  height: 18px;
  cursor: pointer;
  stroke: var(--primary500);
}

.questionMarkInfo:hover {
  filter: brightness(0.8);
}

.categoryTooltipStyle {
  width: 320px;
  background-color: var(--white);
  border-radius: 6px;
  bottom: 0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  color: var(--grey700);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  padding: 0.5rem;
  position: absolute;
  right: 10px;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .keywordsModalWrapper {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
    box-sizing: border-box;
  }

  .keywordsModalWrapper {
    width: 100%;
    height: calc(100vh - 80px);
    max-height: none;
    overflow-y: auto;
    top: 5rem;
  }
}
