.offerLink {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 20px;
  align-items: center;
  color: var(--primary500);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: var(--line-height-16);
  margin-bottom: 1rem;
}

.offerLink:hover {
  color: var(--primary600);
  stroke: var(--primary600);
}

.arrowRightIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  stroke: var(--primary500);
  stroke-width: 1.4px;
  transform: rotate(180deg);
}
