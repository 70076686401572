.wrapper {
  margin: 24px;
  height: 100%;
  background-color: var(--white);
  border: 1px solid var(--grey200);
  border-radius: 14px;
  box-shadow: 0px 5px 20px rgba(127, 122, 122, 0.1);
  font-family: var(--poppins);
  overflow: hidden;
  overflow-y: auto;
  padding: 28px 36px;
}

.adminPreview {
  margin: 0;
  border: none;
  border-radius: none;
  box-shadow: none;
  padding-top: 0;
}

.wrapper::-webkit-scrollbar {
  width: 8px;
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: var(--grey400);
  border-radius: 4px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #d3d3d383;
  inset: 0;
  pointer-events: none;
  position: absolute;
  user-select: none;
  z-index: 2;
}

.loader {
  z-index: 2;
}

.wrapper:has(.empty) {
  height: 100%;
  overflow: hidden;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform: translateY(-10%);
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin: 0;
    width: 100%;
    border-radius: 0px;
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 16px;
  }
}
@media screen and (max-width: 450px) {
  .rwdFooter {
    display: block;
  }

  .wrapper {
    overflow-y: scroll;
  }

  .empty {
    transform: translateY(0);
  }
}
