.actionWrapper {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid var(--grey200);
  padding-top: 2rem;
}

.modalContent {
  max-width: 40%;
  max-height: 90vh;
}

.profileContentWrapper {
  padding-block: 2rem;
}

.cancelSubscriptionTextWrappper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.cancelSubscriptionTitle {
  color: var(--grey900);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.cancelSubscriptionSubtitle {
  color: var(--grey700);
  line-height: var(--line-height-15);
}

.text {
  color: var(--grey800);
  font-size: 1rem;
  margin-bottom: 20px;
}

.cancelButton {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  padding: 10px 1.5rem;
}

.cancelButton:hover {
  background-color: var(--grey100);
}

.boldDate {
  color: var(--grey900);
  font-weight: var(--font-weight-semi);
}

.textArea {
  min-height: 6rem;
  margin-bottom: 2rem;
}

.cancelSubscriptionTextWrappper>span {
  color: var(--grey800);
  white-space: pre-wrap;
  line-height: var(--line-height-15);
}

@media screen and (max-width: 1024px) {
  .modalContent {
    max-width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .modalContent {
    width: 100vw;
    max-width: none;
    left: 0;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}