.modal {
  display: flex;
  flex-direction: column;
  max-width: 95vw;
  min-width: 95vw;
  max-height: 95vh;
  min-height: 95vh;
  background-color: var(--white);
  border: 1px solid var(--grey100);
  border-radius: 18px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  left: 50%;
  overflow: hidden;
  padding-bottom: 1.5rem;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  background-color: rgba(125, 125, 125, 0.36);
  inset: 0;
  overflow: hidden;
  position: fixed;
  z-index: 2;
}
