.customLoader {
  width: 50px;
  height: 50px;
  animation: s2 1s infinite linear;
  border: 7px solid;
  border-color: var(--adminOrange300);
  border-radius: 50%;
  border-right-color: var(--adminOrange600);
}
@keyframes s2 {
  to {
    transform: rotate(1turn);
  }
}
