.dataWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.singleRow {
  display: flex;
  gap: 1rem;
}

.phoneInput {
  width: calc(100% / 2 - 0.5rem);
}

.pill {
  background-color: var(--adminOrange400);
  border: none;
  border-radius: 2rem;
  box-sizing: border-box;
  color: var(--black);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  padding: 4px 8px;
}

.readOnlyData {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 0.5rem;
}

.record {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  color: var(--adminGreyDarker);
  font-weight: var(--font-weight-medium);
}

.value {
  color: var(--black);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.userDetailsTitle {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
}

.noActivePill {
  background-color: var(--adminGreyNormalActive);
}
