.wrapper {
  display: flex;
  flex-direction: column;
  width: 305px;
  border-right: 1px solid var(--grey200);
}

.hidden {
  display: none;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    width: 100%;
    background-color: rgba(54, 54, 54, 0.3);
  }

  .hidden {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    background-color: rgba(54, 54, 54, 0.3);
    position: relative;
    z-index: 2;
  }
}
