.businessProfileCompletion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  flex: 2;
  padding: 24px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-right: 1px solid var(--grey300);
  flex: 1;
  padding-right: 12px;
}

.rightSide {
  height: 100%;
  box-sizing: border-box;
  flex: 1;
  margin-left: 24px;
  padding-bottom: 24px;
}

.loader {
  margin: auto;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--grey800);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.progressBar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.showDetails {
  display: flex;
  margin-top: auto;
  align-items: flex-end;
  color: var(--primary500);
  cursor: pointer;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 12px;
}

.showDetails:hover {
  color: var(--primary600);
}

.completionLabel {
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

@media screen and (max-width: 1360px) {
  .progressBar {
    margin-top: 0;
  }
}

@media screen and (max-width: 900px) {
  .progressBar {
    margin-top: 12px;
  }

  .leftSide {
    justify-content: flex-start;
  }

  .showDetails {
    align-items: flex-start;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .businessProfileCompletion {
    padding: 16px;
  }
}

@media screen and (max-width: 450px) {
  .businessProfileCompletion {
    height: 100%;
    max-height: 100%;
    padding: 16px;
  }

  .rowContainer {
    flex-direction: column;
  }

  .leftSide {
    border-bottom: 1px solid var(--grey200);
    border-right: none;
    margin-bottom: 14px;
  }

  .progressBar {
    margin-top: 24px;
  }

  .completionLabel {
    margin-bottom: 8px;
  }

  .title {
    align-items: flex-start;
    width: 100%;
    justify-self: flex-start;
  }

  .showDetails {
    margin: 14px;
  }
}
