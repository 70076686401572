.cardHeaderActions {
  display: none;
}

.actionIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  stroke: var(--grey600);
}

.actionWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  padding: 16px;
}

@media screen and (max-width: 768px) {
  .cardHeaderActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
  }

  .actionWrapper {
    padding-right: 0;
  }
}

@media screen and (max-width: 450px) {
  .cardHeaderActions {
    padding-right: 16px;
  }
}