.tableWrapper {
  max-height: calc(100vh - 19rem);
  margin-bottom: 1rem;
}

.tableStyle {
  display: block;
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}

.body {
  height: 100%;
  box-sizing: border-box;
  padding-inline: 1.5rem;
}
