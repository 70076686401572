.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--grey300);
    border-radius: .25rem;
    padding: 1rem;
}

.wrapper>*>p,
.dataItemWrapper>p,
.value>*>p,
.connectedBusinessProfile>p {
    margin: 0;
    padding: 0;
}

.dataItemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.value {
    max-width: 60%;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    margin-left: auto;
    text-align: end;
    text-transform: capitalize;
    text-wrap: wrap;
}

.value>* {
    max-width: none;
}

.dataItemWrapper:nth-last-of-type(3) {
    order: 4;
}

.dataItemWrapper:nth-last-of-type(3)>* {
    max-width: 100%;
    margin-left: 0;
    text-align: start;
}

.key {
    color: var(--grey700);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-16);
}

.connectedBusinessProfile {
    margin-right: auto;
    text-align: left;
}

.connectedBusinessProfile>p:nth-of-type(2) {
    color: var(--grey800);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
}

.actionButton {
    margin: 0;
    margin-top: 1.5rem;
    align-self: flex-end;
    background-color: transparent;
    color: var(--primary500);
    font-size: var(--font-size-sm);
    order: 5;
    padding: 0;
}

.actionButton:hover,
.actionButton:focus {
    background-color: transparent;
    color: var(--primary700);
}

.dateWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.dateLabel {
    color: var(--grey700);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-16);
    margin-right: auto;
}

.actionButton:hover,
.actionButton:focus {
    color: var(--primary700);
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.actions>* {
    background-color: transparent;
    border: none;
    color: var(--grey900);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    padding: 6px 16px;
}

.value .dateWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: none;
}

.value:has(.dateWrapper) {
    justify-content: space-between;
    width: 100%;
    min-width: 100%;
    margin-left: 0;
}