.userImage {
  width: 100%;
  max-height: clamp(200px, 20vh, 240px);
  aspect-ratio: 1/1;
  border: 2px solid var(--grey300);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  object-fit: cover;
  object-position: center;
  user-select: none;
}

.closeIcon {
  width: var(--icon-regular);
  height: var(--icon-regular);
  background-color: #3f3f3f99;
  border: 1px solid var(--grey400);
  border-radius: 50%;
  box-shadow: 0px 1px 2px 0px #1018280f;
  color: var(--white);
  cursor: pointer;
}

.closeIcon:hover {
  background-color: #31313199;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  align-items: center;
  width: 100%;
  max-height: clamp(200px, 20vh, 240px);
  object-fit: contain;
  position: relative;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--icon-regular);
  height: var(--icon-regular);
  background-color: #3f3f3f99;
  border: 1px solid var(--grey400);
  border-radius: 50%;
}

.checkboxStyle {
  border-radius: 50%;
  color: white;
}

.borderSelected {
  border: 2px solid var(--secondary500);
}

img.userImage {
  border: 1px solid transparent;
}

.plannedItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(16, 24, 40, 0.1), rgba(16, 24, 40, 0.1));
  background-size: cover;
  position: relative;
}

.plannedPill {
  background-color: #3f3f3f99;
  border: 1px solid var(--grey400);
  color: var(--white);
}

.itemActions {
  display: flex;
  gap: 4px;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}

.changeCoverBtn {
  background-color: var(--primary50);
  border: 1px solid var(--primary500);
  bottom: 1rem;
  color: var(--primary500);
  font-size: var(--font-size-xs);
  left: 1rem;
  padding: 4px 12px;
  position: absolute;
  z-index: 0;
}

.changeCoverBtn:hover {
  background-color: var(--primary100);
}

.mockedPhotoLoader {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  align-items: center;
  width: 100%;
  height: 00px;
  border: 1px solid var(--grey300);
  border-radius: 8px;
}

@media screen and (max-width: 450px) {
  .userImage {
    width: 100%;
    height: 100%;
  }
}
