.questionnaireSection {
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.question {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-15);
}

.keywordsWrapper {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
}