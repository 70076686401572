.modelWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid var(--grey300);
  border-radius: 1.5rem;
  box-shadow: 0px 5px 27px 0px #64646f14;
  box-sizing: border-box;
  padding: 1.5rem;
  position: relative;
}

.popularWrapper {
  border: 2px solid var(--secondary500);
}

.modelTitle {
  background-color: var(--primary50);
  border-radius: 6px;
  color: var(--primary500);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
  padding: 10px 26px;
}

.modelTitle.popular {
  background-color: var(--secondary50);
  color: var(--secondary500);
}

.row {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
}

.comingSoonWrapper>.button {
  margin-top: 0;
  background-color: var(--primary300);
  cursor: initial;
  pointer-events: none;
  user-select: none;
}

.comingSoonWrapper .popularLabel {
  display: none;
}

.comingSoonWrapper .row,
.comingSoonWrapper .subTitle,
.comingSoonWrapper .price,
.comingSoonWrapper .modelFeaturesList {
  opacity: 0.6;
}

.popularLabel {
  background-color: var(--secondary500);
  border-radius: 6px;
  color: var(--white);
  font-size: var(--font-size-xs);
  padding: 0.5rem 0.75rem;
}

.subTitle {
  margin: 1rem 0 28px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}

.price {
  color: var(--primary900);
  font-size: var(--display-lg);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-14);
}

.price>span {
  color: var(--grey600);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

.button {
  margin-top: 2rem;
  width: 100%;
  background-color: var(--primary500);
  border-radius: 8px;
  color: var(--white);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  padding: 0.75rem 2rem;
}

.button.popularBtn {
  background-color: var(--secondary500);
}

.button.popularBtn:hover {
  background-color: var(--secondary600);
}

.modelFeaturesList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 3rem 0 4.375rem 0;
}

.featureText {
  display: flex;
  gap: 14px;
  align-items: center;
  color: var(--primary900);
  font-size: var(--font-size-sm);
}

.comingSoon {
  margin-top: auto;
  color: var(--primary500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 0.5rem;
  z-index: 2;
}

.popularWrapper .comingSoon {
  color: var(--secondary500);
}

.comingSoonWrapper .popularBtn {
  background-color: var(--secondary200);
}

.comingSoonWrapper.popularWrapper {
  border-color: var(--secondary200);
}

.showPlanDetails {
  display: flex;
  flex-direction: flex;
  gap: 1ch;
  margin-top: 0.75rem;
  align-items: center;
  color: var(--primary500);
  cursor: pointer;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
}

.icon {
  display: flex;
  width: 18px;
  height: 18px;
}

.priceMethodWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.chooseMethodTitle {
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.trialLabel {
  margin-top: 3rem;
  color: var(--grey900);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
  margin-bottom: auto;
}

.trialLabel p {
  margin-top: 2rem;
}

.popularBtn:disabled {
  background-color: var(--secondary300);
}

.popularBtn:disabled:hover {
  background-color: var(--secondary300);
}

.currentSubscription {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.current {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 101.25%;
  height: 50px;
  background-color: var(--primary500);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  color: var(--white);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  left: -2px;
  line-height: var(--line-height-15);
  position: absolute;
  top: -3rem;
}

@media screen and (max-width: 1024px) {
  .modelWrapper {
    width: 45%;
    max-width: none;
    margin-inline: 0;
  }

  .modelFeaturesList {
    margin-bottom: 60px;
  }

  .button {
    margin-top: 2rem;
    padding: 6px 16px;
  }
}

@media screen and (max-width: 600px) {
  .modelWrapper {
    width: auto;
    max-width: none;
    box-sizing: border-box;
    margin-inline: 1rem;
  }

  .modelFeaturesList {
    margin-bottom: 60px;
  }

  .mobileWrapper {
    margin: 0;
    padding: 1rem;
  }

  .button {
    margin-top: 2rem;
    padding: 6px 16px;
  }

  .subTitle {
    margin-bottom: auto;
  }
}