.showMoreBtn {
  background-color: transparent;
  border: none;
  color: var(--primary500);
  padding: 0;
}

.showMoreBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.proposal {
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
  border-radius: 10px;
  padding: 1.5rem;
}

.useProposalBtn {
  gap: 4px;
  margin-top: 1.5rem;
  background-color: var(--grey100);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  margin-left: auto;
  padding: 6px 16px;
}

.useProposalBtn:hover {
  background-color: var(--grey100);
  border: 1px solid var(--grey500);
  color: var(--grey900);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

