.title {
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 14px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.horizontalLine {
  margin: 24px auto;
  width: 100%;
  border-bottom: 1px solid var(--grey300);
}

.wrapper.keywordSelectWrapper {
  width: 340px;
  padding: 0;
}

.wrapper.keywordSelectWrapper .horizontalLine {
  display: none;
}

.rwdInput {
  width: calc(100% - 78px);
}
