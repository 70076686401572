.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 30rem;
  border-radius: 0.5rem;
  box-shadow: var(--shadowMD);
  padding: 2rem;
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  margin-bottom: 12px;
}

.subTitle {
  color: var(--adminGreyDarker);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 2rem;
}

.input {
  height: 40px;
  margin-bottom: 2rem;
}

.inputTitle {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 4px;
}

.confirmBtn {
  width: 100%;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.confirmBtn:hover {
  background-color: var(--adminOrange700);
}

.confirmBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.passwordHelper {
  display: flex;
  gap: 5px;
  align-items: center;
  align-self: flex-start;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  margin-bottom: 24px;
}

.infoIcon {
  width: 16px;
  height: 16px;
  color: var(--grey600);
  stroke: var(--grey600);
}

.passwordInputWrapper {
  position: relative;
}

.showPasswordIcon {
  width: 1rem;
  color: var(--adminGreyDarkActive);
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 40%;
}

.showPasswordIcon:hover {
  color: var(--black);
}

.loaderWrapper {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
  align-items: center;
  height: 300px;
}
