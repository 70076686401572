.actionsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 50px;
  margin-bottom: 32px;
}

.searchBox {
  max-width: 40%;
  max-height: 50px;
  color: var(--grey600);
  flex: 2;
}

.maginfierIcon {
  width: var(--icon-regular);
  height: var(--icon-regular);
  bottom: 36px;
  color: var(--grey600);
  left: 28px;
  position: relative;
}

.searchInput {
  border: 1px solid var(--grey300);
  border-radius: 42px;
  color: var(--grey600);
  padding: 12px 16px;
}

.deleteCardBtn {
  display: flex;
  gap: 8px;
  background-color: transparent;
  border: none;
  color: var(--primary500);
  margin-left: auto;
}

.deleteCardBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.deleteCardBtn:disabled {
  background-color: transparent;
  border: none;
  color: var(--grey500);
}

.select {
  width: 160px;
  max-width: 160px;
  min-width: 160px;
  padding: 12px 24px;
}

.trashIconStyle {
  width: var(--icon-small);
  height: var(--icon-small);
}

.filters {
  display: flex;
  gap: 4px;
  width: 60%;
  max-height: 48px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .actionsWrapper {
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .filters {
    flex-direction: column;
    width: 60%;
  }

  .select {
    width: 65%;
    max-width: 65%;
    min-width: 65%;
  }

  .searchInput {
    height: 22px;
  }

  .searchBox {
    max-width: 60%;
    max-height: 60%;
    flex: 1;
  }
}
