.termsConditions {
  margin-top: 8px;
  color: var(--grey700);
  font-size: 14px;
  font-weight: 400;
  line-height: var(--line-height-16);
  white-space: pre-line;
}

@media screen and (max-width: 450px) {
  .termsConditions {
    width: 100%;
    word-wrap: break-word;
  }
}
