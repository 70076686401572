.categoryType {
  margin-top: 20px;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
}

.categoryTitle {
  color: var(--grey900);
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  margin-bottom: 24px;
}

.icon {
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
}

.addServices {
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
}

.servicesList {
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-top: 24px;
  flex-wrap: wrap;
}

.displayName {
  display: flex;
  width: 100%;
}

.servicePill {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: fit-content;
  background-color: var(--white);
  border: 1px solid var(--secondary500);
  border-radius: 32px;
  color: var(--grey800);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  padding: 7px 16px;
  white-space: pre-wrap;
}

.selectedService {
  background-color: var(--secondary50);
  border: 1px solid var(--secondary500);
  color: var(--grey800);
}

.noServices {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  margin: 0;
  align-items: center;
  background-color: var(--white);
  border: none;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  margin-bottom: 12px;
  padding: 0;
  text-align: center;
}

.actionsWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 40px;
  align-items: center;
  width: 100%;
}

.saveChangesButton {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding: 10px 24px;
}

.saveChangesButton:disabled {
  background-color: var(--primary100);
  cursor: auto;
  filter: none;
}

.cancelButton {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding: 10px 24px;
}

.cancelButton:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
  filter: none;
}

.closeIcon {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  fill: var(--grey600);
}

.closeIcon:hover {
  fill: var(--grey900);
}

.horizontalLine {
  margin: 24px 0;
  border-bottom: 1px solid var(--grey200);
}
@media screen and (max-width: 768px) {
  .cancelButton,
  .saveChangesButton {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .actionsWrapper {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .saveChangesButton,
  .cancelButton {
    width: 100%;
  }
}
