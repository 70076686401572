.wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
  flex: 1;
  font-family: var(--poppins);
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey200);
  overflow: hidden;
  padding: 24px 40px 24px 40px;
  row-gap: 16px;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
}

.sectionHeader {
  border-bottom: 1px solid var(--primary200);
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  padding-bottom: 12px;
  padding-top: 40px;
}

.subHeader {
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
  background-color: var(--white);
  overflow-x: auto;
  user-select: none;
}

.subHeader::-webkit-scrollbar {
  display: none;
}

.cell {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  box-sizing: border-box;
  color: var(--grey500);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 4px;
}

.name.focused {
  border-bottom: 2px solid var(--primary500);
  color: var(--primary500);
}

.name {
  padding: 5px 4px;
  text-wrap: nowrap;
}

.body {
  height: 100%;
  flex: 1;
  overflow: hidden;
  overflow-y: scroll;
  padding: 24px;
  position: relative;
}

.body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.body::-webkit-scrollbar-track {
  background: var(--white);
  border-radius: 8px;
}

.body::-webkit-scrollbar-thumb {
  background: var(--grey400);
  border-radius: 8px;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #eeeeeeaa;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 100;
}

.form {
  background-color: var(--white);
  border: 1px solid var(--primary200);
  border-radius: 14px;
  padding-left: 24px;
  padding-right: 26px;
}

.section {
  border-bottom: 1px solid var(--grey200);
  padding: 32px 0;
}

.formList {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.section:first-child {
  border-top: none;
  padding-top: 26px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.admimPreview {
  overflow: visible;
  padding-left: 1.5rem;
  padding-top: 0;
}

@media screen and (max-width: 1024px) {
  .body {
    max-height: calc(100% - 90px);
    overflow-y: auto;
    padding: 0px;
    top: 0px;
  }

  .formList {
    gap: 32px;
  }

  .header {
    display: flex;
    border-bottom: none;
    padding: 24px 24px 8px 24px;
    padding-bottom: 0px;
    z-index: 1;
  }

  .form {
    border-radius: 0px;
  }

  .sectionHeader {
    padding: 24px;
    padding-bottom: 12px;
    padding-left: 0px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    overflow: hidden;
  }

  .header {
    display: flex;
    border-bottom: none;
    padding: 16px 16px 8px 16px;
    padding-bottom: 0px;
    z-index: 1;
  }

  .title {
    font-size: var(--font-size-lg);
  }

  .cell {
    white-space: nowrap;
  }

  .subHeader {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 8px;
    white-space: nowrap;
  }

  .subHeader::-webkit-scrollbar {
    display: none;
  }

  .sectionHeader {
    font-size: var(--font-size-md);
  }

  .body {
    overflow-y: auto;
    padding: 0px;
    top: 0px;
  }

  .form {
    border: none;
    border-radius: 0px;
    overflow: hidden;
    padding: 16px;
    padding-top: 0px;
  }

  .form:first-of-type {
    padding-top: 0px;
  }

  .sectionHeader:first-child {
    border-bottom-color: var(--primary500);
    padding-top: 12px;
  }

  .formList {
    gap: 0;
  }
}
