.icon {
  width: var(--icon-small);
  height: var(--icon-small);
  stroke: var(--grey500);
}

.subInformationBox {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin: 12px 0 12px 6px;
  align-items: center;
  color: var(--grey600);
  font-size: var(--font-size-xs);
}
