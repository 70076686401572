.wrapper {
  height: 100%;
  background-color: var(--white);
  border-radius: 0.75rem;
  overflow: hidden;
  overflow-y: auto;
  padding: 24px;
}

.wrapper::-webkit-scrollbar {
  width: 0.25rem;
}

.header {
  border-bottom: 1px solid var(--primary200);
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  padding-bottom: 10px;
}

.loaderWrapper {
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
}

.error {
  margin-top: 1rem;
  color: var(--red500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

@media screen and (max-width: 1240px) {
  .wrapper {
    box-sizing: border-box;
  }

  .wrapper::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin: 0;
    border-radius: 0;
    padding: 1rem;
  }
}
