.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  background-color: rgba(125, 125, 125, 0.36);
  flex-wrap: wrap;
  inset: 0;
  overflow: hidden;
  position: fixed;
  z-index: 2;
}

.item {
  width: 100%;
  max-width: 80vw;
  max-height: 80vh;
  border: 1px solid var(--grey300);
  border-radius: 8px;
  border-top-left-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  object-fit: contain;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.arrow {
  width: 32px;
  height: 32px;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--grey800);
  cursor: pointer;
  padding-left: 0px;
  position: absolute;
  stroke-width: 2px;
  top: 50%;
  z-index: 2;
}

.arrowBack {
  left: 10%;
  transform: translate(-50%, -50%);
}

.arrowNext {
  right: 10%;
  transform: translate(50%, -50%) rotate(180deg);
}

.galleryPill {
  background-color: var(--secondary50);
  border: 1px solid var(--secondary500);
  color: var(--secondary500);
  font-size: var(--font-size-sm);
  text-align: center;
  white-space: wrap;
}

.pillWrapper {
  left: 0;
  position: absolute;
  top: 0;
}
