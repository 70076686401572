.actionsWrapper {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  justify-content: flex-end;
  align-self: center;
  box-sizing: border-box;
  margin-left: auto;
}

.tabletActions {
  display: none;
}

.tabletBtn {
  display: none;
}

.cancelButton {
  max-width: min-content;
  background-color: transparent;
  border: none;
  color: var(--primary500);
  padding-inline: 0;
}

.cancelButton:hover {
  background-color: transparent;
  color: var(--primary600);
}

.displayNone {
  display: none;
}

.filtersTitle {
  display: flex;
  justify-content: space-between;
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  margin-bottom: 8px;
}

@media screen and (max-width: 1024px) {
  .button {
    display: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--grey100);
    border-top: 1px solid var(--primary50);
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    box-sizing: border-box;
    margin-bottom: 24px;
    padding: 14px 24px;
  }

  .tabletBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    width: fit-content;
    border: none;
    margin-left: auto;
    padding: 0.5rem 1.5rem;
  }

  .tabletBtn:disabled {
    background-color: transparent;
    border: none;
    color: var(--primary400);
  }

  .actionsWrapper {
    display: none;
  }

  .tabletActions {
    display: flex;
    justify-content: flex-end;
  }
}
