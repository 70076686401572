.cell {
  background-color: var(--white);
  border-bottom: 1px solid var(--adminGreyLightActive);
  box-sizing: border-box;
  color: var(--black);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  overflow: hidden;
  padding: 12px 16px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.clientCell {
  color: var(--grey800);
  font-size: var(--font-size-sm);
}

.clientCell.cellExpanded {
  color: var(--primary500);
}

.evenRow,
.evenRow .cell,
.evenRow .chevronHeader,
.evenRow .buttonCell {
  background-color: var(--adminGreyLightActive);
}

.chevronHeader,
.buttonCell {
  width: 60px;
  min-width: 60px;
  background-color: var(--white);
  position: sticky;
}

.chevronHeader {
  border-right: 1px solid var(--adminGreyNormal);
  left: 0;
}

.buttonCell {
  right: 0;
}

.chevronIcon {
  width: 20px;
  cursor: pointer;
  margin-inline: auto;
  padding: 8px;
  transition: all 0.2s ease-in-out;
}

.chevronActive {
  color: var(--adminOrange600);
  transform: rotate(180deg);
}

.clientChevron {
  color: var(--primary500);
}

.tooltipStyle {
  background-color: var(--white);
  border-radius: 0.5rem;
  bottom: 0;
  box-shadow: 0px 7px 29px 0px #64646f33;
  color: var(--black);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semi);
  padding: 4px 12px;
  position: absolute;
  right: 60px;
  text-align: right;
  z-index: 2;
}

.editButton {
  margin: 20px 16px;
  background-color: transparent;
  border: 1px solid var(--adminOrange600);
  border-radius: 0.5rem;
  color: var(--adminOrange600);
  padding: 0.5rem;
}

.editButton:hover {
  background-color: transparent;
  border-color: var(--adminOrange700);
  color: var(--adminOrange700);
}

.clientEditButton {
  background-color: var(--white);
  border: 1px solid var(--primary500);
  border-radius: 50%;
  color: var(--primary500);
  padding: 0.5rem;
}

.clientEditButton:hover {
  background-color: var(--white);
  border-color: var(--primary600);
  color: var(--primary600);
}


@media screen and (max-width: 450px) {
  .buttonCell {
    position: static;
  }
}