.container {
  margin-top: 100px;
  z-index: 50;
}

.background {
  background-image: url('../../../../shared/assets/landingBackground.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.container > div > h1 {
  color: var(--white);
  font-size: var(--display-2xl);
  font-weight: var(--font-weight-bold);
  letter-spacing: -1.2px;
  margin-bottom: 3rem;
  text-align: center;
}

.container > div > p {
  color: var(--white);
  font-size: var(--font-size-lg);
  line-height: var(--line-height-15);
  margin-inline: auto;
  text-align: center;
}

.textWrapper {
  margin: 0 auto 90px;
  max-width: 1040px;
}

.textWrapper > p {
  margin-inline: 9rem;
}

.actionsWrapper {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  width: 100%;
}

.button {
  background-color: var(--white);
  border-radius: 0.5rem;
  color: var(--grey800);
  padding: 0.75rem 2rem;
}

.button:hover {
  background-color: var(--primary50);
  color: var(--primary500);
}

.actionsWrapper > p {
  color: var(--white);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.imageWrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto 90px;
  align-items: center;
}

.imageWrapper > svg {
  width: 75vw;
}
@media screen and (max-width: 1440px) {
  .background {
    background-size: cover;
  }
}
@media screen and (max-width: 1024px) {
  .container > div > h1 {
    font-size: var(--display-xl);
    line-height: var(--line-height-12);
    padding-inline: 2.5rem;
  }

  .container > div > p {
    margin: 0 auto 3rem;
    padding-inline: 130px;
  }

  .imageWrapper {
    margin: 100px auto;
    margin-top: 100px;
    max-width: 85vw;
    margin-inline: 100px;
    overflow: hidden;
  }

  .imageWrapper > svg {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .container > div > h1 {
    font-size: var(--display-xs);
  }

  .container > div > p {
    font-size: var(--font-size-md-lg);
    padding-inline: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .container {
    margin-top: 90px;
  }

  .container > div > h1 {
    font-size: var(--font-size-lg);
    letter-spacing: initial;
    line-height: var(--line-height-15);
    margin-bottom: 1rem;
    padding-inline: 0;
    white-space: break-spaces;
  }

  .container > div > p {
    font-size: var(--font-size-md);
    padding-inline: 0;
  }

  .actionsWrapper {
    flex-direction: column;
  }

  .actionsWrapper > p {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
    letter-spacing: initial;
    margin-block: 0;
  }

  .button {
    width: 70%;
  }

  .imageWrapper {
    margin: 0;
    margin-top: 3rem;
    width: auto;
    max-width: none;
    overflow: hidden;
  }

  .imageWrapper > svg {
    width: 100%;
  }

  .textWrapper {
    margin-bottom: 0;
    margin-inline: 1rem;
  }
}
