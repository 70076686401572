.wrapper > h3,
.wrapper > p {
  margin: 0;
}

.icon {
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
}

.title {
  margin: 0;
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-bold);
}

.description {
  margin: 0;
  color: #1211278f;
  font-size: var(--font-size-regular);
  line-height: var(--line-height-15);
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
  }

  .title {
    margin: 1rem 0 1.5rem;
  }

  .description {
    font-size: var(--font-size-sm);
  }
}
