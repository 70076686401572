.title {
  color: var(--grey900);
  font-size: 20px;
  font-weight: 500;
  line-height: var(--line-height-15);
}

.schedule {
  color: var(--grey600);
  font-size: 16px;
  font-weight: 400;
  line-height: var(--line-height-15);
  margin-bottom: 12px;
}
