.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  background-color: transparent;
  position: relative;
  z-index: 5;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 480px;
  max-width: 480px;
  background-color: var(--white);
  border: 1px solid var(--grey200);
  border-radius: 20px;
  box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05), 0px 4px 16px 0px rgba(47, 47, 141, 0.09);
  box-sizing: border-box;
  padding: 32px;
}

.loading {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
  z-index: 3;
}

.input {
  margin: 0;
  padding: 0;
}

.checkIcon {
  width: 16px;
  height: 16px;
  bottom: 30px;
  color: var(--white);
  left: 90%;
  position: relative;
  stroke-width: 0.3;
  z-index: 2;
}

.showCheck {
  color: var(--green700);
}

.text {
  width: 100%;
  color: var(--grey900);
  font-size: var(--display-xs);
  font-weight: var(--font-weight-semi);
  margin-bottom: 30px;
  text-align: center;
}

.showPassword {
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  align-self: flex-start;
  color: var(--grey700);
  cursor: auto;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
}

.checkboxStyle {
  width: 20px;
  height: 20px;
  border: 1px solid var(--secondary500);
  cursor: pointer;
}

.checkIconStyle {
  transform: translate(10%, 15%);
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 48px;
  width: 100%;
}

.button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 12px 24px;
  stroke: var(--white);
}

.button:disabled {
  cursor: auto;
  opacity: 0.8;
}

.button:disabled:active {
  filter: none;
}

.backButton {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--black);
  padding: 12px 24px;
  stroke: var(--black);
}

.backButton:hover {
  background-color: var(--grey100);
  border: 1px solid var(--grey500);
}

.error {
  color: var(--red500);
  stroke: var(--red500);
}

.success,
.success>* {
  color: var(--grey700);
  stroke: var(--grey700);
  font-weight: var(--font-size-regular);
}

@media screen and (max-width: 450px) {
  .checkIcon {
    left: 160px;
  }
}

@media screen and (max-width: 450px) {
  .text {
    font-size: var(--font-size-xl);
  }

  .form {
    min-height: calc(90vh - 80px);
    border: none;
    box-shadow: none;
    padding: 16px;
  }

  .wrapper {
    align-items: flex-start;
    min-height: calc(100vh - 80px);
    background-color: white;
  }

  .checkIcon {
    left: 150px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-end;
    margin-top: auto;
    width: 100%;
  }
}