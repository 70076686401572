.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: calc(100vw - 280px);
  height: 100%;
  box-sizing: border-box;
  padding: 2rem 1.5rem;
}
