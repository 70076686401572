.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 375px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
}

.dayName {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  margin-bottom: 8px;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.checkbox {
  display: flex;
  gap: 8px;
  color: var(--grey);
  cursor: auto;
  font-size: var(--font-size-sm);
}

.checkboxStyle {
  border: 1px solid var(--secondary500);
}

.pointerEventsOn {
  cursor: pointer;
  pointer-events: auto;
}

.name {
  color: var(--grey900);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

.description {
  color: var(--grey600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
}

.dayWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.openTypesWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
