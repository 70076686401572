.title {
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 14px;
}

.horizontalLine {
    width: 100%;
    height: 1px;
    background-color: var(--grey300);
    margin-bottom: 16px;
}

.asyncSelectStyle {
    z-index: 1;
}


.overlay {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #d3d3d383;
  inset: 0;
  position: absolute;
  z-index: 22;
}

@media screen and (max-width: 450px) {
  .wrapper {
    padding-inline: 16px;
  }
}