.aboutReportsRow {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}

.subHeader {
  color: var(--grey700);
  font-weight: var(--font-weight-regular);
  margin-bottom: 24px;
}

.aboutReportPill {
  background-color: var(--primary50);
  border-radius: 2rem;
  color: var(--primary800);
  font-size: var(--font-size-xs);
  padding: 0.25rem 0.75rem;
}
