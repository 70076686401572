.actionBtn {
  width: fit-content;
  background-color: transparent;
  border: 1px solid var(--adminOrange600);
  border-radius: 0.5rem;
  color: var(--adminOrange600);
  cursor: pointer;
  padding: 0.5rem 1.5rem;
}

.actionBtn:hover {
  background-color: transparent;
  border-color: var(--adminOrange700);
  color: var(--adminOrange700);
}

.managerActions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-right: 1rem;
}

.addSubBtn {
  width: fit-content;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.addSubBtn:hover {
  background-color: var(--adminOrange700);
}
