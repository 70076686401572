.star {
  fill: var(--grey300);
}

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
}

.star.filled {
  fill: var(--yellow500);
}