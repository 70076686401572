.categoryBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  max-width: 100%;
  border: 1px solid var(--primary300);
  border-radius: 12px;
}

.categoryBox:last-child {
  margin-bottom: 20px;
}

.categoryType {
  color: var(--grey700);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
}

.categoryName {
  color: var(--grey900);
  font-size: var(--font-weight-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--primary300);
  padding: 16px 24px;
}

.addServiceButton {
  display: flex;
  gap: 4px;
  align-items: center;
  width: fit-content;
  background-color: var(--white);
  border: 1px solid var(--primary500);
  color: var(--primary500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  justify-self: flex-end;
  line-height: var(--line-height-16);
  padding: 6px 16px;
  stroke: var(--primary500);
}

.addServiceButton:hover {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
  filter: none;
}

.addServiceButton:disabled {
  background-color: var(--primary50);
  border: 1px solid var(--primary300);
  opacity: 0.6;
}

.addServiceButton:disabled:hover {
  cursor: auto;
  filter: none;
}

.serviceName {
  flex: 300;
  font-size: var(--font-size-sm);
}

.readOnlyIcon {
  cursor: auto;
}

.servicePrice {
  flex: 250;
  font-size: 14px;
}

.trashIcon {
  background-color: var(--grey100);
  border: 0.5px solid var(--grey300);
  border-radius: 50%;
  cursor: pointer;
  padding: 6px;
  stroke: var(--grey900);
  stroke-width: 0.6;
}

.categoryActionsBox {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.serviceHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  color: var(--grey600);
  font-size: 12px;
  font-weight: 400;
}

.serviceRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.customServicesHeader {
  color: var(--grey600);
  font-size: 14px;
  line-height: var(--line-height-16);
  margin-bottom: 16px;
}

.horizontalLine {
  margin: 16px 0px;
  width: 100%;
  height: 1px;
  background-color: var(--grey200);
}

.separation {
  margin-top: 16px;
}

.editBtn {
  background-color: transparent;
  color: var(--primary500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 5px 16px;
  padding-right: 0px;
}

.editBtn:hover {
  background-color: transparent;
}

.addFirstService {
  display: flex;
  color: var(--grey700);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

.body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.tooltipStyle {
  width: 200px;
  background-color: var(--white);
  border-radius: 6px;
  bottom: 20px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  z-index: 20;
}

@media screen and (max-width: 500px) {
  .body {
    padding-left: 16px;
    padding-right: 16px;
  }

  .servicePrice {
    text-align: right;
  }

  .header {
    padding: 16px;
  }
}
@media screen and (max-width: 768px) {
  .categoryBox {
    padding: 16px;
  }

  .categoryName {
    font-size: var(--font-size-sm);
  }

  .categoryType {
    font-size: var(--font-size-xs);
  }

  .header {
    padding: 16px;
  }

  .addServiceButton {
    display: flex;
    gap: 4px;
    align-items: center;
    width: fit-content;
    background-color: var(--white);
    border: 1px solid var(--primary500);
    color: var(--primary500);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    justify-self: flex-end;
    line-height: var(--line-height-16);
    padding: 6px 16px;
    stroke: var(--primary500);
  }

  .addServiceButton:hover {
    background-color: var(--primary50);
    border: 1px solid var(--primary600);
    filter: none;
  }

  .addServiceButton:disabled {
    background-color: var(--primary50);
    border: 1px solid var(--primary300);
    opacity: 0.6;
  }

  .addServiceButton:disabled:hover {
    cursor: auto;
    filter: none;
  }

  .servicePrice {
    font-size: 14px;
    text-align: right;
  }

  .trashIcon {
    background-color: var(--grey100);
    border: 0.5px solid var(--grey300);
    border-radius: 50%;
    cursor: pointer;
    padding: 6px;
    stroke: var(--grey900);
    stroke-width: 0.6;
  }

  .categoryActionsBox {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }

  .serviceHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    color: var(--grey600);
    font-size: 12px;
    font-weight: 400;
  }

  .serviceRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;


  }
}