.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 675px;
  height: fit-content;
  max-height: calc(100vh - 64px);
}

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  overflow: hidden;
  position: fixed;
  z-index: 10;
}

.dayWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
}

.dayName {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  margin-bottom: 8px;
}

.form {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
}

.pointerEventsOn {
  cursor: pointer;
  pointer-events: auto;
}

.actionWrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
  justify-content: flex-start;
  margin-top: 24px;
  align-items: center;
  width: 100%;
}

.lightGray {
  color: var(--grey500);
}
@media screen and (max-width: 768px) {
  .content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
}
@media screen and (max-width: 450px) {
  .actionWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
}
