.expandableSection {
  width: 100%;
  padding: 0;
}

.sectionTitle {
  display: flex;
  gap: 8px;
  align-items: center;
  width: calc(100% - 32px);
  border-radius: 16px;
  color: var(--grey900);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.expandedSectionName {
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sectionName {
  display: flex;
  gap: 8px;
  align-items: center;
  height: min-content;
  user-select: none;
}

.expandIconWrapper {
  transform: translateY(5px);
  transition: transform 0.25 linear;
}

.expandIcon {
  width: var(--icon-small);
  color: var(--grey600);
  transform: rotate(0deg);
  transition: transform 0.25s linear;
}

.reversed {
  margin-top: 0;
  transform: rotateX(180deg);
}

.shortBox {
  padding: 0;
}
