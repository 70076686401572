.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--grey100);
  padding: 24px 0 16px 40px;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
}

.tabs {
  display: flex;
  gap: 24px;
  width: calc(100% - 5rem);
  overflow: hidden;
  overflow-x: auto;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tabBtn {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  box-sizing: border-box;
  color: var(--grey600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  text-wrap: nowrap;
}

.activeTabStyle,
.tabBtn:hover {
  background-color: transparent;
  border-bottom: 2px solid var(--primary500);
  color: var(--primary500);
}

.tabBtn:first-child {
  margin-left: 0;
  padding-left: 0;
}
@media screen and (max-width: 1024px) {
  .wrapper {
    gap: 8px;
    padding: 14px 16px;
  }

  .tabs {
    width: calc(100vw - 32px);
    overflow: hidden;
    overflow-x: scroll;
  }

  .tabs::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .wrapper {
    gap: 8px;
    padding: 14px 16px;
  }

  .tabs {
    gap: 16px;
  }

  .title {
    font-size: var(--font-size-lg);
    line-height: var(--line-height-16);
  }
}
