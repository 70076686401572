.filterWrapper {
  margin: 20px auto;
  background-color: var(--grey50);
  border-radius: 10px;
  padding: 16px;
}

.filterWrapper:first-child {
  margin-top: 0px;
}

.filterName {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--grey900);
  font-size: var(--font-size-lg);
  font-weight: 500;
  margin-bottom: 12px;
}

.filterDescription {
  color: var(--grey700);
  line-height: var(--line-height-15);
}

.circleInfo {
  width: 18px;
  height: 18px;
  fill: var(--grey600);
}

.filterModalWrapper {
  margin-top: 24px;
}
@media screen and (max-width: 450px) {
  .filterName {
    color: var(--grey800);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-15);
  }

  .filterDescription {
    color: var(--grey700);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-15);
  }
}
