.opinionText {
  max-width: 700px;
  padding-left: 74px;
}

.opinionReply {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  align-items: center;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
}

.image {
  width: 3em;
  min-width: 3rem;
  height: 3em;
  min-height: 3rem;
  border-radius: 50%;
  color: var(--grey400);
  object-fit: cover;
}

.nativeReplyActions {
  display: flex;
  gap: 32px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.replyAction {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  color: var(--grey600);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
}

.replyAction:hover {
  color: var(--grey800);
}

@media screen and (max-width: 1024px) {
  .opinionText {
    max-width: none;
    box-sizing: border-box;
    padding-left: 0;
    word-wrap: break-word;
  }
}