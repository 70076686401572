.wrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  width: 100%;
  height: 72px;
  max-height: 72px;
  min-height: 72px;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey200);
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;
  position: relative;
}

.notificationIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  min-width: 38px;
  height: 38px;
  min-height: 38px;
  background-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--black);
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.notificationIconWrapper:hover .icon {
  background-color: transparent;
  color: var(--primary500);
  transition: all 0.3s ease-in-out;
}

.address {
  margin: 0;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  padding: 0;
}

.icon {
  width: 18px;
  height: 18px;
  color: var(--black);
}

.logoutWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: transparent;
  color: var(--grey800);
  cursor: pointer;
  font-weight: var(--font-weight-regular);
}

.logoutWrapper:hover {
  background-color: transparent;
  color: var(--grey900);
}

.searchInput {
  border: 1px solid var(--grey300);
  border-radius: 42px;
  flex-basis: 30%;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: 0;
  padding-inline: 1rem;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 1;
}

.seoCardsBtn {
  gap: 0.5rem;
  background-color: transparent;
  border: 1px solid var(--primary500);
  color: var(--primary500);
  padding: 0.5rem 1.5rem;
}

.seoCardsBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.element {
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
}

.accountDetails {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.name {
  width: 50%;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: nowrap;
  word-break: keep-all;
}

.userPicture {
  width: 40px;
  height: 40px;
  background: lightgray 50% / cover no-repeat;
  border-radius: 50%;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.accountName {
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail {
  color: var(--grey600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chevron {
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  color: var(--grey800);
  transform: translateY(0.25rem);
}

.subMenu {
  width: 230px;
  background-color: var(--white);
  border: 1px solid var(--primary400);
  border-radius: 0.5rem;
  overflow: hidden;
  position: absolute;
  right: 1rem;
  top: 95%;
  z-index: 1;
}

.notificationBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 1rem;
  height: 1rem;
  background-color: var(--secondary500);
  border-radius: 25%;
  bottom: 60%;
  box-sizing: border-box;
  color: var(--white);
  font-size: 10px;
  font-weight: var(--font-weight-semi);
  left: 70%;
  padding-inline: 0.25rem;
  position: absolute;
  right: 0;
}

.notificationFeed {
  width: 20rem;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 30px;
}
@media screen and (max-width: 1024px) {
  .mobileDropDown {
    display: none;
  }

  .wrapper {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .mobileDropDown {
    display: none;
  }
}
