.wrapper {
  max-width: fit-content;
  background-color: var(--white);
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  -moz-box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  box-sizing: border-box;
  padding: 32px;
  position: fixed;
  top: 10%;
  z-index: 2;
}

.wrapper>* {
  user-select: none;
}

.selectPeriodWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.selectedPeriod>* {
  text-wrap: nowrap;
}

.periodPill {
  background-color: var(--grey50);
  border: 1px solid var(--grey200);
  border-radius: 2rem;
  color: var(--grey900);
  cursor: pointer;
  font-size: var(--font-size-sm);
  padding: 6px 14px;
  user-select: none;
}

.periodPill:hover {
  border-color: var(--grey800);
}

.monthsWrapper {
  display: grid;
  gap: 24px;
  border-bottom: 1px solid var(--grey300);
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  padding-bottom: 1.5rem;
}

.singleMonthWrapper {
  grid-template-columns: 1fr;
}

.verticalLine {
  width: 1px;
  height: 100%;
  background-color: var(--grey300);
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

.selectedPeriod {
  background-color: var(--grey200);
  border-color: var(--grey800);
  color: var(--grey900);
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.btn {
  border-radius: 2rem;
  cursor: pointer;
  padding: 6px 24px;
}

.btn:first-of-type {
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
  color: var(--grey900);
  font-size: var(--font-size-sm);
}

.btn:first-of-type:hover {
  background-color: var(--grey100);
  border-color: var(--grey400);
}

.adminBtn {
  border-radius: 0.5rem;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-semi);
  padding: 0.5rem 1.5rem;
}

.adminBtn:first-of-type {
  background-color: var(--white);
  border-color: var(--adminOrange500);
  color: var(--adminOrange500);
}

.adminBtn:first-of-type:hover {
  background-color: transparent;
  border-color: var(--adminOrange700);
  color: var(--adminOrange700);
}

.adminBtn:nth-last-of-type(1) {
  background-color: var(--adminOrange500);
  color: var(--white);
}

.adminBtn:nth-last-of-type(1):hover {
  background-color: var(--adminOrange600);
  color: var(--white);
}

.adminPeriodPill {
  background-color: var(--white);
  border-color: var(--adminGreyNormalHover);
  border-radius: 0.5rem;
  text-wrap: nowrap;
}

.adminPeriodPill.selectedPeriod {
  background-color: var(--adminGreyNormal);
  border-color: var(--adminGreyNormalHover);
}

.year {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  font-weight: var(--font-weight-semi);
}

.arrowIconWrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.25rem;
}

.arrowIcon {
  display: flex;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  transform: rotate(180deg);
}

@media screen and (max-width: 1024px) {
  .wrapper {
    left: auto;
    right: auto;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-width: calc(100vw - 2rem);
    min-width: calc(100vw - 2rem);
    left: 1rem;
    right: 1rem;
  }

  .monthsWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .monthsWrapper>* {
    width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: 100vw;
    background-color: tras;
    box-shadow: 0px 5px 20px 0px #7F7A7A1A;
    inset: auto;
    margin-block: 1rem;
    padding: .25rem;
    position: static;
  }

  .wrapper,
  .monthsWrapper {
    display: flex;
    flex-direction: column;
  }

  .periodPill {
    font-size: var(--font-size-xs);
    padding: 4px 12px;
  }

  .actions>* {
    width: 100%;
  }
}