.sectionTitle {
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.sectionDataWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-bottom: 1px solid var(--grey300);
  padding-block: 1.5rem;
}

.dataTitle {
  color: var(--adminGreyDarker);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.dataValue {
  color: var(--black);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.pill {
  margin-top: 0.75rem;
  background-color: var(--adminGreyNormalActive);
  border: none;
  border-radius: 20px;
  color: var(--black);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 6px 12px;
}

.activeStatusPill {
  background-color: var(--adminOrange400);
}

.userChangeHistoryDataWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border-bottom: 1px solid var(--adminGreyNormalHover);
  padding-block: 1.5rem;
}

.dataValueComment {
  max-width: 100%;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  white-space: pre-wrap;
  word-break: break-word;
}

.showMoreReasonBtn {
  width: fit-content;
  background-color: transparent;
  border: 1px solid var(--adminOrange500);
  border-radius: 0.5rem;
  color: var(--adminOrange500);
}

.showMoreReasonBtn:hover {
  background-color: transparent;
  color: var(--adminOrange700);
}

.loader {
  height: auto;
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}

.managerSubscription {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.actionBtn {
  width: fit-content;
  background-color: transparent;
  border: 1px solid var(--adminOrange600);
  border-radius: 0.5rem;
  color: var(--adminOrange600);
  cursor: pointer;
  padding: 0.5rem 1.5rem;
}

.actionBtn:hover {
  background-color: transparent;
  border-color: var(--adminOrange700);
  color: var(--adminOrange700);
}
