.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.sectionDataWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-bottom: 1px solid var(--adminGreyNormalHover);
  margin-bottom: 1.5rem;
  padding-block: 1.5rem;
}

.actionBtn {
  background-color: transparent;
  border: 1px solid var(--adminOrange600);
  border-radius: 0.5rem;
  color: var(--adminOrange600);
  cursor: pointer;
  padding: 0.5rem 1.5rem;
}

.actionBtn:hover {
  background-color: transparent;
  border-color: var(--adminOrange700);
  color: var(--adminOrange700);
}

.showPaymentDataBtn {
  flex-direction: row-reverse;
  gap: 0.5rem;
  width: fit-content;
  background-color: transparent;
  color: var(--grey900);
  font-family: var(--manrope);
  font-weight: var(--font-weight-semi);
  padding-left: 0;
}

.arrowIcon {
  transform: rotate(180deg);
}

.showPaymentDataBtn:hover {
  background-color: transparent;
  color: var(--adminOrange600);
}