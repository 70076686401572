.container {
  border: 1px solid var(--primary300);
  border-radius: 6px;
  padding: 12px;
}

.cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
}

.reportCard {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
  cursor: pointer;
  padding-block: 12px;
  transition: all 0.2s ease-in-out;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  position: absolute;
}
