#expandedUserRowWrapper {
  display: grid;
  width: 100%;
  border: 1px solid var(--adminGreyNormal);
  box-sizing: border-box;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;
  padding-inline: 5%;
}

.expandedUserRowWrapper .cell {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  width: fit-content;
}

.expandedUserRowWrapper .label {
  max-width: 95%;
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.expandedUserRowWrapper .value {
  color: var(--black);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.expandedUserRowWrapper .pill {
  background-color: var(--adminGreyNormalActive);
  border: none;
  border-radius: 20px;
  color: var(--black);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 6px 12px;
}

.expandedUserRowWrapper .activeStatusPill {
  background-color: var(--adminOrange400);
}

.addSubBtn {
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.addSubBtn:hover {
  background-color: var(--adminOrange700);
}

.actionBtn {
  background-color: transparent;
  border: 1px solid var(--adminOrange600);

  border-radius: 0.5rem;
  color: var(--adminOrange600);
  cursor: pointer;
  padding: 0.5rem 1.5rem;
}

.actionBtn:hover {
  background-color: transparent;
  border-color: var(--adminOrange700);
  color: var(--adminOrange700);
}

.managerActions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-right: 1rem;
}
