.modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40vw;
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
}

.loaderWrapper {
  height: auto;
}

.customIconStyle {
  display: none;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}
