.couponCodeBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  align-items: center;
  width: 100%;
  background-color: var(--grey50);
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 14px 0;
}

.coupon {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: var(--grey700);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: var(--line-height-16);
}

.code {
  margin: 0 auto;
  margin-top: 8px;
  color: var(--grey900);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: var(--line-height-15);
}

.codeBoxSchedule {
  margin-top: 14px;
  color: var(--grey600);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-16);
  text-align: center;
}
