.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.outletWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 80px);
  overflow: hidden;
  overflow-y: auto;
}

.rightSide {
  height: 100vh;
  background: linear-gradient(
    90deg,
    #226ef9 0%,
    #226ef8 6.25%,
    #226df7 12.5%,
    #216cf4 18.75%,
    #216af0 25%,
    #2068ec 31.25%,
    #1f66e7 37.5%,
    #1e63e1 43.75%,
    #1d61db 50%,
    #1c5ed6 56.25%,
    #1b5bd0 62.5%,
    #1a59cb 68.75%,
    #1957c7 75%,
    #1955c3 81.25%,
    #1854c0 87.5%,
    #1853bf 93.75%,
    #1853be 100%
  );
  flex: 0.4;
  flex-shrink: 0;
}

.leftSide {
  height: 100vh;
  flex: 0.6;
}

.footer {
  display: none;
  width: 100%;
  bottom: 0;
  position: fixed;
}

.tabletFooter {
  display: none;
  width: 100%;
  bottom: 0;
  position: fixed;
}

@media screen and (max-width: 1024px) {
  .rightSide {
    display: none;
  }

  .wrapper {
    flex-direction: column;
    overflow: hidden;
    z-index: 1;
  }

  .footer {
    display: block;
  }

  .outletWrapper {
    position: relative;
    z-index: 1;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    left: 0;
  }

  .outletWrapper {
    justify-content: flex-start;
    height: auto;
    max-height: none;
  }
}
