.modalContent {
  display: flex;
  max-width: 50%;
  height: fit-content;
  max-height: 90vh;
  overflow-y: auto;
}

.label {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 4rem;
  border-bottom: 1px solid var(--adminGreyNormal);
  border-top: 1px solid var(--adminGreyNormal);
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding-block: 1.5rem;
}

.customCloseStyle {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
}

.customCloseStyle:hover {
  background-color: transparent;
}

.titleStyle {
  width: 100%;
  border-bottom: 1px solid var(--adminGreyNormal);
  padding-bottom: 1rem;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

.saveBtn {
  gap: 0.5rem;
  margin-top: 1.5rem;
  width: fit-content;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  margin-left: auto;
  padding: 0.5rem 1.5rem;
}

.saveBtn:hover {
  background-color: var(--adminOrange700);
}

.saveBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
