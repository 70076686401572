.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
}

.section {
  display: flex;
  flex-direction: column;
  background-color: var(--grey50);
  flex: 1;
  overflow: hidden;
}

.rwdBox {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  background-color: transparent;
  left: 0;
  position: absolute;
  top: 0;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  .section {
    max-width: 100%;
    height: calc(100vh - 60px);
  }
}
@media screen and (max-width: 450px) {
  .wrapper {
    height: calc(100svh);
    overflow-y: auto;
  }
}
@media screen and (orientation: landscape) and (max-width: 768px) {
  .wrapper {
    height: calc(100vh - 10px);
    overflow-y: auto;
  }

  .rwdBox,
  .section {
    height: auto;
  }
}
