.overlay {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #d3d3d383;
  inset: 0;
  position: absolute;
  z-index: 2;
}

.loader {
  backdrop-filter: blur(3px);
  background-color: transparent;
  inset: 0;
  position: fixed;
}
