.businessProfileImage {
  width: 52px;
  max-width: 52px;
  height: 52px;
  max-height: 52px;
  background-color: var(--grey400);
  border-radius: 14px;
  color: var(--grey300);
  object-fit: cover;
  stroke: var(--grey400);
}

.image {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  cursor: pointer;
  fill: var(--grey400);
  object-fit: cover;
  stroke: var(--grey400);
}

.noImage {
  width: 44px;
  height: 44px;
  background-color: var(--grey100);
  box-sizing: border-box;
  color: var(--grey500);
  padding: 4px;
}

.businessDetailsWrapper {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.name {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.address {
  color: var(--grey600);
}

.subscriptionPill {
  margin-top: 2rem;
  background-color: var(--primary50);
  border: none;
  border-radius: 0.25rem;
  color: var(--primary500);
  font-size: var(--font-size-md);
  padding: 0.5rem 1rem;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}
