.selectKeywordsText {
  color: var(--grey600);
  font-size: 14px;
}

.keywordsWrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.keyword {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  height: fit-content;
  background-color: var(--white);
  border: 1px solid var(--secondary500);
  border-radius: 25px;
  color: var(--secondary500);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  transition: all 0.3s ease;
}

.selectedStyle {
  background-color: var(--secondary500);
  border: 1px solid var(--secondary500);
  color: var(--white);
  transition: all 0.3s ease;
}

.addKeyword {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  height: fit-content;
  background-color: var(--secondary500);
  border: 1px solid var(--secondary500);
  border-radius: 50%;
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  right: 24px;
}

.userTypedKeywordWrapper {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  align-items: center;
  width: 100%;
}

.addIcon {
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
  border-radius: 50%;
  color: var(--white);
  cursor: pointer;
  padding: 8px;
}

.addKeywordIcon {
  width: 24px;
  color: var(--secondary500);
  cursor: pointer;
}

.addKeywordIcon:hover {
  color: var(--secondary600);
}
