.container {
  font-family: var(--poppins);
  overflow: hidden;
  overflow-y: scroll;
}

.container:has(.empty) .container {
  overflow: hidden;
}

.container::-webkit-scrollbar {
  width: 8px;
}

.container::-webkit-scrollbar-thumb {
  background-color: var(--grey400);
  border-radius: 4px;
}

.container:has(.box) {
  pointer-events: none;
  user-select: none;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #eeeeee7a;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 100;
}

.body {
  display: flex;
  flex-direction: column;
  margin: 24px;
  min-height: calc(100vh - 170px);
  background-color: var(--white);
  border-radius: 14px;
  padding: 24px;
}

.body::-webkit-scrollbar {
  display: none;
}

.emptyWrapper {
  height: calc(100vh - 170px);
  overflow-y: hidden;
}

.pagination {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  margin: 24px auto 0 auto;
  margin-top: auto;
  align-items: center;
  width: 100%;
}

.adminPreview .body {
  margin-top: 0;
  padding: 0;
}

.adminPreview .emptyWrapper {
  min-height: 0;
}

@media screen and (max-width: 1024px) {
  .body {
    margin: 0;
    border-radius: 0px;
    overflow-y: scroll;
    padding: 16px;
  }

  .pagination {
    box-sizing: border-box;
    padding-bottom: 24px;
    padding-inline: 0;
  }
}

@media screen and (max-width: 450px) {
  .container {
    max-height: calc(100svh - 120px);
    background-color: var(--white);
    overflow: hidden;
    overflow-y: auto;
    padding-inline: 12px;
  }

  .body {
    margin: 0;
    max-height: calc(100vh - 120px);
    min-height: auto;
    border-radius: 0px;
    overflow-y: scroll;
    padding: 0px;
  }
}
