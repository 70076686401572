.title {
  display: flex;
  gap: 12px;
  align-items: center;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.pill {
  background-color: var(--secondary50);
  border: none;
  color: var(--secondary500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  padding: 4px 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid var(--primary200);
  box-sizing: border-box;
  margin-bottom: 16px;
  padding-bottom: 12px;
}

.addNewBtn {
  gap: 8px;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 24px;
}

@media screen and (max-width: 550px) {
  .header {
    align-items: flex-start;
  }

  .addNewBtn {
    width: 50%;
    max-height: 44px;
    padding: 6px 16px;
  }

  .title {
    width: 100%;
  }
}