.progressCircle {
  transform: rotate(-90deg);
}

.backgroundCircle {
  fill: none;
  stroke: var(--secondary50);
  stroke-width: 14px;
}

.progressArc {
  clip-path: url(#clipPath);
  fill: none;
  stroke: var(--secondary500);
  stroke-linecap: round;
  stroke-width: 6px;
  transition: stroke-dashoffset 0.3s ease-in-out;
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #eeeeee7a;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 100;
}

.progressText {
  color: var(--grey800);
  font-size: 18px;
  font-weight: 500;
  position: relative;
  top: 60px;
}
