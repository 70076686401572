.button {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--primary500);
  color: var(--primary500);
  padding: 8px 24px;
}

.button:hover {
  background-color: transparent;
  border-color: var(--primary600);
  color: var(--primary600);
}

.iconStyle {
  width: 20px;
  height: 20px;
  stroke: var(--primary500);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
