.answer {
  color: var(--grey900);
  font-size: 16px;
  font-weight: 400;
}

.actionIcon {
  width: var(--font-size-md);
  height: var(--font-size-md);
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.actionIcon:hover {
  transform: scale(1.15);
}

.actions {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 8px;
  stroke-width: 0.6px;
}

.useTemplateBtn {
  gap: 10px;
  margin-top: 12px;
  width: 100%;
  background-color: var(--primary500);
  border: 1px solid var(--primary500);
  color: var(--white);
  padding: 12px 24px;
  stroke: var(--white);
  stroke-width: 2px;
}

.useTemplateBtn:hover {
  background-color: var(--primary600);
}

.loader {
  margin: 20px auto;
}

.rowActionBox {
  display: flex;
  gap: 8px;
  align-items: center;
}

.actionDescription {
  display: none;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.showMoreBtn {
  display: none;
  background-color: transparent;
  color: var(--primary500);
  padding: 0;
}

.showMoreBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .answer {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .useTemplateBtn {
    padding: 10px 24px;
  }

  .actions {
    gap: 32px;
    flex-wrap: wrap;
    order: 2;
  }

  .actionDescription {
    display: block;
  }

  .answer.showFull {
    display: block;
    -webkit-line-clamp: unset;
    text-overflow: unset;
  }

  .showMoreBtn {
    display: block;
  }

  .template {
    width: 100%;
  }
}
