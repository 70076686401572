.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wrapper.noData {
  filter: grayscale(0.5);
}

.searchDataWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1350px) {
  .wrapper, .searchDataWrapper {
    display: flex;
    gap: 16px;
  }
}

@media screen and (max-width: 1290px) {
  .wrapper, .searchDataWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .wrapper, .searchDataWrapper {
    display: flex;
  }
}

@media screen and (max-width: 900px) {
  .wrapper, .searchDataWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 550px) {
  .wrapper, .searchDataWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
  }
}
