.wrapper {
  margin: 1.5rem;
  height: 100%;
  background-color: var(--white);
  border-radius: 0.75rem;
  font-family: var(--poppins);
  padding: 1.5rem;
}

.listWrapper {
  margin-top: 1.5rem;
  height: 100%;
  max-height: calc(100vh - 360px);
  box-sizing: border-box;
}

.icon {
  width: 16px;
  height: 16px;
}

.pagination {
  padding: 1rem;
}

.mobileList {
  display: none;
}

.adminPreview {
  margin: 0;
  padding-top: 0;
}

.adminPreview .listWrapper {
  max-height: calc(100vh - 460px);
}

.adminPreview .pagination {
  padding-bottom: 0;
}
@media screen and (max-width: 1024px) {
  .wrapper {
    margin: 0;
  }

  .listWrapper {
    max-height: calc(100vh - 320px);
  }
}

@media screen and (max-width: 768px) {
  .listWrapper {
    display: none;
  }

  .mobileList {
    display: block;
  }

  .wrapper {
    overflow-y: scroll;
  }

  .pagination {
    padding: 0;
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    height: 80svh;
    padding: 1rem;
  }
}
