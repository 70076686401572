.stepWrapper,
.titleSubInfo {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-12);
}

.stepTitle {
  margin: 0.25rem 0 0.75rem 0;
  color: var(--black);
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.modalHeader {
  margin-bottom: 2rem;
}
