.tabsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    position: absolute;
    top: 0;
    right: 0;
}

.tabsWrapper>* {
    background-color: transparent;
    border-bottom: 1px solid transparent;
    padding: 4px 12px;
    color: var(--secondary500);
    font-weight: var(--font-weight-medium);
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    cursor: pointer;
}

.active {
    background-color: var(--secondary500);
    color: var(--white);
}