.input {
  width: 100%;
  background-color: var(--white);
  border: none;
  border-radius: 8px;
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
  outline: none;
  padding-left: 8px;
}

.searchWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: auto;
  padding-inline: 0.5rem;
  position: relative;
}

.searchIcon {
  width: var(--icon-medium);
  height: var(--icon-medium);
  color: var(--grey800);
}

.input::placeholder {
  font-size: var(--font-size-sm);
  user-select: none;
}

.listWrapper {
  width: 100%;
  max-height: 40vh;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  z-index: 1;
}

.listWrapper::-webkit-scrollbar {
  display: none;
}

.listItem {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--grey300);
  color: var(--grey800);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  padding: 12px;
}

.listItem:hover {
  background-color: var(--primary50);
}

.loader {
  width: 30%;
  aspect-ratio: 1/1;
  margin-inline: auto;
}
