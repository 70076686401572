.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  background-color: transparent;
  position: relative;
  z-index: 5;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  width: 80%;
  background-color: transparent;
  box-sizing: border-box;
  padding: 1rem;
}

.header {
  margin: 0.75rem auto 1.5rem auto;
  color: var(--grey900);
  font-size: var(--display-sm);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
  text-align: center;
}

.backButton {
  margin-top: 16px;
  background-color: transparent;
  color: var(--primary500);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
}

.backButton:hover {
  background-color: transparent;
  color: var(--primary600);
}

.description {
  max-width: 476px;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
  margin-bottom: 40px;
  text-align: center;
}

.button {
  width: 230px;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding: 12px 24px;
}

.businessIcon {
  width: 122px;
  height: 122px;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    justify-content: center;
    align-items: center;
    height: calc(100svh - 80px);
    background-color: transparent;
    position: relative;
  }

  .form {
    width: 80%;
    background-color: var(--white);
  }
}
@media screen and (max-width: 768px) {
  .form {
    width: 80%;
  }
}
@media screen and (max-width: 450px) {
  .wrapper {
    justify-content: center;
    margin-top: 40px;
    height: auto;
    background-color: transparent;
    position: relative;
  }

  .header {
    font-size: var(--font-size-xl);
  }

  .description {
    width: 100%;
    font-size: var(--font-size-md);
    margin-bottom: 40px;
  }

  .button {
    width: 100%;
    font-size: var(--font-size-md);
  }

  .form {
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin-inline: 1rem;
  }

  .businessIcon {
    width: 80px;
    height: 80px;
  }
}
