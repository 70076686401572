.businessProfileInfo {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: calc(100vw - 32rem);
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
}

.listWrapper::-webkit-scrollbar {
  height: 8px;
}

.listWrapper::-webkit-scrollbar-thumb {
  background-color: var(--grey200);
}

.name {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
}

.address {
  color: var(--grey600);
  font-weight: var(--font-weight-regular);
  overflow: hidden;
  text-overflow: ellipsis;
}

.pill {
  border-radius: 1rem;
  box-sizing: border-box;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
  padding: 0.25rem 0.75rem;
}

.cell {
  color: var(--grey800);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.actionCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.completed {
  background-color: var(--green50);
  border-color: var(--green500);
  color: var(--green900);
}

.canceled,
.rejected {
  background-color: var(--red50);
  border-color: var(--red500);
  color: var(--red900);
}

.pending {
  background-color: var(--grey50);
  border-color: var(--grey600);
  color: var(--grey900);
}

.new {
  background-color: var(--secondary50);
  border-color: var(--secondary500);
  color: var(--secondary900);
}

.subscriptionName {
  text-transform: capitalize;
}

.list {
  width: calc(100vw - 32rem);
  max-height: calc(100vh - 400px);
  margin-bottom: 1rem;
}

.downloadWrapper {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  min-height: 2rem;
  border: 1px solid var(--primary500);
  border-radius: 50%;
  color: var(--primary500);
  margin-right: 0.5rem;
}

.paymentType {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.paymentType>div:nth-of-type(2) {
  color: var(--grey600);
  font-weight: var(--font-weight-regular);
}

.paidByIcon {
  width: 1rem;
  height: 1rem;
}

.card {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--primary500);
  border-radius: 50%;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-sizing: border-box;
  padding: 0.5rem;
}


@media screen and (max-width: 1440px) {
  .listWrapper,
  .header {
    width: calc(100vw - 32rem);
  }



  .list,
  .list>* {
    width: 100vw;
    text-overflow: initial;
  }


}

@media screen and (max-width: 1024px) {
  .listWrapper {
    width: calc(100vw - 5rem);
  }

}

@media screen and (max-width: 768px) {
  .listWrapper {
    width: 100%;
    margin-bottom: 1rem;
  }

  .list {
    width: 100vw;
  }

  .listWrapper::-webkit-scrollbar {
    display: none;
  }

}