.navItemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0 0 1rem;
  align-items: center;
  align-self: flex-start;
  box-sizing: border-box;
}

.navItem {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  border-left: 4px solid transparent;
  border-radius: 0.5rem;
  color: var(--white);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  padding: 12px;
  transition: background-color 0.3s;
}

.navItem:hover {
  color: var(--adminOrange600);
}

.navItem:hover .icon {
  color: var(--adminOrange600);
}

.selectedSection {
  background-color: #2a2a2ae5;
  border-left: 4px solid var(--adminOrange600);
  color: var(--adminOrange600);
}

.icon {
  width: var(--icon-medium);
  height: var(--icon-medium);
  color: var(--white);
}

.selectedSection .icon {
  color: var(--adminOrange600);
}
