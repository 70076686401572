.pill {
  background-color: var(--secondary50);
  border: 0;
  border-radius: 16px;
  color: var(--secondary500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 4px 12px;
  user-select: none;
}

.headerTitle {
  color: var(--grey800);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
}

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
 
}

.wrapper > * {
  user-select: none;
}
@media screen and (max-width: 450px) {
  .pill {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
    padding: 4px 12px;
    text-wrap: nowrap;
  }
}
