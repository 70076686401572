.content {
  max-width: 50vw;
}

.titleStyle {
  font-weight: var(--font-weight-semi);
  padding-bottom: 1rem;
}

.modalContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-bottom: 1px solid var(--adminGreyNormal);
  border-top: 1px solid var(--adminGreyNormal);
  padding-block: 1.5rem;
}

.customCloseStyle {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
}

.customCloseStyle:hover {
  background-color: transparent;
}

.inputName {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 4px;
}

.addAdminBtn {
  gap: 0.5rem;
  margin-top: 1rem;
  width: fit-content;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  margin-left: auto;
  padding: 0.5rem 1.5rem;
}

.addAdminBtn:hover {
  background-color: var(--adminOrange700);
}

.addAdminBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.loaderWrapper {
  height: auto;
  min-height: 12rem;
}
