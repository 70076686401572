.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: var(--grey900);
  font-size: var(--font-size-lg);
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardDate {
  color: var(--grey700);
}

.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.cardHeaderActions {
  display: flex;
  gap: 8px;
}

.actionIcon {
  width: var(--icon-small);
  height: var(--icon-small);
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 32px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06);
  cursor: pointer;
  padding: 8px;
  stroke-width: 1px;
}

.actionIcon:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}
@media screen and (max-width: 1200px) {
  .rowContainer {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
}

@media screen and (max-width: 1024px) {
  .rowContainer {
    flex-direction: row;
    gap: 8px;
  }
}
@media screen and (max-width: 768px) {
  .cardHeaderActions {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .cardHeader {
    box-sizing: border-box;
    padding-inline: 16px;
    padding-top: 16px;
  }
}
