.wrapper {
  display: flex;
  flex-direction: row;
  gap: 10%;
  justify-content: center;
  margin: 0 130px 130px;
  align-items: center;
  max-height: calc(100vh - 240px);
  box-sizing: border-box;
  margin-bottom: 32px;
}

.standAloneWrapper {
  margin-block: auto;
}

.leftSide,
.rightSide {
  min-height: 500px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  width: 80%;
  flex: 1;
}

.rightSide {
  display: flex;
  gap: 24px;
  width: 100%;
  aspect-ratio: 1/2;
  flex: 0.7;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
}

.subTitle {
  text-align: center;
}

.optionsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.goBackBtn {
  background-color: transparent;
  color: var(--primary500);
  padding-left: 0;
}

.goBackBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.mailWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.domain {
  display: flex;
  align-items: center;
  height: 58px;
  background-color: var(--grey100);
  border: 1px solid var(--grey400);
  border-radius: 8px;
  box-sizing: border-box;
  color: var(--grey700);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  padding-inline: 12px;
  transform: translateX(-16px);
}

.infoIcon {
  width: var(--icon-small);
  aspect-ratio: 1/1;
}

.helperText {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: var(--grey700);
  font-size: var(--font-size-xs);
  margin-block: 12px;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin-inline: 42px;
  }

  .optionsList {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .wrapper {
    margin: 0;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .rightSide {
    display: none;
  }

  .leftSide {
    margin: 0;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin: 0;
  }

  .leftSide,
  .rightSide {
    min-height: auto;
  }

  .optionsList {
    width: 100%;
  }
}
