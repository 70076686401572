.container {
  display: none;
  flex-direction: column;
  width: 100%;
  background-color: var(--secondary50);
  border-radius: 12px;
  box-sizing: border-box;
  margin-bottom: 32px;
  padding: 16px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--grey800);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.stepNumber {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}

.barStyle {
  background-color: var(--secondary500);
  border-radius: 8px;
}

.progressBar {
  margin-top: 24px;
  max-width: 100%;
  height: 8px;
  background-color: var(--secondary100);
  border-radius: 4px;
}

.progressCount {
  justify-content: flex-start;
  margin-top: 12px;
  color: var(--black);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.progressValue {
  position: relative;
}

@media screen and (max-width: 450px) {
  .container {
    display: flex;
  }
}
