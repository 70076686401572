.wrapper,
.body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.addBtn {
  display: flex;
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  background-color: var(--grey100);
  border: 1px solid var(--grey300);
  border-radius: 50%;
  color: var(--black);
  cursor: pointer;
  position: relative;
}

.addBtn:hover {
  background-color: var(--grey200);
}

.addBtn:read-only {
  background-color: var(--grey100);
}

.addIconStyle {
  width: var(--icon-xsmall);
  min-width: var(--icon-xsmall);
  height: var(--icon-xsmall);
  min-height: var(--icon-xsmall);
  transform: translateY(-35%);
}

.labelsList {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.pill {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--secondary500);
  border-radius: 32px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: 500;
  padding: 6px 16px;
}

.closeIcon {
  width: 14px;
  height: 14px;
  fill: var(--grey600);
}

.closeIcon:hover {
  fill: var(--grey800);
  filter: scale(1.2);
}

.activeIcon {
  cursor: pointer;
}

.tooltipStyle {
  width: 250px;
  background-color: var(--white);
  border-radius: 6px;
  bottom: 34px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  z-index: 1;
}

.loaderWrapper {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

.displayNone {
  display: none;
}
