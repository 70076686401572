:root {
  --poppins: 'Poppins', sans-serif;
  --manrope: 'Manrope', sans-serif;
  --font-size-xs: 0.75rem; /* 12px */
  --font-size-sm: 0.875rem; /* 14px */
  --font-size-md: 1rem; /* 16px */
  --font-size-md-lg: 1.125rem; /*18px*/
  --font-size-lg: 1.25rem; /* 20px */
  --font-size-xl: 1.5rem; /* 24px */

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi: 600;
  --font-weight-bold: 700;

  --display-xs: 1.8125rem; /* 29px */
  --display-sm: 2.125rem; /* 34px */
  --display-md: 2.5rem; /* 40px */
  --display-lg: 2.8125rem; /* 45px */
  --display-xl: 3.25rem; /* 52px */
  --display-2xl: 3.75rem; /* 60px */
  --display-3xl: 4.375rem; /* 70px */

  --line-height-12: 120%;
  --line-height-14: 140%;
  --line-height-15: 150%;
  --line-height-16: 160%;
}
