.navbarBackground {
  background-color: var(--primary500);
}

.wrapper {
  margin: 1.5rem;
  background-color: var(--grey100);
  border: 1px solid var(--grey300);
  border-radius: 0.5rem;
  padding: 1rem;
}

.wrapper > div > p,
.wrapper > div > ul > li {
  color: var(--grey700);
  margin-inline: 1.5rem;
}

.controllerData {
  color: var(--grey900);
  font-weight: var(--font-weight-semi);
}
@media screen and (max-width: 768px) {
  .wrapper {
    overflow-x: hidden;
  }

  .wrapper > div > ul {
    margin: 1rem;
    padding-inline-start: 0;
  }

  .wrapper > div > ul > li {
    margin: 0rem;
  }

  .wrapper > div > p {
    margin-inline: 0.25rem;
    padding: 0;
  }
}
