.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  width: 60%;
  border: 1px solid var(--grey200);
  border-radius: 1rem;
  box-shadow: 0px 8px 8px -4px #10182808;
  padding: 32px 24px;
}

.desactivateTrialWrapper {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  align-items: center;
}

.desactivateTrialWrapper p {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}

.recurringActionWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.aboutRecurringChange {
  display: flex;
  gap: 0.5rem;
  margin-top: 1.5rem;
  align-items: flex-start;
  color: var(--grey800);
  font-size: var(--font-size-sm);
}

.aboutRecurringChange > p {
  margin-block: 0;
}

.infoIcon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

@media screen and (max-width: 1440px) {
  .wrapper {
    width: auto;
  }

  .wrapper::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin-top: 1rem;
    padding: 1rem;
  }

  .desactivateTrialWrapper {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
}
