.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 130px 130px;
  align-items: flex-start;
  height: 100%;
  overflow: visible;
}

.wrapper:has(.svgImage) {
  gap: 10%;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.formTitle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.leftSide,
.rightSide {
  min-height: 500px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;
}

.rightSide {
  display: flex;
  gap: 24px;
  width: 100%;
  height: 100%;
  align-self: center;
  flex: 0.7;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  max-width: 70%;
  height: 100%;
  flex: 1;
}

.loaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  background-color: transparent;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 2;
}

.errMessage {
  max-width: 80%;
  color: var(--red500);
  font-size: var(--font-size-sm);
  margin-bottom: 12px;
}

.confirmBtn {
  background-color: transparent;
  color: var(--primary500);
}

.mapContainer {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1300px) {
  .title,
  .subtitle {
    max-width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin-top: 30px;
    align-items: flex-start;
    margin-inline: 42px;
  }

  .inputs,
  .errMessage {
    max-width: 100%;
  }

  .wrapper:has(.mapContainer) {
    gap: 24px;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    margin-inline: 16px;
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    flex-direction: column;
    margin: 0;
    margin-bottom: 60px;
  }

  .wrapper:has(.mapContainer) {
    margin: 60px 0 120px 0;
  }

  .rightSide:has(.svgImage) {
    display: none;
  }

  .leftSide {
    z-index: 1;
  }

  .inputs {
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .wrapper:has(.mapContainer) {
    margin-top: 0;
  }

  .leftSide,
  .rightSide {
    min-height: auto;
  }

  .leftSide {
    margin-left: 0px;
  }

  .title {
    font-size: var(--font-size-md);
  }

  .form {
    max-width: 100%;
  }

  .wrapper {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    align-items: flex-start;
    height: 100%;
  }

  .inputs {
    max-width: 100%;
    height: fit-content;
  }
}
