.wrapper {
  display: flex;
  height: 100vh;
  max-height: 100vh;
  box-sizing: border-box;
  font-family: var(--manrope);
  overflow: hidden;
}

.sideNavigator {
  display: flex;
  flex-direction: column;
  width: 280px;
  max-width: 280px;
  min-width: 280px;
  height: 100%;
  background-color: #000000e5;
  padding-left: 1rem;
}

.logoIcon {
  width: 164px;
  height: 30px;
  color: var(--white);
  cursor: pointer;
  padding-top: 1.5rem;
}

.logoutItem {
  display: flex;
  color: var(--white);
  margin-bottom: 3rem;
}

.mainSection {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 300px);
  height: 100vh;
  box-sizing: border-box;
}

.topbar {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;
}
