.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-left: 10px;
}

.textAreaWrapper {
  flex: 15;
}

.answerActions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 16px;
  align-items: center;
  width: 100%;
  max-width: fit-content;
  align-self: flex-end;
}

.textArea {
  margin: 0;
  height: 156px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 13px 32px 13px 14px;
  padding-right: 26px;
}

.textAreaStyle {
  color: var(--grey800);
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-16);
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
}

.button {
  width: 110px;
  height: 40px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 16px;
}

.button:disabled:active {
  cursor: auto;
  filter: none;
}

.cancel {
  background-color: var(--grey100);
  border: 1px solid var(--grey300);
  color: var(--grey900);
}

.cancel:hover {
  background-color: var(--grey100);
  border: 1px solid var(--grey500);
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 90%;
}

.charCounter {
  display: flex;
  justify-content: flex-end;
  bottom: 24px;
  color: var(--white);
  font-size: 12px;
  position: relative;
  right: 18px;
}

.showCounter {
  color: var(--grey600);
}

.image {
  margin-top: 12px;
  width: 48px;
  height: 48px;
  clip-path: circle(50% at 50% 50%);
  fill: var(--grey500);
  flex: 1;
  object-fit: cover;
}

.overlay {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #d3d3d383;
  inset: 0;
  position: absolute;
  z-index: 2;
}

.loader {
  z-index: 50;
}
@media screen and (max-width: 450px) {
  .image {
    width: 36px;
    height: 36px;
  }

  .textContainer {
    max-width: 100%;
  }
}
