.modalContent {
  width: 590px;
}

.modalTextArea {
  margin: 24px 0;
  height: 150px;
}

.actions {
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  width: 100%;
}

.modalButton {
  max-width: 160px;
  max-height: 44px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 24px;
}

.cancelButton {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
}

.cancelButton:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.button:disabled {
  cursor: auto;
  pointer-events: none;
  user-select: none;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  inset: 0;
  overflow: hidden;
  position: absolute;
  z-index: 4;
}

@media screen and (max-width: 768px) {
  .modalContent {
    width: 100%;
    height: 100%;
    align-content: space-between;
    overflow-y: hidden;
  }

  .modalTextArea {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 450px) {
  .actions {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    align-content: flex-end;
  }

  .modalButton {
    width: 100%;
    max-width: 100%;
  }
}
