.tableWrapper {
  height: 100%;
  max-height: calc(100vh - 300px);
  border: 1px solid var(--adminGreyNormal);
  border-radius: 0.5rem;
  overflow: hidden;
}

.tableWrapper::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

.tableBody {
  max-height: calc(100vh - 340px);
  overflow: hidden;
  overflow-y: auto;
}

.tableWrapper table {
  width: 100%;
  border-collapse: collapse;
}

.tableWrapper thead,
.tableWrapper tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableBody::-webkit-scrollbar {
  width: 4px;
}

table {
  border-collapse: collapse;
}