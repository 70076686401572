.wrapper {
  width: 100vw;
  height: 5.625rem;
  background-color: var(--black);
  box-sizing: border-box;
  opacity: 0.9;
  padding: 1rem 2rem;
  position: absolute;
  top: 0;
}

.sectionTitle {
  color: var(--grey900);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
}

.logo {
  width: 10rem;
  height: 1.875rem;
  color: var(--white);
}

.text {
  color: var(--white);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
}
