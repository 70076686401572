.opinionWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 32px;
    border-bottom: 1px solid var(--grey200);
    padding: 24px;
  }

  @media screen and (max-width: 1024px) {
    .opinionWrapper {
      margin-top: 0;
      border-bottom: none;
      padding-right: 32px;
    }
  }