.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: calc(100vh - 12rem);
  box-sizing: border-box;
  margin-block: 2rem;
  overflow-y: auto;
  padding-inline: 1.5rem;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.datePickerBtn {
  gap: 0.5rem;
  background-color: transparent;
  border: 1px solid var(--adminGreyNormalActive);
  border-radius: 0.5rem;
  color: var(--adminGreyDarkActive);
  padding: 10px 16px;
}

.datePickerBtn:hover {
  background-color: transparent;
  color: var(--adminGreyDarker);
}

.calendarIconStyle {
  stroke-width: 1.3px;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
  z-index: 1;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 1rem;
}
