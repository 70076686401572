.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 24px;
  border: 1px solid;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  padding: 0px 12px;
  text-overflow: ellipsis;
}

.wrapper > * {
  display: none;
  user-select: none;
}