.list {
  display: flex;
  flex-direction: column;
  height: calc(100% - 138px);
  background-color: var(--white);
  overflow-y: auto;
  row-gap: 1rem;
}


.card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey300);
  border-radius: 0.25rem;
  padding: 1rem;
  row-gap: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}

.left {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}

.title {
  margin: 0;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 0
}

.right {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}

.adress {
  margin: 0;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-size-regular);
  padding: 0
}

.body {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: flex-start;
}

.footer {
  color: var(--grey800);
  font-size: var(--font-size-sm);
}

.imageContainer {
  width: 44px;
  height: 44px;
  overflow: visible;
}

.image {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  object-fit: cover;
}

.checkbox {
  width: 1rem;
  height: 1rem;
  border-color: var(--secondary500);
  border-radius: 6px;
}

.checkboxStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
}

@media screen and (max-width: 450px) {
  .list {
    height: calc(100svh - 320px);
    margin-bottom: 1rem;
  }
}