.wrapper {
  display: flex;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 8px 12px -4px #1018280f;
}

.tab {
  border-bottom: 2px solid transparent;
  color: var(--adminGreyDarker);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding: 10px 24px;
  transition: all 0.3s;
}

.tab:hover {
  border-bottom: 2px solid var(--adminOrange600);
  transition: border-bottom 0.3s;
}

.active {
  border-bottom: 2px solid var(--adminOrange600);
  color: var(--adminOrange600);
  transition: all 0.3s;
}
