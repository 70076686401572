.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--adminGreyLightActive);
  border: 1px solid var(--adminGreyNormal);
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.rowContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.label {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.redirectNameInputWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: calc(100% / 2 - 18px);
}

.deleteBtn {
  width: fit-content;
  margin-left: auto;
}

.withDeleteAction {
  display: flex;
  justify-content: space-between;
  max-width: none;
}

.withDeleteAction .label {
  max-width: calc(100% / 2 - 18px);
}

.deleteIcon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.error {
  color: var(--red600);
  font-size: var(--font-size-xs);
}
