.arrowWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #dddddd;
  border-radius: 0.5em;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0.5rem;
}

.arrowWrapper:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.arrow {
  width: 1rem;
  height: 1rem;
}

.arrow.arrowLeft {
  transform: rotate(90deg);
}

.arrow.arrowRight {
  transform: rotate(270deg);
}
