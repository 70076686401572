.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1.5rem;
}

.pill {
  background-color: var(--adminOrange400);
  border: none;
  border-radius: 5rem;
  color: var(--black);
  font-size: var(--font-size-sm);
  padding: 6px 0.75rem;
}

.canceled {
  background-color: var(--adminRed400);
}

.pending {
  background-color: var(--adminGreyNormalActive);
}

.cell {
  color: var(--black);
  font-family: var(--manrope);
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: nowrap;
}

.listHeader {
  color: var(--black);
  font-weight: var(--font-weight-semi);
}

.list {
  height: calc(100vh - 320px);
}

.mangeBtn {
  background-color: transparent;
  border-color: var(--adminOrange300);
  border-radius: 0.5rem;
  color: var(--adminOrange300);
  margin-right: 0;
  padding-right: 0;
}

.mangeBtn:disabled {
  background-color: transparent;
  pointer-events: none;
}

.deleteBtn {
  background-color: transparent;
  color: var(--grey800);
  margin-left: auto;
  padding-inline: 0;
}

.deleteBtn:hover {
  background-color: transparent;
  color: var(--grey900);
}

.deleteIconStyle {
  width: 1.125rem;
  height: 1.125rem;
}

.overlay {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
}