.cardInformationHeading {
  margin: 1.5rem 0 1rem;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.cardInformationWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 50%;
}

.label {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 0.25rem;
}

.pill {
  margin-top: 4px;
  background-color: var(--adminOrange400);
  border: none;
  border-radius: 9999px;
  color: var(--black);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 6px 12px;
}

.nonActivePill {
  background-color: var(--adminGreyNormalHover);
}

.wrapper {
  width: 100%;
  border-bottom: 1px solid var(--adminGreyNormalHover);
  padding-bottom: 1.5rem;
}

.createdAt {
  margin-top: 8px;
  color: var(--black);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  align-items: center;
}

.actions > * {
  background-color: var(--transparent);
  border: 1px solid var(--adminRed500);
  border-radius: 0.5rem;
  color: var(--adminRed500);
  padding: 0.5rem 1.5rem;
}

.actions > *:hover {
  background-color: transparent;
  border-color: var(--adminRed600);
  color: var(--adminRed600);
}

.deleteCardBtn {
  border-color: var(--adminOrange600);
  color: var(--adminOrange600);
}

.deleteCardBtn:hover {
  background-color: transparent;
  color: var(--adminOrange700);
}

.deleteCardBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  border-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 1;
}
