.wrapper {
  display: flex;
  align-items: center;
}

.input {
  width: 330px;
  height: 44px;
  border-radius: 500px;
}

.calendarIconStyle {
  width: var(--icon-regular);
  height: var(--icon-regular);
  padding-right: 8px;
}

.calendarAdminPreview {
  position: fixed !important;
  right: 5rem !important;
  top: 4rem !important;
}

.filters {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  margin-left: 14px;
  padding: 10px 24px;
  text-wrap: nowrap;
}

.filters:hover {
  background-color: var(--grey100);
}

.iconStyle {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.newReportBtn {
  display: none;
  width: 100%;
  margin-left: auto;
  padding: 10px 24px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 700px) {
  .filters {
    padding: 8px 16px;
  }
}

@media screen and (max-width: 630px) {
  .filters {
    font-size: var(--font-size-sm);
    padding: 8px;
  }
}

@media screen and (max-width: 550px) {
  .filters {
    display: none;
  }

  .input {
    width: 100%;
  }

  .newReportBtn {
    display: block;
  }

  .wrapper {
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
}