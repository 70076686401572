.columnsRow {
  background: linear-gradient(0deg, #f5f5f5 0%, #ffffff 100%);
  border-bottom: 1px solid var(--adminGreyNormal);
  box-sizing: border-box;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semi);
  padding: 12px 16px;
  text-align: left;
  text-wrap: nowrap;
}

.clientRow {
  height: 44px;
  color: var(--grey700);
  font-weight: 400;
}

.chevronHeader {
  border-right: 1px solid var(--adminGreyNormal);
}

.sortHead {
  cursor: pointer;
}

.rowContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-shrink: 0;
}

.sortIcon {
  width: 12px;
  flex-shrink: 0;
}

.activeSortIcon {
  transform: rotate(180deg);
}

.chevronHeader,
.buttonCell {
  width: 60px;
  min-width: 60px;
  background: linear-gradient(0deg, #f5f5f5 0%, #ffffff 100%);
  left: 0;
  position: sticky;
  z-index: 1;
}

.buttonCell {
  background: linear-gradient(0deg, #f5f5f5 0%, #ffffff 100%);
  position: sticky;
  right: 0;
  z-index: 1;
}

.activeSort {
  color: var(--adminOrange600);
}

.clientActiveSort {
  color: var(--primary500);
}
