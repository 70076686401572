.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}

.sectionName {
  color: var(--grey900);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 24px;
}

.addButton {
  display: flex;
  gap: 8px;
  align-items: center;
  align-self: flex-end;
  border-radius: 32px;
  padding: 10px 24px;
  stroke: var(--white);
}

.actionBox {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 24px;
  align-items: center;
  width: 100%;
}

.showPreview {
  background-color: transparent;
  color: var(--primary500);
  font-size: 16px;
  font-weight: 500;
}

.showPreview:hover {
  background-color: transparent;
  color: var(--primary600);
}

.addButton:disabled {
  background-color: var(--primary300);
  cursor: auto;
}

.addButton:disabled:hover {
  filter: none;
}

.showPreview:disabled {
  background-color: var(--white);
  color: var(--primary400);
}

.showPreview:disabled:hover {
  background-color: none;
  cursor: auto;
}

.showPreview:disabled:active {
  filter: none;
}

.RWDShowPreview {
  display: none;
}

@media screen and (max-width: 450px) {
  .addButton {
    margin-top: 24px;
    width: 100%;
    padding: 12px 24px;
  }

  .actionBox {
    flex-direction: column;
    margin-top: auto;
    width: 100%;
    align-content: flex-end;
    align-self: flex-end;
  }

  .showPreview {
    display: none;
    width: 100%;
    padding: 12px 24px;
    text-align: center;
  }

  .RWDShowPreview {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    margin-top: 32px;
    background-color: transparent;
    color: var(--primary500);
    padding-left: 0;
  }

  .RWDShowPreview:hover {
    background-color: transparent;
    color: var(--primary600);
  }
}
