.loader {
  display: flex;
  justify-content: center;
  z-index: 2;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(238, 238, 238, 0.72);
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 5;
}

.text {
  color: var(--grey800);
  font-size: var(--display-xs);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
  margin-bottom: 32px;
}

@media screen and (max-width: 450px) {
  .text {
    font-size: var(--font-size-xl);
  }
}