.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--grey100);
    border-radius: 1rem;
    padding: 1rem;
    border: 1px solid var(--grey300);
    box-shadow: var(--shadowMD);
    gap: 1rem;
}

.title,
.wrapper>p {
    color: var(--grey900);
    margin: 0;
}

.link {
    text-decoration: none;
    color: var(--white);
    background-color: var(--primary500);
    padding: 10px 1.5rem;
    border-radius: 2rem;
    font-weight: var(--font-weight-medium);
}

.link:hover {
    background-color: var(--primary600);
}