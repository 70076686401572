.passwordHelper {
  display: flex;
  gap: 5px;
  align-items: center;
  align-self: flex-start;
  color: var(--red600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  margin-bottom: 24px;
}

.infoIcon {
  width: 1rem;
  height: 1rem;
  color: var(--red600);
  stroke: var(--red600);
}
