.opinions {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  box-sizing: border-box;
  flex: 2;
  flex-shrink: 0;
  padding: 24px;
}

.opinions.noData {
  background-color: var(--grey100);
  filter: grayscale(0.5);
  opacity: 0.7;
}

.title {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 32px;
}

.opinionList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.showOpinionsBtn {
  margin-top: auto;
  background-color: var(--white);
  color: var(--primary500);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  margin-left: auto;
  padding: 0;
}

.showOpinionsBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.emptyOpinionState {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 250px;
  color: var(--grey700);
}

.goToOpinionsBtn {
  background-color: transparent;
  color: var(--primary500);
}

.goToOpinionsBtn:hover {
  background-color: transparent;
}

.goToOpinionsBtn:disabled {
  background-color: transparent;
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .showOpinionsBtn {
    margin-top: 12px;
    margin-left: 0;
    margin-right: auto;
  }

  .opinions {
    padding: 16px;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    flex-direction: column;
  }

  .opinions {
    width: auto;
    flex: 1;
  }

  .showOpinionsBtn {
    margin-top: 12px;
    margin-left: 0;
    margin-right: auto;
  }

  .title {
    margin-bottom: 24px;
  }
}
