.wrapper {
  display: flex;
  flex-direction: column;
}

.rowContainer {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  padding: 1rem 1.5rem;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
}

.menu {
  display: none;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--grey200);
  box-sizing: border-box;
}

.image {
  display: none;
}

.businessDetails {
  overflow: hidden;
  padding: 1rem 0.75rem 0.75rem;
}

.name {
  max-width: 100%;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address {
  color: var(--grey500);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chevronIconTopBar {
  display: none;
}

.notificationIconWrapper,
.userIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  min-width: 38px;
  height: 38px;
  min-height: 38px;
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--grey900);
  cursor: pointer;
}

.notificationIconWrapper {
  position: relative;
}

.notificationFeed {
  width: auto;
  min-width: 20rem;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  right: 5%;
  top: 4rem;
}

.notificationBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 1rem;
  height: 1rem;
  background-color: var(--secondary500);
  border-radius: 25%;
  bottom: 60%;
  box-sizing: border-box;
  color: var(--white);
  font-size: 10px;
  font-weight: var(--font-weight-semi);
  left: 70%;
  padding-inline: 0.25rem;
  position: absolute;
  right: 0;
}

.notificationIconWrapper:hover {
  background-color: transparent;
  color: var(--primary500);
}

.icon {
  width: 18px;
  height: 18px;
}

.seoCardsBtn {
  gap: 0.5rem;
  background-color: transparent;
  border: 1px solid var(--primary500);
  color: var(--primary500);
  padding: 6px 16px;
  stroke: 2px;
}

.seoCardsBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.rightSide {
  display: flex;
  gap: 1rem;
}

.userIconWrapper {
  background-color: transparent;
  color: var(--grey900);
}

.userIcon {
  width: 18px;
  height: 18px;
}

.subscriptionPill {
  background-color: var(--primary50);
  border: none;
  border-radius: 0.25rem;
  color: var(--primary500);
  margin-bottom: 0.75rem;
  padding: 0.25rem 0.75rem;
}

@media screen and (max-width: 1024px) {
  .header {
    width: auto;
    background-color: var(--primary500);
    padding-inline: 1.5rem;
  }

  .header.navOpen {
    background-color: var(--grey100);
    color: var(--black);
  }

  .navOpen .info>*>*,
  .navOpen .chevronIconTopBar {
    color: var(--grey900);
  }

  .rowContainer {
    display: flex;
  }

  .menu {
    display: block;
    width: var(--icon-medium);
    min-width: var(--icon-medium);
    height: var(--icon-medium);
    min-height: var(--icon-medium);
    color: var(--white);
    cursor: pointer;
    padding-right: 1.5rem;
  }

  .close {
    color: var(--black);
  }

  .info {
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
    max-width: 90%;
    max-height: 72px;
    border-bottom: 0;
    color: var(--white);
    padding: 0;
    padding-right: 1rem;
  }

  .image {
    display: block;
    width: 42px;
    height: 42px;
    border-radius: 0.75rem;
    object-fit: cover;
  }

  .address {
    color: var(--white);
  }

  .chevronIconTopBar {
    display: flex;
    width: 20px;
    height: 20px;
    color: var(--white);
    cursor: pointer;
    margin-left: auto;
  }

  .notificationFeed {
    width: 40vw;
  }

  .subscriptionPill {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .header {
    display: flex;
    margin: 0 auto;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    color: var(--white);
    padding-inline: 16px;
  }

  .menu {
    display: block;
    margin-right: 24px;
    padding: 0;
  }

  .info {
    justify-content: flex-start;
    max-width: 60%;
    max-height: 64px;
    border-bottom: 0;
    color: var(--white);
    margin-right: 16px;
    overflow: hidden;
    padding: 0;
  }

  .image {
    display: none;
  }

  .name {
    overflow: hidden;
    word-break: break-all;
  }

  .chevronIconTopBar {
    display: flex;
    width: 24px;
    min-width: 24px;
    height: 20px;
    min-height: 20px;
    stroke: var(--white);
  }

  .invertedIcon {
    width: 20px;
    height: 20px;
    stroke: var(--white);
    transform: rotate(180deg) translateX(2px) translateY(4px);
  }

  .notificationFeed {
    min-height: calc(90svh);
  }
}

@media screen and (max-width: 450px) {
  .rowContainer {
    padding: 0.5rem 1rem;
  }

  .header {
    width: 100%;
    box-sizing: border-box;
  }

  .businessDetails {
    padding-left: 0;
  }

  .info {
    max-width: none;
    margin-right: auto;
  }

  .notificationFeed {
    width: auto;
    max-height: calc(100svh - 3rem);
    max-width: calc(100vw - 2rem);
    border-radius: 0;
    left: 0;
    right: 0;
    top: 3rem;
    height: 100vh;
  }
}