.header {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--grey800);
  font-weight: var(--font-weight-medium);
  margin-bottom: 40px;
}

.rowContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
}

.increase {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--green500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semi);
}

.negativeValue {
  color: var(--red500);
}

.arrowIcon {
  width: var(--icon-small);
  height: var(--icon-small);
  stroke-width: 2px;
  transform: rotate(90deg);
}

.arrowNegativeValue {
  transform: rotate(270deg);
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
  border-radius: 50%;
  cursor: pointer;
  padding: 4px;
  transition: all 0.2s ease-in-out;
}

.icon {
  width: var(--icon-regular);
  height: var(--icon-regular);
}

.iconWrapper:hover {
  transform: scale(1.1);
}

.icons {
  display: flex;
  gap: 8px;
  color: var(--grey600);
  margin-left: auto;
}

.selectedIcon {
  background-color: var(--primary50);
  border-color: var(--primary400);
  color: var(--primary500);
}

.tooltipStyle {
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px #1018281a;
  font-size: var(--font-size-xs) !important;
  left: -6rem;
  padding: 6px 8px;
  position: absolute;
  text-wrap: nowrap;
  z-index: 5;
}
@media screen and (max-width: 550px) {
  .icons {
    margin-left: 0;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }
}
