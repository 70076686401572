.businessProfilesList {
  display: none;
  height: calc(100svh - 118px);
  background-color: var(--white);
  box-sizing: border-box;
  overflow: scroll;
  padding: 16px;
}

.rowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.yourBusinessCardHeading {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
}

.createBusinessProfileBtn {
  background-color: var(--white);
  color: var(--primary500);
  font-size: var(--font-size-sm);
  padding: 0;
}

.createBusinessProfileBtn:hover {
  background-color: var(--white);
}

.horizontalLine {
  width: 100%;
  height: 1px;
  background-color: var(--grey400);
  margin-bottom: 12px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.businessProfileName {
  margin: 0;
  color: var(--white);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.businessProfileAddress {
  color: var(--white);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.input {
  width: 100%;
  height: 24px;
  background-color: var(--primary500);
  border: none;
  border-radius: 8px;
  color: var(--white);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
  outline: none;
  padding: 0;
  transition: height 0.3s;
}

.input::placeholder {
  color: var(--white);
  padding-left: 0;
}

.itemWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.subscriptionPill {
  background-color: var(--primary50);
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  color: var(--primary700);
  flex-shrink: 0;
  padding: 0.25rem 0.75rem;
  white-space: nowrap;
}

@media screen and (max-width: 1024px) {
  .businessProfilesList {
    display: block;
    background-color: var(--primary500);
  }
}
