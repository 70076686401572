.wrapper {
  width: 100%;
  height: calc(100vh - 90px);
  font-family: var(--poppins);
  overflow: hidden;
}

.wrapper:has(.empty) {
  overflow: hidden;
  user-select: none;
}

.body {
  display: flex;
  flex-direction: column;
  margin: 24px;
  height: calc(100vh - 210px);
  background-color: var(--white);
  border: 1px solid var(--grey200);
  border-radius: 14px;
  box-shadow: 0px 5px 20px rgba(127, 122, 122, 0.1);
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.adminPreview {
  margin: 0;
  height: calc(100vh - 260px);
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  padding: 0;
}

.adminPreview .pagination {
  margin-bottom: 1rem;
}

.body::-webkit-scrollbar {
  width: 8px;
}

.body::-webkit-scrollbar-thumb {
  background-color: var(--grey400);
}

.body:has(.empty) {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: var(--grey400);
}

.wrapper::-webkit-scrollbar {
  width: 8px;
}

.opinionList {
  margin: 0 9rem;
}

.adminPreview .opinionList {
  height: 100%;
  overflow-y: auto;
}

.adminPreview .opinionList::-webkit-scrollbar {
  display: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #eeeeeeaa;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 100;
}

.pagination {
  display: flex;
  justify-content: space-around;
  margin-top: auto;
  width: 80%;
  align-self: flex-end;
  margin-bottom: 1rem;
  padding-top: 12px;
}

.getMoreOpinionsBtn {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 24px;
}

.rwdFooterAction {
  display: none;
}
@media screen and (max-width: 1024px) {
  .body {
    margin: 0;
    height: calc(100% - 90px);
    border: none;
    border-radius: 0px;
    box-shadow: none;
  }

  .wrapper {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  .pagination {
    margin-bottom: 2rem;
  }

  .opinionList {
    height: 100%;
    margin-left: 80px;
    overflow: hidden;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 768px) {
  .body {
    height: calc(100% - 130px);
  }

  .opinionList {
    margin-left: 2rem;
  }
}
@media screen and (max-width: 500px) {
  .wrapper::-webkit-scrollbar {
    display: none;
  }

  .body:has(.empty) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    overflow: hidden;
  }

  .rwdGetMore {
    width: 100%;
    padding: 12px 24px;
  }

  .rwdFooterAction {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--white);
    bottom: 0;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    padding: 8px 16px 8px;
    position: fixed;
  }

  .body {
    margin: 0;
    height: auto;
    border: none;
    border-radius: 0px;
    box-shadow: none;
  }

  .pagination {
    margin: 0 0.5rem;
    width: 90%;
    padding-bottom: 1rem;
  }

  .opinionList {
    margin: 0 auto;
    max-height: auto;
  }

  .wrapper {
    height: calc(100svh - 200px);
  }
}
