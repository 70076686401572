.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 1;
}

.seoStatusIcon {
  width: 20px;
  height: 20px;
}

.pill {
  background-color: var(--adminGreyNormalActive);
  border: none;
  border-radius: 20px;
  color: var(--black);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 6px 12px;
}

.activeStatusPill {
  background-color: var(--adminOrange400);
}

.tableSeoCardList {
  overflow: auto;
}

.tableSeoCardList::-webkit-scrollbar:vertical {
  display: none;
}

.tableSeoCardList thead,
.tableSeoCardList tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableSeoCardList thead {
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableBody {
  width: 100%;
  height: calc(100vh - 400px);
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: calc(100vw - 340px);
  height: 100%;
  overflow: hidden;
}
