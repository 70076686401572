.wrapper {
  width: fit-content;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 32px;
  cursor: pointer;
  padding: 8px 20px;
  position: relative;
  user-select: none;
}

.list {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  width: 100%;
  max-height: 140px;
  background-color: white;
  border: 2px solid var(--grey300);
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  -moz-box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  left: 5px;
  overflow-y: auto;
  position: absolute;
  top: calc(100%);
  z-index: 1;
}

.list::-webkit-scrollbar-thumb {
  background-color: var(--grey400);
}

.list::-webkit-scrollbar {
  width: 4px;
}

.element {
  color: var(--grey900);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 10px;
  text-wrap: nowrap;
}

.element:hover {
  background-color: var(--grey100);
  color: var(--grey900);
}

.icon {
  display: flex;
  justify-content: center;
  margin-top: 3px;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease-in-out;
}

.invertedIcon {
  transform: rotate(180deg) translateY(0px) translateX(2px);
  transition: all 0.3s ease-in-out;
}

.selectContainer {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  color: var(--grey900);
  font-size: 14px;
  font-weight: 500;
  line-height: var(--line-height-16);
}
