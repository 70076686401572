.bgNavbar {
  background-color: var(--primary500);
}

.wrapper {
  margin: 1.5rem;
  background-color: var(--grey100);
  border: 1px solid var(--grey300);
  border-radius: 0.5rem;
  counter-reset: section;
  padding: 1rem;
}

.wrapper h3 {
  counter-increment: section;
  counter-reset: subsection;
}

.wrapper h3::before {
  content: counter(section) '. ';
  font-weight: bold;
}

.wrapper p {
  color: var(--grey800);
  counter-increment: subsection;
  margin-left: 1.5rem;
}

.wrapper li {
  color: var(--grey800);
  margin-left: 1.5rem;
}

.warpper b {
  color: var(--grey900);
}

.wrapper p::before {
  content: counter(section) '.' counter(subsection) ' ';
}

.noDecor {
  list-style: none;
  margin-left: 1.5rem;
  text-decoration: none;
}

.nestedPoint {
  margin-left: 4rem;
}
@media screen and (max-width: 768px) {
  .wrapper {
    overflow-x: hidden;
  }

  .wrapper ul {
    margin: 1rem;
    padding-inline-start: 0;
  }

  .wrapper li {
    margin: 0rem;
    overflow-wrap: break-word;
  }

  .wrapper p {
    line-break: auto;
    margin-inline: 0.25rem;
    overflow-wrap: break-word;
    padding: 0;
    text-wrap: wrap;
    white-space: pre-wrap;
  }
}

@media screen and (max-width: 475px) {
  .wrapper {
    margin: 0.5rem;
  }
}