input {
  font-family: 'Poppins', sans-serif;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
  cursor: pointer;
  filter: invert(29%) sepia(90%) saturate(1896%) hue-rotate(211deg) brightness(97%) contrast(101%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: black;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(29%) sepia(20%) saturate(12%) hue-rotate(111deg) brightness(97%) contrast(51%);
}

input[type='time']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(29%) sepia(90%) saturate(1896%) hue-rotate(211deg) brightness(97%) contrast(101%);
}

.input {
  width: calc(100% - 20px);
  max-width: 90%;
  height: 0px;
  background-color: var(--white);
  border: none;
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
  outline: none;
  overflow: hidden;
  padding-bottom: 0px;
  padding-left: 7px;
  padding-top: 0px;
  text-overflow: ellipsis;
  transition: height 0.3s;
}

.input.disabled {
  background-color: var(--grey50);
  color: var(--grey700);
}

.inputWrapper:hover {
  border-color: var(--primary300);
}

.input.focused {
  height: 25px;
  background-color: transparent;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  padding-left: 10px;
}

.wrapper.wrapper:has(.errorLabel) input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(53%) sepia(84%) saturate(4420%) hue-rotate(338deg) brightness(101%) contrast(100%);
}

.wrapper:has(.counter) .inputWrapper {
  padding-right: 52px;
  position: relative;
}

.errorLabel {
  display: flex;
  gap: 4px;
  margin-top: 4px;
  align-items: center;
  width: 100%;
  color: var(--red500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  text-wrap: nowrap;
}

.errorIcon {
  width: var(--icon-small);
  height: var(--icon-small);
}

.error > .text {
  color: var(--red500);
}

.label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: var(--grey700);
  font-size: var(--font-size-md);
  line-height: var(--line-height-16);
  transition: font-size 0.3s;
}

.label.search {
  padding-left: 24px;
}

.text {
  line-height: var(--line-height-15);
  margin-left: 10px;
}

.label.focused {
  font-size: var(--font-size-xs);
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: var(--white);
  border: 1px solid var(--grey300);
  border-radius: inherit;
  padding-left: 6px;
  padding-right: 16px;
}

.inputWrapper.disabled {
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
}

.inputWrapper.marginRight {
  padding-right: 42px;
}

.inputWrapper.focused {
  border: 1px solid var(--primary500);
}

.inputWrapper.focused.disabled {
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
}

.inputWrapper.error {
  border: 1px solid var(--red500);
}

.wrapper {
  width: 100%;
  height: 54px;
  border-radius: 8px;
  position: relative;
}

.wrapper:has(.errorLabel) {
  margin-bottom: 36px;
}

.counter {
  width: fit-content;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  position: absolute;
  right: 1rem;
  top: 50%;
}

.withCounter {
  padding-right: 16px;
}

.label.disabled {
  color: var(--grey700);
}

.admin {
  height: 40px;
}

.admin .input {
  color: var(--black);
  font-family: var(--manrope);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.admin.inputWrapper {
  border-color: var(--adminGreyNormalActive);
}

.admin.inputWrapper.focused {
  border-color: var(--adminGreyDarker);
}

.admin.inputWrapper.disabled {
  background-color: var(--adminGreyNormal);
  border-color: var(--adminGreyNormalHover);
}

.admin .input:disabled {
  color: var(--adminGreyDarkActive);
}

.wrapper:has(.admin.error) .counter {
  top: 31%;
}

.wrapper:has(.admin) {
  height: auto;
}

.admin:has(.errorLabel) .inputWrapper {
  border-color: var(--adminRed);
}

.wrapper:has(.admin).wrapper:has(.errorLabel) {
  margin-bottom: 0;
}

.customIcon {
  width: 1rem;
  left: 1rem;
  position: absolute;
  top: 35%;
}

.wrapper:has(.admin).wrapper:has(.errorLabel) .customIcon {
  top: 20%;
}

.wrapper:has(svg.customIcon) input {
  padding-left: 2rem;
}

@media screen and (max-width: 500px) {
  .counter {
    left: calc(85% + 6px);
  }
}

@media screen and (max-width: 450px) {
  .input.disabled {
    color: var(--black);
  }

  .errorLabel {
    transform: translateX(-6px);
  }

  .counter {
    left: 80%;
    right: 0.5rem;
  }
}
