.wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 1.5rem 1.25rem 1.5rem;
}

.leftSide {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.businessImage {
  width: 3rem;
  height: 3rem;
  border-radius: 1rem;
  object-fit: cover;
}

.title {
  color: var(--black);
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-16);
}

.address {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
}

.closeIcon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-left: auto;
  padding: 0.5rem;
}
