.wrapper {
  margin: 1.5rem;
  height: calc(100vh - 120px);
  background-color: var(--white);
  border-radius: 0.75rem;
  overflow: hidden;
  padding: 1.5rem;
}

.adminWrapper {
  margin: 0 1.5rem 0.5rem 1.5rem;
  padding: 0;
}

.mediaItemList {
  display: grid;
  gap: 1.5rem;
  margin-top: 1.5rem;
  max-height: calc(100vh - 320px);
  grid-template: auto / repeat(auto-fill, minmax(240px, 1fr));
  overflow: hidden;
  overflow-y: auto;
  padding: 0.25rem;
}

.mediaItemList::-webkit-scrollbar {
  display: none;
}

.customLoaderWrapper {
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
}

.mockedPhotoLoader {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 1px solid var(--grey300);
  border-radius: 8px;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin: 0;
    border-radius: 0px;
    padding: 16px;
  }

  .pagination {
    box-sizing: border-box;
    padding-bottom: 24px;
    padding-inline: 0;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin: 0;
  }

  .mediaItemList {
    margin-top: 0.25rem;
    max-height: calc(100svh - 340px);
    grid-template: auto / repeat(auto-fill, minmax(180px, 1fr));
  }
}
