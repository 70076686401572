.option {
  align-items: center;
  max-width: 90%;
  border: 1px solid var(--secondary500);
  border-radius: 12px;
  cursor: pointer;
  padding: 24px;
}

.selectedOption,
.option:hover {
  background-color: var(--secondary50);
  border-color: var(--secondary400);
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkboxStyle {
  width: 20px;
  height: 20px;
  border: 1px solid var(--secondary500);
  border-radius: 50%;
}

.checboxWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-right: 16px;
  white-space: nowrap;
}

.subInfo {
  margin-top: 8px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin-inline: 42px;
  }

  .checkboxWrapper {
    font-size: var(--font-size-sm);
    white-space: wrap;
  }
}
