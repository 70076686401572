.actionsWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  margin-top: auto;
  width: 100%;
  box-sizing: border-box;
}

.goBackBtn {
  width: 165px;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding: 10px 24px;
}

.goBackBtn:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.confirmBtn {
  width: 180px;
  color: var(--white);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding: 10px 24px;
}

.resignBtn {
  background-color: transparent;
  color: var(--primary500);
  font-size: var(--font-size-sm);
}

.resignBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.creatingDoneWrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 24px auto;
}

.goVerify {
  padding: 12px 20px;
}

@media screen and (max-width: 700px) {
  .actionsWrapper {
    padding-right: 0;
  }
}

@media screen and (max-width: 650px) {
  .actionsWrapper {
    flex-direction: column-reverse;
    margin-top: auto;
  }

  .goBackBtn,
  .confirmBtn {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .actionsWrapper,
  .creatingDoneWrapper {
    flex-direction: column-reverse;
    margin-top: auto;
    width: 100%;
    padding-right: 0;
  }

  .actionsWrapper {
    margin-top: 32px;
  }

  .goBackBtn {
    display: none;
    width: 100%;
    max-width: 100%;
  }

  .goVerify {
    padding: 6px 12px;
  }
}
