.edit {
  display: flex;
  gap: 8px;
  margin-top: 24px;
  align-items: center;
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
  color: var(--black);
  padding-left: 40px;
  padding-right: 40px;
}

.buttons {
  display: flex;
  flex-direction: row;
}

.button {
  margin-right: 15px;
  padding-left: 40px;
  padding-right: 40px;
}

.button:disabled {
  cursor: auto;
}

.button:disabled:active {
  filter: none;
}

.select {
  flex: 1;
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 50%;
  cursor: pointer;
  stroke: var(--grey900);
  stroke-width: 1.5px;
}

.action:disabled {
  background-color: var(--grey100);
  border: 1px solid var(--grey300);
  cursor: auto;
  stroke: var(--grey500);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  flex: 1;
  margin-bottom: 24px;
}

.icon {
  width: var(--icon-regular);
  height: var(--icon-regular);
}

.plusIcon {
  width: var(--icon-xsmall);
  height: var(--icon-xsmall);
}

.tooltipStyle {
  width: 200px;
  background-color: var(--white);
  border-radius: 6px;
  bottom: 20px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  z-index: 20;
}

.toolWrapper {
  width: 100%;
}

.categoryTooltipStyle {
  width: 200px;
  background-color: transparent;
  bottom: 0;
  color: var(--grey700);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  position: absolute;
  right: 10px;
  text-align: right;
  z-index: 2;
}

@media screen and (max-width: 550px) {
  .categoryTooltipStyle {
    display: none;
  }
}