.wrapper {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  font-family: var(--manrope);
  overflow: hidden;
}

.outletWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
