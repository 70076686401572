.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  fill: var(--white);
}

.icon > * {
  width: 1rem;
  height: 1rem;
  color: var(--white);
}

.warning {
  background-color: var(--yellow500);
}

.success {
  background-color: var(--green500);
}

.error {
  background-color: var(--red500);
  overflow: hidden;
}

.icon .success {
  background-color: transparent;
  transform: translate(5%, 20%);
}

.icon .warning {
  width: 0.8rem;
  height: 0.8rem;
  background-color: transparent;
}
