.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: var(--poppins);
  overflow-y: auto;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 1.5rem;
}

.body::-webkit-scrollbar {
  width: 8px;
}

.body.reverse {
  flex-direction: column-reverse;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}

.body > * {
  order: 1;
}

.body > .businessCardsMetadata.noData {
  order: 0;
}

@media screen and (max-width: 768px) {
  .body {
    padding: 12px;
  }
}

@media screen and (max-width: 450px) {
  .headerText {
    font-size: 20px;
  }

  .header {
    height: 60px;
    padding: 1rem;
  }

  .body {
    margin-top: 1rem;
    padding: 12px;
  }

  .body::-webkit-scrollbar {
    display: none;
  }
}
