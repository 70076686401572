.linkDataWrapper {
  border-bottom: 1px solid var(--adminGreyNormalHover);
  color: var(--black);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 1rem;
}

.label {
  color: var(----adminGreyDarkActive);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.linkInteractionsValue {
  color: var(--black);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
}

.columnLabel {
  display: flex;
  flex-direction: column;
  margin-block: 1rem;
}

.linkValue {
  max-width: 150px;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rowWrapper {
  max-width: 70%;
  border-bottom: 1px solid var(--adminGreyNormalHover);
  margin-bottom: 1rem;
  margin-left: 2rem;
}

.linkLastUsed {
  color: var(--black);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.clientData .linkValue,
.clientData .linkLastUsed {
  color: var(--grey800);
  font-weight: 400;
}

.clientData .linkInteractionsValue {
  color: var(--grey800);
}

.clientLabel > * {
  font-size: var(--font-size-sm);
}
