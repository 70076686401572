.priceMethodWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  align-items: stretch;
  width: 100%;
}

.chooseMethodTitle {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

@media screen and (max-width: 450px) {
  .priceMethodWrapper {
    margin-top: 1.25rem;
    padding-top: 0;
  }
}
