.bodyWrapper {
  margin-top: 1.5rem;
  max-width: 55%;
}

.label {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 4rem;
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.saveBtn {
  gap: 0.5rem;
  margin: auto 0 0 auto;
  width: fit-content;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.saveBtn:hover {
  background-color: var(--adminOrange700);
}

.saveBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
  user-select: none;
}

.wrapper {
  border-bottom: 1px solid var(--adminGreyNormal);
  margin-bottom: 1rem;
  padding-bottom: 1.5rem;
}

.loaderWrapper {
  height: auto;
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}

.error {
  color: var(--adminRed500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}
