.keywords {
  display: flex;
  flex-direction: column;
  max-width: 34%;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  box-sizing: border-box;
  color: var(--grey800);
  flex: 1;
  flex-shrink: 0;
  padding: 1.5rem;
}

.keywords.noData {
  background-color: var(--grey100);
  filter: grayscale(0.5);
  opacity: 0.7;
}

.title {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.tooltipStyle {
  width: 500px;
  max-width: 320px;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  box-sizing: border-box;
  float: left;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  left: 0px;
  overflow: visible;
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  top: 20px;
  white-space: wrap;
  z-index: 2;
}

.infoIcon {
  width: var(--icon-small);
  height: var(--icon-small);
  cursor: pointer;
  stroke: var(--grey600);
}

.keywordsList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.keywordBtn {
  margin-top: 1.5rem;
  align-self: flex-end;
  background-color: transparent;
  color: var(--primary500);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
}

.keywordBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.noKeywords {
  margin: 1.5rem auto 0 auto;
  width: 100%;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
  text-align: center;
}

.keywordsList:has(.noKeywords) {
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 0;
}

@media screen and (max-width: 1450px) {
  .keywordsList {
    gap: 12px;
    margin-right: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .keywordBtn {
    margin-top: 12px;
    align-self: flex-start;
    padding-left: 0;
  }

  .keywords {
    max-width: none;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    flex-direction: column;
  }

  .keywords {
    display: flex;
    width: auto;
  }

  .keywordsList {
    margin-bottom: 12px;
    margin-right: 0;
  }

  .keywordBtn {
    margin-top: 0;
    align-self: flex-start;
    padding-left: 0;
  }

  .tooltipStyle {
    width: 320px;
    left: 0;
    position: absolute;
    transform: translateX(-90%);
    white-space: normal;
  }
}
