.heading {
  width: 90%;
}

.title {
  margin: 0px;
  color: var(--grey900);
  font-size: var(--display-xs);
  font-weight: var(--font-weight-semi);
  line-height: 130%;
  margin-bottom: 32px;
  text-align: center;
}

.span {
  color: var(--secondary500);
}

@media screen and (max-width: 768px) {
  .heading {
    width: 100%;
  }

  .title {
    font-size: var(--font-size-lg);
    margin-bottom: 24px;
  }
  
}

@media screen and (max-width: 450px) {
  .heading {
    display: flex;
    justify-content: center;
    width: 90%;
  }

  .title {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    margin-bottom: 16px;
  }
}
