.wrapper {
  height: 4px;
  min-height: 4px;
  background-color: var(--grey100);
}

.progress {
  height: 100%;
  background-color: var(--primary300);
  transition: .3s width ease;
}