.accountInfoWrapper {
  margin-top: 1.5rem;
  max-width: 50%;
}

.lastStatusChangeDate {
  color: var(--black);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.pill {
  background-color: var(--adminGreyNormalActive);
  border: none;
  border-radius: 20px;
  color: var(--black);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 6px 12px;
}

.activeStatusPill {
  background-color: var(--adminOrange400);
}

.dataTitle {
  color: var(--black);
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-semi);
}

.basicDataWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.dataLabel {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  margin-bottom: 0.5rem;
}

.dataValue {
  font-weight: var(--font-weight-semi);
}
