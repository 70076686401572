.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  border: 2px solid var(--secondary500);
  border-radius: 50%;
  cursor: pointer;
}

.label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox {
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  background-color: var(--white);
  border-radius: 50%;
  box-sizing: border-box;
}

.input {
  display: none;
}

.input:checked~.checkbox {
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  background-color: var(--secondary500);
  border: 4px solid var(--white);
  border-radius: 50%;
  box-sizing: border-box;
}

.userClick {
  cursor: pointer;
}

.primaryCheckbox {
  border-color: var(--primary500);
}

.input:checked~.primaryCheckbox {
  background-color: var(--primary500);
}