.container {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  width: calc(100% / 5);
  min-width: calc(100% / 5);
  background-color: var(--white);
  border: 2px solid transparent;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  padding: 14px 16px;
}

.container:not(.noData):not(.noHighlight):nth-child(1):hover,
.container:not(.noData):not(.noHighlight):nth-child(2):hover {
  border: 2px solid var(--primary300);
}

.container.noData {
  background-color: var(--grey100);
  opacity: 0.7;
}

.valueBox {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  color: var(--grey600);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
  white-space: nowrap;
  word-break: break-all;
}

.infoIcon {
  width: var(--icon-small);
  height: var(--icon-small);
  color: var(--grey600);
  cursor: pointer;
}

.infoIcon:hover {
  stroke: var(--grey800);
}

.value {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  align-items: flex-end;
  color: var(--grey800);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
}

.valueComparison {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--green500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semi);
}

.arrow {
  width: var(--icon-small);
  height: var(--icon-small);
  stroke-width: 2px;
  transform: rotate(90deg);
}

.tooltip {
  width: 500px;
  max-width: 500px;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  float: left;
  left: 0px;
  overflow: visible;
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  top: 20px;
  white-space: wrap;
  word-break: keep-all;
  z-index: 200;
}

.rotate {
  transform: rotate(270deg);
}

.red {
  color: var(--red500);
}

.icon {
  min-width: 32px;
}

@media screen and (max-width: 1350px) {
  .container {
    width: 100%;
    word-break: break-all;
  }

  .tooltip {
    margin: 0 auto;
    width: 320px;
    max-width: 320px;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    transform: translateX(-75%);
    white-space: normal;
  }
}

@media screen and (max-width: 1290px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 786px) {
  .tooltip {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 550px) {
  .container {
    width: 100%;
    box-sizing: border-box;
  }

  .value {
    align-items: center;
  }

  .tooltip {
    transform: translateX(-50%);
  }
}
