.modalContent {
  max-width: 50vw;
  max-height: 95vh;
}

.wrapper {
  margin-top: 1.5rem;
}

@media screen and (max-width: 1440px) {
  .modalContent {
    max-width: 80vw;
  }
}
