.row {
  margin-top: 10rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
  margin-inline: auto;
}

.gridContainer {
  display: grid;
  gap: max(2rem, 2%);
  grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  position: relative;
  row-gap: 0;
}

.gridItem {
  max-width: 140px;
  background-color: var(--primary50);
  border-bottom: 1px solid var(--primary300);
  box-sizing: border-box;
  margin-block: 1.5rem;
  position: relative;
  text-align: center;
  text-wrap: nowrap;
  z-index: 1;
}

.gridItem:not(:first-child)::before {
  width: 100%;
  height: 10rem;
  background-color: var(--primary50);
  border-radius: 0;
  content: '';
  left: 0;
  position: absolute;
  top: -4rem;
  z-index: -1;
}

.gridItem.planName::before {
  height: 60px;
  background-color: transparent;
  bottom: 0;
  transform: translateY(125px);
  z-index: 0;
}

.gridItem:nth-last-of-type(-n + 5)::before {
  height: 8rem;
  border-radius: 0 0 9px 9px;
}

.planName {
  margin-top: 0;
  background-color: var(--primary500);
  border-radius: 9px 9px 0 0;
  padding: 2rem 1rem;
  position: sticky;
  top: 0;
  z-index: 5;
}

.gridItemTitle {
  color: var(--grey900);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  grid-column: 1 / 2;
  line-height: var(--line-height-15);
  margin-block: 1rem;
}

.gridItemSubFeature {
  border-bottom: 1px solid var(--primary300);
  grid-column: 1;
  margin-block: 1.5rem;
  padding-bottom: 1.5rem;
}

.googleBusienssCards,
.seoCards {
  color: var(--grey900);
  font-size: var(--display-xs);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
  margin-bottom: 2.5rem;
  position: absolute;
  top: 8rem;
}

.seoCards {
  margin: 0;
  position: static;
}

.comparePlans {
  max-width: none;
  background-color: transparent;
  border-bottom: 0;
  color: var(--secondary500);
  font-size: var(--display-md);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-15);
  margin-bottom: 20px;
  text-align: start;
}

.planTitle,
.planPrice {
  color: var(--white);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-15);
  text-align: center;
}

.planPrice {
  margin-top: 0.75rem;
  font-size: var(--font-size-sm);
}

.planPrice span {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
}

.expandBtn {
  margin: 1rem 0 0 auto;
  background-color: transparent;
  color: var(--primary500);
  padding-inline: 0;
}

.expandBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.previewValue {
  color: var(--primary800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

.boldText {
  font-weight: var(--font-weight-semi);
}

.cardTitle,
.gridCardItem {
  margin-block: 2rem;
}
@media screen and (max-width: 1200px) {
  .gridContainer {
    gap: .5rem;
  }
}
@media screen and (max-width: 1024px) {
  .comparePlans {
    font-size: var(--font-size-xl);
  }

  .planPrice {
    display: none;
  }

  .gridContainer {
    margin: 0;
    column-gap: 0.5rem;
  }

  .gridItem.planName {
    max-width: 130px;
  }

  .googleBusienssCards {
    display: none;
  }

  .seoCards {
    font-size: var(--font-size-lg);
  }

  .gridItemTitle {
    font-size: 16px;
  }

  .gridItemSubFeature {
    font-size: 14px;
  }

  .expandBtn {
    margin-right: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .gridContainer {
    margin-inline: 0;
  }
}
