.ctaSection {
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 600px;
  background: linear-gradient(180deg, #fe8130 0%, #ff761e 98.75%);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.leftSide {
  width: 55%;
  box-sizing: border-box;
  padding: 170px 100px 170px 85px;
}

.rightSide {
  width: 45%;
  height: 100%;
  position: absolute;
  right: 0;
}

.CTAImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  margin: 0;
  max-width: 70%;
  color: var(--white);
  font-size: var(--display-sm);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.description {
  margin: 1.5rem 0 2rem 0;
  color: var(--white);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

.signUpBtn {
  background-color: var(--white);
  border-radius: 0.5rem;
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  padding: 0.75rem 2rem;
}

.signUpBtn:hover {
  background-color: var(--primary50);
  color: var(--primary500);
}

@media screen and (min-width: 2000px) {
  .leftSide {
    padding-left: 14vw;
  }

  .title,
  .description {
    max-width: 70%;
  }
}

@media screen and (max-width: 1440px) {
  .title {
    max-width: none;
  }
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: var(--display-xs);
  }

  .leftSide {
    padding: 100px 60px;
  }

  .rightSide {
    width: auto;
    max-width: 45%;
    flex: 0.75;
    position: absolute;
    right: 0;
  }

  .ctaSection {
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .rightSide {
    margin-top: 4.25rem;
    width: 100vw;
    max-width: none;
    bottom: -0.5rem;
    position: relative;
  }

  .CTAImage {
    width: 100vw;
    object-fit: contain;
  }

  .ctaSection {
    flex-direction: column;
    max-height: none;
    padding: 4.25rem 1rem 0;
  }

  .leftSide {
    width: auto;
    max-width: none;
    padding: 0;
  }

  .title {
    font-size: var(--font-size-lg);
  }

  .description {
    font-size: var(--font-size-md);
  }
}
