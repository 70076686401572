.content {
  max-width: 650px;
  border: 1px solid var(--grey100);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.modalContent {
  display: flex;
  flex-direction: column;
  margin: 16px auto;
  max-width: calc(100% - 32px);
  background-color: var(--grey50);
  border-radius: 10px;
  color: var(--grey700);
  font-size: 14px;
  font-weight: 400;
  line-height: var(--line-height-16);
  padding: 24px;
}

.howItWorks {
  color: var(--grey800);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.subInfo {
  margin: 24px auto 14px auto;
  font-size: 14px;
  line-height: var(--line-height-16);
}

.learnMore {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  color: var(--primary500);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: var(--line-height-16);
}

.askForOpinion {
  color: var(--grey800);
  font-size: 16px;
  font-weight: 500;
}

.askForOpinionIcons {
  display: flex;
  gap: 16px;
  margin: 16px 0 24px 0;
}

.pill {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  border: 1px solid var(--primary600);
  border-radius: 32px;
  color: var(--primary600);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 16px;
}

.copyIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  stroke: var(--grey700);
  stroke-width: 2.2px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  height: 60px;
}
@media screen and (max-width: 768px) {
  .content {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .modalContent {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 16px 0;
  }

  .subInfo {
    margin: 16px auto 14px auto;
  }

  .askForOpinionIcons {
    gap: 8px;
  }

  .content {
    height: 100%;
    overflow-y: scroll;
  }

  .pill {
    font-size: 12px;
    padding: 6px 12px;
  }
}
