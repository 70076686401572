.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(54, 54, 54, 0.3);
  inset: 0;
  overflow: hidden;
  position: fixed;
  z-index: 10;
}

.categoriesWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 650px;
}

.content {
  width: 675px;
  height: fit-content;
  max-height: calc(100vh - 64px);
}
@media screen and (max-width: 1024px) {
  .modalWrapper {
    z-index: 20;
  }
}
@media screen and (max-width: 700px){
  .content {
    width: 100%;
    height: 100vh;
    z-index: 99;
  }
}
@media screen and (max-width: 768px) {
  .content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
}
