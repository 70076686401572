.wrapper {
  display: flex;
  flex-direction: row;
  gap: 10%;
  justify-content: center;
  margin: 0 130px 130px;
  align-items: center;
}

.title {
  max-width: 100%;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.formTitle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;
}

.rightSide {
  display: flex;
  gap: 24px;
  width: 100%;
  height: 100%;
  flex: 0.7;
}

.form {
  max-width: 85%;
}

.input {
  margin-top: 24px;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin-top: 30px;
    margin-inline: 42px;
  }

  .form {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    margin-top: 0;
    margin-inline: 16px;
  }
}

@media screen and (max-width: 650px) {
  .rightSide {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .leftSide {
    gap: 0;
    margin-left: 0;
  }

  .title {
    font-size: var(--font-size-md);
  }

  .wrapper {
    margin: 0;
  }

  .form {
    max-width: 100%;
  }
}
