.progressBarContainer {
  width: 100%;
  height: 8px;
  background-color: var(--secondary100);
  border-radius: 4px;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  background-color: var(--secondary500);
  color: var(--white);
  line-height: 20px;
  text-align: center;
  transition: width 0.3s ease-in-out;
}
