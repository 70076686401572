.profile {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
  min-height: 56px;
  box-sizing: border-box;
  user-select: none;
}

.image {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  cursor: pointer;
  fill: var(--grey400);
  object-fit: cover;
  stroke: var(--grey400);
}

.image.selected {
  width: 100%;
  height: 100%;
  border: 6px solid var(--secondary500);
  box-sizing: border-box;
}

.noImage {
  width: 44px;
  height: 44px;
  background-color: var(--grey200);
  box-sizing: border-box;
  color: var(--grey400);
  padding: 4px;
}
