.wrapper {
  display: flex;
  flex-direction: row;
  gap: 10%;
  justify-content: center;
  margin: 0 130px 130px;
  align-items: center;
}

.title {
  max-width: 100%;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.subtitle {
  max-width: 90%;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
}

.formTitle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.checkbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.checkboxStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: var(--white);
  border: 1px solid var(--secondary500);
  border-radius: 50%;
  margin-right: 8px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;
}

.rightSide {
  display: flex;
  gap: 24px;
  width: 100%;
  height: 100%;
  flex: 0.7;
}

.form {
  max-width: 85%;
}

.pill {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--secondary500);
  border-radius: 32px;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: 500;
  padding: 6px 16px;
}

.editIcon {
  cursor: pointer;
}

.pickedAreasList {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 12px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.optionalInformationText {
  margin-top: 12px;
  max-width: 90%;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-16);
}

.closeIcon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  fill: var(--grey600);
}

.closeIcon:hover {
  fill: var(--grey800);
  filter: scale(1.2);
}

@media screen and (max-width: 1300px) {
  .title,
  .subtitle,
  .optionalInformationText,
  .form {
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin-top: 30px;
    align-items: flex-start;
    margin-inline: 42px;
  }

  .title,
  .subtitle,
  .form {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    margin-inline: 16px;
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    margin: 0;
    margin-bottom: 60px;
  }

  .rightSide {
    display: none;
  }

  .leftSide {
    z-index: 1;
  }

  .form {
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .title {
    max-width: 100%;
    font-size: var(--font-size-md);
  }

  .subtitle {
    max-width: 100%;
    font-size: var(--font-size-xs);
  }

  .optionalInformationText {
    max-width: 100%;
    font-size: var(--font-size-xs);
  }

  .form {
    max-width: 100%;
  }

  .inputs {
    max-width: 100%;
  }

  .formTitle {
    max-width: 100%;
  }

  .checkbox {
    max-width: 100%;
  }

  .checkboxStyle {
    max-width: 100%;
  }

  .leftSide {
    max-width: 100%;
    margin-left: 0px;
  }

  .rightSide {
    display: none;
    max-width: 100%;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: center;
    height: 100%;
    margin-inline: 0px;
  }
}
