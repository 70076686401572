.wrapper {
  display: none;
}

.rwdNavigation {
  display: none;
  gap: 8px;
  margin: 24px 0;
  align-items: center;
  color: var(--grey700);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-15);
}

.arrow {
  width: var(--icon-medium);
  height: var(--icon-medium);
  stroke-width: 1.5px;
}

@media screen and (max-width: 450px) {
  .wrapper {
    display: block;
  }

  .rwdNavigation {
    display: flex;
  }
}
