.modal {
  max-width: 600px;
}

.modalBody {
  margin-top: 24px;
}

.modalText {
  color: var(--grey800);
  line-height: var(--line-height-15);
  margin-bottom: 24px;
}

.modalBtns {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 54px;
  align-items: center;
}

.cancelBtn,
.confirmBtn {
  padding: 10px 24px;
}

.cancelBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
}

.cancelBtn:hover {
  background-color: var(--grey100);
  border: 1px solid var(--grey500);
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .modal {
    max-width: 100%;
  }
}
