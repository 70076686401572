.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  margin-top: 24px;
  align-items: flex-start;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
}

.input {
  max-width: 33%;
}

.input:first-of-type {}
@media screen and (max-width: 800px) {
  .row {
    flex-direction: column;
    width: 100%;
  }

  .input {
    max-width: 100%;
  }

}