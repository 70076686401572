.noImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--grey100);
}

.noImageIcon {
  max-height: 4rem;
  color: var(--grey400);
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 270px;
  object-fit: contain;
}

.card {
  background-color: var(--grey100);
  border-radius: .75rem;
  margin-bottom: 1rem;
  height: 270px;
  max-height: 270px;
  display: flex;
}