.wrapper {
    border: 1px solid var(--grey300);
    border-radius: .25rem;
    overflow: hidden;
    padding: 1rem;
}

.dataItemWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.key {
    color: var(--grey700);
    font-size: var(--font-size-sm);
    font-weight: var(--line-height-16);
}

.dataItemWrapper:first-of-type .key {
    display: none;
}

.dataItemWrapper:first-of-type {
    justify-content: flex-start;
    align-items: center;
}

.value {
    text-transform: capitalize;
}

.checkboxStyle {
    display: flex;
    gap: 6px;
    width: 16px;
    height: 16px;
    border: 1px solid var(--secondary500);
    border-radius: 6px;
    overflow: hidden;
}

.checkboxWrapper {
    display: flex;
    justify-content: flex-start;
    margin-right: 12px;
}

.checkbox {
    display: flex;
    gap: 8px;
    width: 1rem;
    max-width: 1rem;
    height: 1rem;
    border: 1px solid var(--secondary500);
    border-radius: 0.25rem;
    color: var(--grey800);
    font-size: var(--font-size-sm);
    margin-right: 1rem;
}


.pill {
    background-color: var(--green50);
    border: 1px solid var(--green500);
    border-radius: 1rem;
    padding: 0.25rem 0.75rem;
}

.duplicate {
    background-color: var(--grey50);
    border-color: var(--grey600);
    color: var(--grey900);
}

.suspended {
    background-color: var(--yellow50);
    border-color: var(--yellow500);
    color: var(--yellow700);
}

.notVerified {
    background-color: var(--red50);
    border-color: var(--red500);
    color: var(--red700);
}

.external {
    background-color: transparent;
    border-color: transparent;
}

.checkIcon {
    width: 16px;
    transform: translate(1px, 1px);
}

.icon {
    width: 18px;
    height: 18px;
    background-color: var(--grey100);
    color: var(--grey900);
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 38px;
    max-height: 38px;
    aspect-ratio: 1/1;
    background-color: var(--grey100);
    border: 1px solid var(--grey400);
    border-radius: 50%;
    box-sizing: border-box;
}

.processing {
    background-color: var(--grey50);
    border-color: var(--grey600);
    color: var(--grey900);
}

.actions {
    display: flex;
    gap: .5rem;
}

.dataItemWrapper>p {
    overflow: hidden;

}

.actions {
    display: flex;
    gap: 1rem;
    align-self: flex-end;
    justify-self: flex-end;
}

.btn {
    background-color: transparent;
    color: var(--primary500);
    font-size: var(--font-size-sm);
}

.btn:hover,
.btn:focus {
    background-color: transparent;
    color: var(--primary600);
}

.btn:last-of-type {
    padding-right: 0;
}

.selectedItem {
    background-color: var(--grey100);
}