.wrapper {
  margin: 24px 120px;
}

.standAloneWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px;
  align-items: center;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  background-color: var(--white);
  border-radius: 16px;
  box-sizing: border-box;
  margin-inline: 24px;
}

.standAloneWrapper .content {
  margin-top: auto;
  width: calc(100% - 96px);
  margin-bottom: 0;
}

.standAloneWrapper .actionsContainer {
  margin-top: auto;
  margin-bottom: 24px;
  margin-left: auto;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 10%;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
}

.leftSide {
  flex: 1;
}

.rightSide {
  flex: 0.6;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  margin-bottom: 8px;
}

.subTitle {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
  margin-bottom: 16px;
  white-space: pre-wrap;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 80%;
  margin-bottom: 12px;
}

.infoIcon {
  width: var(--icon-small);
  aspect-ratio: 1/1;
}

.helperText {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: var(--grey700);
  font-size: var(--font-size-xs);
}

.actionsContainer {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 12px;
  align-items: center;
}

.googleVerificationBtn {
  background-color: transparent;
  color: var(--primary500);
  padding-left: 0;
}

.googleVerificationBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.sendDataBtn {
  padding: 8px 24px;
}

@media screen and (max-width: 1024px) {
  .content {
    align-items: center;
    width: 100%;
  }

  .wrapper {
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  .standAloneWrapper {
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
  }

  .content {
    gap: 5%;
  }

  .standAloneWrapper .content {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .rightSide {
    display: none;
  }

  .form,
  .leftSide {
    width: 100%;
    max-width: 100%;
  }

  .standAloneWrapper {
    height: calc(100vh - 64px);
  }

  .actionsContainer {
    flex-direction: column;
    justify-content: center;
    margin: 0;
    align-items: center;
    width: 100%;
  }

  .standAloneWrapper .content {
    margin-top: 0;
  }

  .sendDataBtn {
    width: 100%;
  }
}
