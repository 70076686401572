.wrapper {
  background-color: var(--grey50);
  border: 1px solid var(--grey200);
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.whiteBg {
  background-color: var(--white);
  border-color: var(--secondary500);
}

.invoiceTitle {
  max-width: 80%;
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
}

.address,
.contactData {
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
  margin-bottom: 1rem;
}

.contactData {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.actions {
  display: flex;
  gap: 1rem;
  position: relative;
}

.editBtn,
.deleteBtn {
  gap: 0.25rem;
  background-color: transparent;
  border: 1px solid var(--primary500);
  color: var(--primary500);
  font-size: var(--font-size-sm);
}

.editBtn:hover,
.deleteBtn:hover {
  background-color: transparent;
  border-color: var(--primary600);
}

.deleteBtn {
  background-color: var(--grey50);
  border-color: var(--grey400);
  color: var(--grey900);
  position: static;
}

.deleteBtn:hover {
  border-color: var(--grey500);
}

.deleteBtn:disabled {
  background-color: var(--grey200);
  border-color: var(--grey300);
  color: var(--grey600);
}

.checkboxStyle {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
}

.checkbox {
  display: flex;
  gap: 8px;
  margin: 0;
  margin-top: 1rem;
  width: fit-content;
  color: var(--grey700);
  cursor: auto;
  font-size: var(--font-size-sm);
  margin-right: auto;
}

.checkIconStyle {
  display: none;
  transform: translate(1.25px, 0.5px);
}

.defaultInvoiceCheckbox {
  cursor: initial;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}

.categoryTooltipStyle {
  width: 20rem;
  background-color: var(--white);
  bottom: 100%;
  box-shadow: 0px 1px 3px 0px #1018281a;
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  overflow: visible;
  padding-inline: 8px;
  position: absolute;
  z-index: 2;
}

.relatedItem {
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .row {
    width: 100%;
  }

  .row {
    flex-wrap: wrap;
  }

  .actions,
  .actions>*,
  .deleteBtn {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    padding: 1rem;
  }
}