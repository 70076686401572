.title {
  margin-top: 8px;
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.content {
  overflow-y: scroll;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
}

.filtersTitle {
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  margin-bottom: 8px;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 45px;
  background-color: transparent;
  border: 1px solid var(--primary500);
  color: var(--primary500);
  cursor: pointer;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 24px;
  pointer-events: all;
  white-space: nowrap;
}

.button:hover {
  background-color: transparent;
  border-color: var(--primary600);
  color: var(--primary600);
}

.button:first-of-type {
  margin-left: 24px;
}

.editFilters.field {
  background: var(--white, #fff);
  border: 1px solid var(--grey300);
  border-radius: 8px;
}

.fields.editFilters {
  width: 100%;
  border-radius: 0;
  pointer-events: all;
}

.label {
  width: 100%;
  color: var(--grey700);
  font-size: 12px;
  line-height: var(--line-height-16);
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.value {
  width: 100%;
  color: var(--grey900);
  font-size: 16px;
  line-height: var(--line-height-15);
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 0;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--white100);
  border: 1px solid var(--grey300);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 4px 16px;
  position: relative;
}

.field.leftFocused {
  border-left-color: transparent;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(4px);
  left: 0;
  position: absolute;
  top: 0;
  z-index: 100;
}

.changeComponentWrapper {
  width: 90%;
  background-color: var(--white);
  border-radius: 8px;
  bottom: calc(90% + 15px);
  padding: 24px;
  z-index: 20;
}

.keywordInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.keywordInput {
  width: 100%;
  background-color: transparent;
  border: none;
  color: var(--grey900);
  flex: 1;
  font-size: 16px;
  line-height: var(--line-height-15);
  outline: none;
  overflow: hidden;
  padding-right: 40px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.actionBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.generateBtn {
  padding: 10px 24px;
}

.cancelBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  padding: 10px 24px;
}

.cancelBtn:hover {
  background-color: var(--grey100);
  border: 1px solid var(--grey500);
}

.expandableSectionTitle {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
}

.counter {
  bottom: 0;
  color: var(--grey700);
  font-size: var(--font-size-xs);
  margin-bottom: 4px;
  margin-right: 12px;
  position: absolute;
  right: 0;
}

.addressForm {
  padding: 4px;
}

.field:has(.addressForm) .changeComponentWrapper {
  margin-top: 4px;
  margin-left: 0;
  overflow-x: hidden;
}
