.month {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.calendarGrid {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(7, 1fr);
  overflow: hidden;
}

.weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--grey600);
  font-size: var(--font-size-sm);
}

.lightDay {
  border-radius: 0.5rem;
  color: #63717c;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 0.5rem;
}
