.wrapper {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 16px;
  font-weight: 500;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image {
  height: 180px;
}

.point {
  aspect-ratio: 1;
  background-color: var(--secondary500);
  border: 1px solid var(--secondary500);
  border-radius: 50%;
  opacity: 0.1;
  position: absolute;
  transform-origin: center;
}

.net {
  width: 180px;
  height: 180px;
  position: absolute;
  top: 0;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.value {
  width: 50px;
  font-size: 14px;
  text-align: center;
}

.rangeLabel {
  width: 60px;
  text-align: right;
}

.subInfo {
  margin-top: 8px;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
  margin-bottom: 16px;
}
@media screen and (max-width: 450px) {
  .image {
    width: 100%;
  }

  .label {
    display: none;
  }

  .rangeLabel {
    display: none;
  }
}
