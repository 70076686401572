.emptyDay,
.day {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 0.5rem;
  position: relative;
  user-select: none;
}

.selectedDay {
  background-color: var(--primary500);
  border: 1px solid transparent;
  color: var(--white);
}

.betweenDays {
  background-color: transparent;
  color: var(--grey900);
}

.betweenDays::before {
  background-color: var(--primary100);
  bottom: 0;
  content: '';
  left: -14px;
  position: absolute;
  right: -14px;
  top: 0;
  z-index: -1;
}

.today {
  border: 1px solid var(--primary500);
  color: var(--primary500);
}

.day:hover {
  color: var(--primary500);
}

.today.betweenDays {
  border: none;
}

.today.selectedDay {
  color: var(--white);
}

.selectedDay.day:hover {
  background-color: var(--primary400);
  color: var(--white);
}

.futureDayNotAvailable {
  color: var(--grey300);
}

.futureDayNotAvailable:hover {
  color: var(--grey300);
  cursor: not-allowed;
  user-select: none;
}

.admin.today {
  border-color: var(--adminOrange500);
  color: var(--adminOrange500);
}

.admin.day:hover {
  color: var(--adminOrange500);
}


.admin.selectedDay:hover {
  background-color: var(--adminOrange300);
}

.admin.betweenDays::before {
  background-color: var(--adminOrange100);
}

.admin.selectedDay {
  background-color: var(--adminOrange500);
  color: var(--white);
}

.notification {
  display: none;
  height: 1000px;
}