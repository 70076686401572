.viewHeaderBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey100);
  box-sizing: border-box;
  color: var(--grey800);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  padding: 1.5rem 2.5rem 0.8rem;
}

.body {
  margin: 1.5rem;
  height: 100%;
  max-height: calc(100vh - 240px);
  background-color: var(--white);
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.wrapper {
  height: 100%;
  max-height: calc(100vh - 70px);
}

.pill {
  background-color: var(--grey200);
  border: none;
  border-radius: 1rem;
  color: var(--grey900);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
  padding: 0.25rem 0.75rem;
}

.activeStatusPill {
  background-color: var(--primary50);
  color: var(--primary900);
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

.orderCartBtn {
  gap: 0.5rem;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 1024px) {
  .viewHeaderBar {
    padding: 1.5rem 1.5rem 0.8rem;
  }

  .wrapper {
    margin: 0;
    background-color: var(--white);
  }

  .body {
    margin: 0;
    border-radius: 0;
    overflow: scroll;
    padding: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .viewHeaderBar {
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
    font-size: var(--font-size-md);
    padding: 1.5rem 1rem 1rem;
  }

  .wrapper {
    overflow-y: scroll;
  }

  .orderCartBtn {
    background-color: transparent;
    color: var(--primary500);
    padding-left: 0;
  }

  .orderCartBtn:hover,
  .orderCartBtn:focus {
    background-color: transparent;
    color: var(--primary600);
  }
}