.listWrapper {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
}

.month {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  border-radius: 0.5rem;
  color: var(--grey900);
  padding: 6px 16px;
}

.month:hover {
  color: var(--adminOrange500);
  cursor: pointer;
}

.selectedMonth {
  background-color: var(--adminOrange500);
  color: var(--white);
  transition: all 0.1s ease-in-out;
}

.disabled {
  color: var(--grey400);
  pointer-events: none;
}

.selectedMonth:hover {
  background-color: var(--adminOrange200);
  color: var(--adminOrange600);
  transition: all 0.1s ease-in-out;
}

.clientMonth.selectedMonth {
  background-color: var(--primary500);
}

.clientMonth:hover {
  background-color: var(--primary600);
  color: var(--white);
}
