.modal {
  width: max(50vw, 40rem);
  max-height: 90vh;
  min-height: 90vh;
  overflow: hidden;
}

.modalWithCalendar {
  overflow: visible;
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid var(--adminGreyNormal);
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
}

.heading>div {
  color: var(--black);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  white-space: pre-wrap;
  word-break: break-word;
}

.titleStyle {
  white-space: pre-line;
}

.clientTitle {
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
}

.clientHeading>div {
  color: var(--grey700);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
}

.datePickerBtn {
  gap: 0.5rem;
  width: fit-content;
  background-color: transparent;
  border: 1px solid var(--adminGreyNormalActive);
  border-radius: 0.5rem;
  color: var(--adminGreyDarkActive);
  margin-bottom: 1.5rem;
  padding: 10px 16px;
}

.datePickerBtn:hover {
  background-color: transparent;
  color: var(--adminGreyDarker);
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 5;
}

.table {
  max-height: calc(100vh - 25rem);
  height: fit-content;
  margin-bottom: 1.5rem;
  overflow-y: auto;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 70vh;
  flex-grow: 1;
}

.clientBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 2rem;
  color: var(--grey900);
  padding: 10px 24px;
}

.pagination {
  width: 100%;
}

.toolsWrapper {
  display: flex;
  gap: 1.5rem;
}

.selectClass {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .modal {
    width: 100vw;

  }

  .table::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .datePickerBtn {
    width: 100%;
  }
  .body {
    height: auto;
    max-height: 50vh;
  }
}