.reviewerInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.verifiedLabel {
  margin-left: auto;
}

.checkboxStyle {
  border: 1px solid var(--secondary500);
}

.avatar {
  width: 46px;
  height: 46px;
}

.displayNameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  margin-left: 1.875rem;
}

.newLabel {
  width: fit-content;
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
  border-radius: 4px;
  bottom: 12px;
  color: var(--primary600);
  font-size: 12px;
  left: 5px;
  padding: 4px 8px;
  position: relative;
}

.opinionDetails {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  color: var(--grey500);
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 450px) {
  .displayNameWrapper {
    display: flex;
    flex-direction: column;
    color: var(--grey900);
    font-weight: 500;
    margin-left: 8px;
  }

  .avatar {
    width: 32px;
    height: 32px;
  }
}
