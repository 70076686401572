.actions {
  display: flex;
  gap: 1.5rem;
  margin-top: 3rem;
}

.actions > * {
  padding: 0.5rem 1.5rem;
}

.suspendPlanBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
}

.suspendPlanBtn:hover {
  background-color: var(--grey200);
}

.cancelSubBtn {
  background-color: transparent;
  color: var(--primary500);
  padding-left: 0;
}

.cancelSubBtn:hover {
  background-color: transparent;
  color: var(--primary700);
}

.showPaymentDetailsBtn {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.25rem;
  background-color: transparent;
  color: var(--primary500);
  padding-left: 0;
}

.showPaymentDetailsBtn:disabled {
  background-color: transparent;
  color: var(--primary300);
}

.showPaymentDetailsBtn:hover:not(:disabled) {
  background-color: transparent;
  color: var(--primary700);
}

.arrowIcon {
  transform: rotate(180deg);
}

.recurringActionWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.aboutRecurringChange {
  display: flex;
  gap: 0.5rem;
  margin-top: 1.5rem;
  align-items: flex-start;
  color: var(--grey800);
  font-size: var(--font-size-sm);
}

.aboutRecurringChange > p {
  margin-block: 0;
}

.infoIcon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.cancelTrialBtn {
  background-color: transparent;
  color: var(--primary500);
  padding-left: 0;
  margin-top: 1.5rem;
}

.cancelTrialBtn:hover {
  background-color: transparent;
  color: var(--primary700);
}
@media screen and (max-width: 768px) {
  .recurringActionWrapper {
    align-items: center;
  }

  .actions {
    margin-top: 2.5rem;
  }

  .showPaymentDetailsBtn {
    text-wrap: wrap;
  }
}
