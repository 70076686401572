.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.adminPreview {
  overflow-y: auto;
}

.adminPreview::-webkit-scrollbar {
  width: 0.5rem;
}

.highlighted {
  margin-top: 0px;
  margin-bottom: 24px;
}

.highlighted:has(.content) {
  margin-block: 0;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-right: 0.75rem;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100vh - 520px);
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0.5rem;
  padding-right: 0.25rem;
}

.adminPreview .list {
  height: calc(100svh - 620px);
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.list::-webkit-scrollbar {
  width: 6px;
  border-radius: 32px;
  padding: 24px 0;
}

::-webkit-scrollbar-thumb {
  height: 100px;
  background: var(--grey400);
  border-radius: 32px;
}

.text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 30px;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  margin-bottom: 16px;
}

.resetBtn {
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
  align-items: flex-start;
  background-color: transparent;
  border: none;
  color: var(--primary500);
  font-weight: var(--font-weight-medium);
  stroke: var(--primary500);
}

.resetBtn:hover {
  background-color: transparent;
  color: var(--primary600);
  cursor: pointer;
  stroke: var(--primary600);
}

.competitionText {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
}

.emptySupportingStyle {
  width: 60%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  align-items: center;
  height: 100%;
  transform: translateY(-10%);
}

.headingEmptyStyle {
  font-weight: var(--font-weight-medium);
}

.select {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 32px;
  padding: 6px 16px;
}

@media screen and (max-width: 1024px) {
  .header {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    width: 100%;
    padding-left: 0;
  }

  .wrapper.highlighted {
    margin-right: 0;
  }

  .list {
    height: auto;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .highlighted {
    margin-left: 0;
  }

  .header {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .highlighted {
    margin: 0;
    padding: 0;
  }

  .text {
    margin: 24px 0 16px;
    font-size: var(--font-size-lg);
  }

  .header {
    margin: 0;
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 0;
  }

  .list {
    display: flex;
    margin: 0;
    height: auto;
    overflow: hidden;
    padding: 0;
  }

  .list::-webkit-scrollbar {
    display: none;
  }

  .select {
    display: none;
  }
}
