.wrapper {
  max-width: 40vw;
}

.actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.actions>* {
  padding: 0.5rem 1rem;
}

.closeBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey800);
  color: var(--grey800);
}

.closeBtn:hover {
  background-color: var(--grey100);
}

@media screen and (max-width: 1024px) {
  .wrapper {
    max-width: 60vw;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-width: none;
  }

  .actions>* {
    width: 100%;
  }

  .actions {
    margin-top: auto;
  }
}