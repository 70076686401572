.horizontalStepper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;
  margin-bottom: 90px;
  margin-inline: auto;
}

.stepsBox {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.stepperTitle {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  text-align: center;
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: var(--grey800);
  font-size: 16px;
}

.stepWrapper::before {
  display: block;
  margin: 1rem auto 0;
  max-width: 250px;
  height: 2px;
  content: '';
  position: relative;
}

.stepWrapper:not(:last-child):after {
  width: 80%;
  height: 2px;
  background-color: var(--grey500);
  content: '';
  left: 48%;
  order: -1;
  position: relative;
  top: 2.2rem;
}

.stepNumber.activeStep {
  animation: fillingWithColorStepNumber 0.5s linear;
  background-color: var(--primary500);
  transition: all 0.5s linear;
}

.stepWrapper:has(.stepNumber.activeLine):after {
  background-color: var(--primary500);
  transition: all 0.5s linear;
}

.stepNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  animation: fillingWithGreyColorStepNumber 0.2s linear;
  background-color: var(--grey500);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
  font-weight: 400;
  margin-right: 16px;
  padding: 6px 12px;
}

.stepTitle {
  font-size: var(--font-size-md);
  line-height: var(--line-height-15);
}

@keyframes fillingWithColorStepNumber {
  0% {
    background-color: var(--grey500);
  }

  100% {
    background-color: var(--primary500);
  }
}

@keyframes fillingWithGreyColorStepNumber {
  0% {
    background-color: var(--primary500);
  }

  100% {
    background-color: var(--grey500);
  }
}
@media screen and (min-width: 2000px) {
  .horizontalStepper {
    margin-bottom: 120px;
  }
}
@media screen and (max-width: 768px) {
  .horizontalStepper {
    max-width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .stepTitle {
    font-size: var(--font-size-regular);
    text-align: center;
    white-space: wrap;
  }
}
@media screen and (max-width: 600px) {
  .stepTitle {
    font-size: var(--font-size-sm);
  }
}
@media screen and (max-width: 450px) {
  .horizontalStepper {
    display: none;
  }
}
