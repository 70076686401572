.chartWrapper {
  width: 100%;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  box-sizing: border-box;
  padding: 20px 32px;
}

.chartWrapper.noData {
  background-color: var(--grey100);
  filter: grayscale(0.5);
  opacity: 0.7;
}

.body {
  width: 100%;
  height: 250px;
  box-sizing: border-box;
  position: relative;
  white-space: pre-wrap;
}

.noDataText {
  font-size: var(--font-size-xl);
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

.helper {
  font-size: var(--font-size-sm);
}

.icons {
  display: flex;
  gap: 8px;
  color: var(--grey600);
  margin-left: auto;
}

.pieChart {
  width: 100%;
  padding: 16px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .chartWrapper {
    padding: 16px;
  }

  .noDataText {
    width: 100%;
    font-size: var(--font-size-lg);
  }

  .helper {
    font-size: var(--font-size-xs);
  }
}

@media screen and (max-width: 550px) {
  .chartWrapper {
    padding: 12px;
  }
}

@media screen and (max-width: 450px) {
  .chartWrapper {
    padding: 20px;
  }

  .header {
    margin-bottom: 16px;
  }
}
