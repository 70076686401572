.paymentMethodItem {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  height: 100%;
  border: 1px solid var(--primary400);
  border-radius: 0.75rem;
  box-sizing: border-box;
  cursor: pointer;
  overflow: visible;
  padding: 1rem;
}

.radio {
  width: 1rem;
  height: 1rem;
}

.label {
  font-size: var(--font-size-xs);
}

.popularWrapper {
  border-color: var(--secondary500);
}

.priceTag {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.priceTag span {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

.subInfo {
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
}

.paymentMethodItem.selectedOfferBackground {
  background-color: var(--primary50);
}

.popularWrapper.selectedOfferBackground {
  background-color: var(--secondary50);
}

.paymentMethodItem.change {
  background-color: var(--grey50);
  border-color: var(--grey300);
  pointer-events: none;
}

.paymentMethodItem.change > .radio {
  background-color: red;
}