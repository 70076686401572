.notification {
  background-color: var(--secondary50);
  border-bottom: 1px solid var(--grey400);
  overflow: hidden;
  padding: 1rem;
  text-wrap: nowrap;
}

.bpName {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 70%;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
}

.bpAddress {
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
}

.notificationDate {
  margin-top: 0.5rem;
  color: var(--grey700);
  font-size: var(--font-size-xs);
  font-weight: 400;
  line-height: var(--line-height-15);
  margin-block: 0;
}

.row {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notificationTextWrapper {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  align-items: flex-start;
  margin-left: 1rem;
}

.notificationTextWrapper > p {
  margin-block: 0;
}

.pill {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.25rem;
  align-items: center;
  background-color: var(--primary50);
  border: 1px solid var(--primary500);
  border-radius: 2rem;
  box-sizing: border-box;
  color: var(--primary800);
  margin-bottom: auto;
  margin-left: auto;
  padding: 0.25rem 0.75rem;
}

.system {
  background-color: var(--grey50);
  border: 1px solid var(--grey600);
  color: var(--grey900);
}

.opinieo {
  background-color: var(--secondary50);
  border-color: var(--secondary600);
  color: var(--secondary900);
}

.checkboxStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
  margin-right: 0.5rem;
}

.image {
  width: 45px;
  height: 45px;
  border-radius: 0.75rem;
  margin-right: 0.25rem;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 0.75rem;
  box-sizing: border-box;
  padding: 0.75rem;
}

.seoLogoIcon {
  color: var(--secondary500);
}

.bpNameWithNoImage {
  margin-left: 3rem;
}
