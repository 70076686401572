.searchInput {
  margin: 2rem 1rem;
  width: 35%;
  height: 40px;
  border: 1px solid var(--adminGreyNormalActive);
  border-radius: 8px;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

.pagination {
  box-sizing: border-box;
  margin-bottom: 2rem;
  padding-inline: 2rem;
}

.toolWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-right: 1rem;
}

.addCardBtn {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  width: fit-content;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  justify-self: flex-end;
  margin-left: auto;
  padding: 0.5rem 1.5rem;
}

.addCardBtn:hover {
  background-color: var(--adminOrange700);
}
