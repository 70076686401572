.pageWrapper {
  height: 100%;
  overflow-y: auto;
  margin-inline: auto;
  width: clamp(55vw, 65vw, 75vw);
}

.aiConfig {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper {
  margin-inline: 1.5rem;
}

.button {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  color: var(--primary500);
  background-color: transparent;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  text-align: center;
  justify-self: center;
}

.button:hover {
  background-color: transparent;
  color: var(--primary600);
}