.wrapper {
  width: 100%;
  position: relative;
}

.list {
  width: 100%;
  max-height: 180px;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: 0px 0px 13px 0px rgba(210, 215, 222, 1);
  left: 0;
  overflow-y: auto;
  position: absolute;
  top: 120%;
  z-index: 1;
}

.list::-webkit-scrollbar {
  display: none;
}

.list::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: var(--grey400);
  border-radius: 2rem;
  padding-top: 12px;
}

.element, .noDataElement {
  border-bottom: 1px solid var(--grey300);
  cursor: pointer;
  padding: 14px 16px;
}

.element:last-child, .noDataElement:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 14px;
}

.element:hover, .noDataElement:hover {
  background-color: var(--primary50);
}

.element:focus {
  outline: none;
}

.hoveredElement {
  background-color: var(--primary50);
}

.noDataElement {
  pointer-events: none;
  user-select: none;
}

.icon,
.invertedIcon {
  width: 16px;
  height: 16px;
  bottom: 20%;
  color: var(--grey700);
  position: absolute;
  right: 4%;
  transition: all 0.3s ease-in-out;
}

.invertedIcon {
  color: var(--primary500);
  transform: rotate(180deg) translateY(4px) translateX(2px);
}

.adminIcon {
  bottom: 25%;
  color: var(--adminGreyDarkActive);
}

.adminIcon.invertedIcon {
  color: var(--adminOrange500);
}

.adminIcon.errorIcon {
  color: var(--adminRed500);
  top: 1rem;
}

.disabled {
  background-color: var(--grey50);
  stroke: var(--grey400);
}
