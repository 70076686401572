.button {
  gap: 0.5rem;
  width: fit-content;
  background-color: transparent;
  border: 1px solid var(--primary500);
  color: var(--primary500);
  margin-inline: auto;
  padding: 0.5rem 1.25rem;
}

.button:hover {
  background-color: transparent;
  border-color: var(--primary600);
  color: var(--primary600);
}

.content {
  display: flex;
  flex-direction: column;
}

.headingStyle {
  margin-top: 0.75rem;
  color: var(--grey800);
  font-weight: var(--font-weight-md);
}

.button:disabled {
  background-color: transparent;
}
