.card {
  display: flex;
  flex-direction: column;
  min-height: 450px;
  background-color: var(--white);
  border: 1px solid var(--grey200);
  border-radius: 16px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  margin-bottom: 2px;
  padding: 24px;
  gap: 1rem;
}

.modalContent {
  width: 675px;
  max-height: 90%;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.descriptionContainer {
  display: -webkit-box;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  color: var(--grey900);
  line-height: var(--line-height-16);
  margin-bottom: 20px;
  white-space: pre-wrap;
  word-break: break-word;
}

.callToAction {
  margin-top: auto;
  width: fit-content;
  background: var(--white);
  border: 1px solid var(--primary500);
  border-radius: 32px;
  color: var(--primary500);
  cursor: pointer;
  padding: 8px 20px;
  text-decoration: none;
}

.callToAction:hover {
  background-color: var(--white);
  border-color: var(--primary600);
  color: var(--primary600);
}

.showMore {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  color: var(--primary500);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  margin-bottom: 24px;
  padding-right: 0;
  padding-top: 24px;
}

.showMore:hover {
  background-color: transparent;
  color: var(--primary600);
}

@media screen and (max-width: 768px) {
  .showMore {
    margin-bottom: 0px;
  }

  .modalContent {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

@media screen and (max-width: 450px) {
  .card {
    border-radius: 4px;
    margin-bottom: 24px;
    padding: 0;
  }

  .content {
    padding-inline: 16px;
  }

  .callToAction {
    display: none;
  }

  .modalContent {
    width: 100%;
    top: 64px;
  }

  .showMore {
    margin-bottom: 0px;
    padding-right: 16px;
  }
}
