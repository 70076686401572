.wrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-family: var(--manrope);
}

.notificationBtn {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  padding: 0;
}

.notificationBtn:hover {
  background-color: var(--adminOrange700);
}

.userData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--black);
  font-weight: var(--font-weight-semi);
  position: relative;
}

.fullName {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
}

.login {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.box {
  width: 40px;
  height: 40px;
  background-color: var(--adminOrange600);
}

.chevronIcon {
  width: var(--icon-small);
  height: var(--icon-small);
  cursor: pointer;
  transition: all 0.3s;
}

.rotatedIcon {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.menu {
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--adminGreyNormal);
  border-radius: 0.5rem;
  box-shadow: var(--shadowMD);
  padding: 0.5rem;
  position: absolute;
  top: 100%;
}

.menuItem {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  padding: 0.5rem;
  text-wrap: nowrap;
}

.menuItem:hover {
  background-color: var(--adminGreyNormal);
  border-radius: 0.5rem;
}

.menuIcon {
  width: var(--icon-regular);
  height: var(--icon-regular);
  color: var(--black);
}
