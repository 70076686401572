.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 2rem 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 30vw;
  margin-bottom: 1.5rem;
}

.input {
  height: 40px;
}

.inputTitle {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 4px;
}

.changePasswordBtn {
  max-width: fit-content;
  background-color: var(--white);
  border: 1px solid var(--adminOrange600);
  border-radius: 0.5rem;
  color: var(--adminOrange600);
  font-weight: var(--font-weight-semi);
  padding: 0.5rem 1.5rem;
}

.changePasswordBtn:hover {
  background-color: transparent;
  border-color: var(--adminOrange700);
  color: var(--adminOrange700);
}

.saveBtn {
  background-color: var(--adminOrange600);
  border: none;
  border-radius: 0.5rem;
  color: var(--white);
  font-weight: var(--font-weight-semi);
  padding: 0.5rem 1.5rem;
}

.saveBtn:hover {
  background-color: var(--adminOrange700);
}

.saveBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}
