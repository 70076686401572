.filtersWrapper {
  max-width: 575px;
  overflow: visible;
}

.option {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--secondary500);
  border-radius: 2rem;
  color: var(--grey800);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  padding: 0.5rem 1rem;
  user-select: none;
}

.checkboxStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
  margin-right: 0.5rem;
}

.option.selectedOption {
  background-color: var(--secondary50);
}

.filterTitle {
  margin-top: 1rem;
  border-top: 1px solid var(--grey300);
  color: var(--grey800);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding-top: 1.5rem;
}

.filterOptions {
  display: flex;
  gap: 1.5rem;
  margin-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.selectProfile {
  margin-top: 1rem;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
  align-items: center;
  border-top: 1px solid var(--grey300);
  padding-top: 1rem;
}

.resetActionBtn,
.actionBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  padding: 0.5rem 1.5rem;
}

.resetActionBtn:hover {
  background-color: var(--grey100);
}

.actionBtn {
  background-color: var(--primary500);
  border: 1px solid var(--primary500);
  color: var(--white);
}

@media screen and (max-width: 768px) {
  .filterOptions {
    flex-direction: column;
    width: fit-content;
  }
}