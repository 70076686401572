.wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 220px);
  padding: 2rem 1.5rem;
}

.searchInput {
  width: 35%;
  height: 40px;
  border: 1px solid var(--adminGreyNormalActive);
  border-radius: 4px;
  margin-bottom: 1.5rem;
  padding-inline: 1rem;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: calc(100vh - 340px);
  margin-bottom: 1.5rem;
  overflow: hidden;
  overflow-y: auto;
}

.listWrapper::-webkit-scrollbar {
  display: none;
}

.pagination {
  margin-top: auto;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 2;
}
