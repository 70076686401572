.templatesWrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 24px;
  row-gap: 20px;
}

.templatesWrapper:first-child {
  row-gap: 12px;
}

.templateAction {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.template {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: calc(100% * (1 / 2) - 60px);
  height: 280px;
  background-color: var(--white);
  border: 1px solid var(--primary300);
  border-radius: 10px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  flex: 1 0 40%;
  overflow: hidden;
  padding: 24px;
  word-break: break-all;
}

.longTemplate {
  overflow-y: scroll;
}

.longTemplate::-webkit-scrollbar {
  width: 5px;
}

.longTemplate::-webkit-scrollbar-thumb {
  background: var(--grey300);
  border-radius: 4px;
}

.addTemplateBtn {
  display: none;
  background-color: transparent;
  border: 1px solid var(--primary500);
  color: var(--primary500);
}

.addTemplateBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

@media screen and (max-width: 1024px) {
  .template {
    max-width: calc(100% * (1 / 2) - 65px);
    flex: 1 0 40%;
  }

  .templatesWrapper {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 450px) {
  .templateAction {
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .templatesWrapper {
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-wrap: nowrap;
  }

  .templatesWrapper::-webkit-scrollbar {
    display: none;
  }

  .addTemplateBtn {
    display: flex;
    gap: 8px;
    border-radius: 32px;
  }

  .longTemplate {
    overflow-y: scroll;
  }

  .template {
    justify-content: space-between;
    max-width: 100%;
    height: 100%;
    flex: 1 0 35%;
  }
}
