.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px;
  align-items: center;
  height: calc(100vh - 96px);
  background-color: white;
  border: 1px solid var(--grey300);
  border-radius: 12px;
  padding: 24px;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  text-align: center;
}

.subtitle {
  color: var(--grey600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
  margin-bottom: 24px;
  text-align: center;
  white-space: pre-wrap;
}

.actions {
  display: flex;
  gap: 16px;
}

.btn {
  background-color: transparent;
  color: var(--primary500);
}

.btn:hover {
  background-color: transparent;
  color: var(--primary500);
}

@media screen and (max-width: 450px) {
  .actions {
    flex-direction: column;
  }
}
