.modalContent {
  max-width: 35vw;
}

.modalBody {
  color: var(--grey800);
  padding-block: 40px;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  width: 100%;
}

.cancelBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
}

.cancelBtn:hover {
  background-color: var(--grey100);
}

.invoiceData {
  margin-block: 2.5rem;
}

.basicData {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 0.25rem;
}

.addressData {
  color: var(--grey600);
  line-height: var(--line-height-15);
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 2;
}
