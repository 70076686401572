.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.form {
  width: clamp(360px, 50%, 600px);
  background-color: var(--white);
  border-radius: 1.125rem;
  box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05), 0px 4px 16px 0px rgba(47, 47, 141, 0.09);
  padding: 45px 30px 26px 44px;
}

.title,
.description {
  color: var(--grey900);
  margin-bottom: 1rem;
  text-align: center;
}

.title {
  font-size: var(--display-xs);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-12);
}

.adminForm {
  max-width: 40%;
  background-color: var(--white);
  border-radius: 1.125rem;
  box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05), 0px 4px 16px 0px rgba(47, 47, 141, 0.09);
  padding: 45px 30px 26px 44px;
}

.description {
  color: var(--grey800);
  line-height: var(--line-height-15);
  margin-bottom: 2.5rem;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 42px 0 14px;
  max-width: 60%;
  margin-left: auto;
}

.backButton {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  flex: 1;
  font-weight: var(--font-weight-medium);
  padding: 10px 24px;
}

.backButton:hover {
  background-color: var(--grey100);
}

.remindPasswordButton {
  flex: 1;
  font-weight: var(--font-weight-medium);
  padding: 10px 24px;
}

.loading {
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

.successWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.successTitle {
  margin-top: 1.5rem;
  max-width: 80%;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 2rem;
  text-align: center;
}

.successBtn {
  width: 320px;
  margin-bottom: 56px;
  padding: 12px 24px;
}

.successIconWrapper {
  background-color: var(--primary200);
  border: 8px solid var(--primary50);
  border-radius: 50%;
  color: var(--primary500);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.successIconWrapper>* {
  width: var(--icon-medium);
  color: var(--primary500);
  transform: translate(3px, 5px);
}

@media screen and (max-width: 1024px) {
  .form {
    margin-top: 40px;
  }

  .actions {
    max-width: 100%;
  }

  .wrapper {
    height: calc(100vh - 60px);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-height: calc(100svh - 80px);
    padding-inline: 1rem;
  }

  .actions {
    margin-top: 40px;
    max-width: none;
  }

  .form {
    max-width: 80%;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    justify-content: center;
  }

  .title {
    font-size: var(--font-size-xl);
  }

  .description {
    width: 100%;
    max-width: 100%;
    font-size: var(--font-size-md);
  }

  .actions {
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    align-items: center;
    margin-bottom: 0;
  }

  .backButton,
  .remindPasswordButton {
    width: 100%;
  }

  .form {
    margin-top: 1rem;
    width: 100%;
    max-width: none;
    background-color: transparent;
    box-shadow: none;
    box-sizing: border-box;
    padding: 0;
  }
}