.wrapper {
  height: calc(100vh - 230px);
  overflow: hidden;
}

.adminPreview {
  height: 100%;
  max-height: none;
}

.adminPreview .report {
  min-height: 60vh;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.generateButton {
  padding: 0px 20px;
}

.report {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 220px);
  overflow-y: hidden;
}

.list {
  height: 100%;
  flex: 0 1 30%;
  padding: 0 0.5rem 0 1.5rem;
}

.map {
  width: 100%;
  flex: 2;
}

@media screen and (max-width: 1024px) {
  .report {
    flex-direction: column-reverse;
    height: calc(100svh - 240px);
    overflow: hidden;
    overflow-y: auto;
  }

  .list {
    margin-top: 1.5rem;
    max-width: none;
    height: auto;
  }

  .map {
    height: 40vh;
    max-height: 40svh;
    min-height: 40svh;
    flex: auto;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    height: 100svh;
    overflow: hidden;
  }

  .list {
    flex: auto;
    padding-inline: 12px;
  }
}
@media screen and (max-width: 500px) {
  .list {
    height: auto;
    flex: auto;
    margin-bottom: 3rem;
    order: 2;
    padding-inline: 0px;
  }

  .map {
    width: 100%;
    height: 350px;
    min-height: 350px;
    box-sizing: border-box;
    flex: auto;
  }

  .report {
    flex-direction: column;
    height: calc(100svh - 120px);
    margin-bottom: 3rem;
    overflow-y: auto;
    padding: 0;
  }

  .report::-webkit-scrollbar {
    display: none;
  }
}
