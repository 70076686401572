.tooltipWrapper {
  display: inline-block;
  position: relative;
}

.tooltip {
  width: 150px;
  height: fit-content;
  background-color: var(--white);
  border-radius: 6px;
  bottom: calc(100% + 8px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  left: 50%;
  opacity: 0;
  padding: 6px 8px;
  position: absolute;
  text-align: left;
  transform: translateX(-50%);
  transition: opacity 0.2s ease;
  visibility: hidden;
  white-space: break-spaces;
  z-index: 3;
}

.tooltipWrapper:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.title {
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  text-align: left;
}
