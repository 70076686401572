.mobileNavWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  left: 0;
  margin-right: auto;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 2;
}

.mobileNavWrapper > ul {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  list-style: none;
  margin-block: 5rem;
}

.actionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
}

.mobileNavWrapper > ul > li > a {
  color: var(--white);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  text-wrap: nowrap;
}

.mobileNavWrapper > li:hover {
  border-bottom: 1px solid var(--white);
  padding-bottom: 2px;
}

.button {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}

.button:hover {
  background-color: transparent;
  color: var(--grey300);
}

.signUpBtn {
  background-color: var(--white);
  border-radius: 0.5rem;
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  padding: 0.75rem 2rem;
}

.signUpBtn:hover {
  background-color: var(--primary50);
  color: var(--primary500);
}
