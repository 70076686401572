.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  animation-duration: 0.3s;
  animation-name: open;
  background-color: white;
  border-radius: 4px 0px 0px 4px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  line-break: auto;
  overflow: hidden;
  padding: 14px;
  position: fixed;
  right: 30px;
  text-wrap: wrap;
  top: 30px;
  white-space: pre-wrap;
  word-break: break-all;
  z-index: 100;
}

.wrapperError {
  background-color: var(--red50);
  border-left: 8px solid var(--red500);
  color: var(--white);
}

.wrapperSuccess {
  background-color: var(--green50);
  border-left: 8px solid var(--green500);
  color: var(--white);
}

.wrapper.display {
  animation-duration: 5s;
  animation-name: display;
}

.wrapper.close {
  animation-duration: 0.3s;
  animation-name: close;
  right: -375px;
}

.close {
  margin: auto;
  align-items: center;
  width: 16px;
  height: 16px;
  color: var(--grey500);
  cursor: pointer;
  justify-self: flex-end;
  margin-left: 20px;
}

.close:hover {
  fill: var(--grey600);
}

.icon {
  width: var(--icon-regular);
  height: var(--icon-regular);
}

.body {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: var(--line-height-16);
  white-space: pre-wrap;
}

.bodyError {
  color: var(--red500);
}

.bodySuccess {
  color: var(--green500);
}

.wrapperWarning {
  background-color: var(--yellow50);
  border-left: 8px solid var(--yellow500);
  color: var(--white);
}

.bodyWarning {
  color: var(--yellow800);
}

.bodyWarning .icon {
  animation: bell 0.5s infinite;
}

@keyframes bell {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes open {
  0% {
    right: -375px;
  }

  100% {
    right: 30px;
  }
}

@keyframes display {
  0% {
    right: 30px;
  }

  100% {
    right: 30px;
  }
}

@keyframes close {
  0% {
    right: 30px;
  }

  100% {
    right: -375px;
  }
}
