.relatedItemAddress {
  color: var(--grey600);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-15);
}

.relatedItemTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: var(--fot-size-xs);
  font-weight: var(--font-weight-medium);
  -webkit-line-clamp: 2;
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
}

.relatedItem {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.image {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 12px;
  object-fit: fill;
}

.wrapper {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  max-width: 60%;
}

.relatedItemImage {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 12px;
  object-fit: fill;
}

.noImage {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--grey100);
  box-sizing: border-box;
  color: var(--grey500);
  padding: 4px;
}