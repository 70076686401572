.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--grey50);
  border-top: 1px solid #ebf2fe;
  box-shadow: 0px 4px 8px -2px #1018281a;
  box-sizing: border-box;
  margin-bottom: 24px;
  padding: 14px 24px;
}

.userActions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--primary500);
  color: var(--primary500);
  cursor: pointer;
  font-size: var(--font-size-sm);
  justify-self: flex-end;
  margin-left: auto;
  padding: 0.5rem 1.5rem;
}

.readOnly {
  background-color: var(--grey50);
  pointer-events: none;
}

.field:has(.readOnly).field {
  background-color: var(--grey50);
  pointer-events: none;
  user-select: none;
}

.button:hover {
  background-color: var(--white);
  border-color: var(--primary600);
  color: var(--primary600);
}

.button:disabled {
  background-color: transparent;
  opacity: 0.6;
  pointer-events: none;
  user-select: none;
}

.fields {
  display: grid;
  gap: 6px;
  width: 100%;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  pointer-events: none;
  user-select: none;
}

.fieldsWithAddress {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.editFilters.field {
  background: var(--white, #fff);
  border: 1px solid var(--grey300);
  border-radius: 8px;
}

.fields.editFilters {
  width: 100%;
  border-radius: 0;
  pointer-events: all;
}

.label {
  width: 100%;
  color: var(--grey700);
  font-size: 12px;
  line-height: var(--line-height-16);
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.value {
  width: 100%;
  color: var(--grey900);
  font-size: 16px;
  line-height: var(--line-height-15);
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-left: 1px solid var(--grey200);
  border-right: 1px solid transparent;
  box-sizing: border-box;
  padding: 4px 16px;
  position: relative;
  text-overflow: ellipsis;
}

.actionsWrapper {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  justify-content: flex-end;
  align-self: center;
  box-sizing: border-box;
  margin-left: auto;
}

.field.leftFocused {
  border-left-color: transparent;
}

.changeComponentWrapper {
  max-height: 350px;
  background-color: var(--white);
  border: 1px solid var(--primary500);
  border-radius: 8px;
  box-shadow: 0px 5px 20px rgba(127, 122, 122, 0.1);
  padding: 24px;
  position: absolute;
  top: calc(100% + 5px);
  z-index: 20;
}

.changeComponentWrapper:last-of-type {
  right: 0;
}

.keywordInput {
  width: 100%;
  background-color: transparent;
  border: none;
  color: var(--grey900);
  font-size: var(--font-size-regular);
  line-height: var(--line-height-15);
  outline: none;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adminPreview {
  margin-bottom: 1rem;
  padding-top: 0;
}
@media screen and (max-width: 1200px) {
  .fields {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .fieldsWithAddress {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .changeComponentWrapper {
    margin: auto;
    width: 60%;
    min-width: 350px;
    inset: 0;
    position: fixed;
    transform: translate(0, 15%);
  }

  .fieldsWithAddress {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media screen and (max-width: 450px) {
  .wrapper {
    display: none;
  }
}
