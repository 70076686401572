.wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1.5rem 1.5rem 1.25rem 1.5rem;
}

.info {
    display: flex;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.title {
    color: var(--black);
    font-size: var(--font-size-md-lg);
    font-weight: var(--font-weight-semi);
    line-height: var(--line-height-16);
}

.closeIcon {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    margin-left: auto;
    padding: 0.5rem;
}

.btn {
    margin: 1.5rem 1.5rem 1.5rem 1.5rem;
    background-color: var(--adminOrange600);
    border-radius: 0.5rem;
    flex-shrink: 0;
    overflow: hidden;
    padding: 0.5rem 1.5rem;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.btn:hover {
    background-color: var(--adminOrange700);
}

.modal {
    display: flex;
    flex-direction: column;
    max-width: 50vw;
    min-width: 50vw;
    max-height: 25vh;
    min-height: 25vh;
    background-color: var(--white);
    border: 1px solid var(--grey100);
    border-radius: 18px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
