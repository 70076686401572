.header {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: flex-start;
}

.headerSubtitle {
  margin: 12px auto;
  color: var(--grey700);
  line-height: var(--line-height-15);
}

.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  margin-top: 12px;
}

.buttonSmall {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  height: fit-content;
  padding: 10px 24px;
}

.iconStyle {
  display: flex;
  width: 20px;
  height: 20px;
  align-self: center;
  stroke: var(--white);
  stroke-width: 2px;
}

.postType {
  display: flex;
  gap: 32px;
}

.tabBtn {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  box-sizing: border-box;
  color: var(--grey600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  text-wrap: nowrap;
}

.activeTabStyle,
.tabBtn:hover {
  background-color: transparent;
  border-bottom: 2px solid var(--primary500);
  color: var(--primary500);
}

.tabBtn:first-of-type {
  margin-left: 0;
  padding-left: 0;
}

.adminPreview .rowContainer {
  margin-top: 0;
}

@media screen and (max-width: 550px) {
  .iconStyle {
    display: none;
  }

  .buttonSmall {
    padding: 6px 18px;
  }
}
