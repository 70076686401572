.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  border-radius: 0.5rem;
  box-shadow: var(--shadowMD);
  padding: 1.5rem 2rem;
}

.businessCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 50%;
  overflow: hidden;
  padding-right: 2rem;
  text-wrap: nowrap;
}

.businessCardText {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.businessCardName,
.cardName {
  width: 100%;
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.businessCardAddress,
.cardAdress {
  width: 100%;
  color: var(--adminGreyDarker);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.cardAdress {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.showBusinessCardBtn {
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  flex-shrink: 0;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
}

.showBusinessCardBtn:hover {
  background-color: var(--adminOrange700);
}

.seoCardPill {
  background-color: var(--adminOrange300);
  border-color: var(--adminOrange300);
  border-radius: 5rem;
  color: var(--black);
  flex-shrink: 0;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-12);
  padding: 6px 12px;
}

.rightSide {
  display: flex;
  gap: 3rem;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.showDetailsBtn {
  background-color: var(--adminOrange600);
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.showDetailsBtn:hover {
  background-color: var(--adminOrange700);
}

.inactiveCard {
  background-color: var(--adminGreyNormalActive);
  border: none;
}

.connectedCard {
  min-width: 30%;
}
