.wrapper {
  max-width: 30rem;
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
  border-radius: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  padding: 1.5rem;
}

.featureTitle {
  display: flex;
  gap: 1rem;
  margin: 0 0 1rem;
  align-items: center;
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.icon {
  width: 20px;
  height: 20px;
}

.selectedFeature {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.featureDescription {
  margin: 0 0 2.5rem;
  color: var(--grey900);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
  overflow: hidden;
  transition: height 0.3s ease;
}

.mobileWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
  border-radius: 0.5rem;
  padding: 1.5rem;
}