.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--grey50);
  border: 1px solid var(--grey100);
  border-radius: 1.125rem;
  box-shadow: 0px 1px 2px 0px #1018280d;
  box-sizing: border-box;
  padding: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  color: var(--grey900);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  margin-bottom: 1rem;
}

.payuCardForm {
  background-color: var(--white);
  border: 1px solid var(--grey300);
  border-radius: 6px;
  font-size: 0.5rem;
  margin-bottom: 1rem;
  padding: 6px;
}

.errorMessage {
  display: flex;
  align-items: center;
  color: var(--red600);
  font-size: var(--font-size-sm);
}

.errorIcon {
  width: 1rem;
  color: var(--red600);
  margin-right: 5px;
}

.btn {
  width: fit-content;
  margin-left: auto;
  padding: 10px 24px;
}

.separator {
  height: 1px;
  background-color: var(--grey200);
}

.loader {
  height: 100vh;
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

.subinformation {
  display: flex;
  align-items: flex-start;
}

.aboutPayu {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}

.payuLogo {
  width: 5rem;
}

.seoLogo {
  width: 6rem;
}

.logosWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
}

.separatorA {
  width: 2px;
  height: 3rem;
  background-color: var(--grey300);
}

.littleText {
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
}

@media screen and (max-width: 450px) {
  .btn {
    width: 100%;
  }

  .container {
    padding: 1rem;
  }
}
