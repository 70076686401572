.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white);
  border-radius: 8px;
  padding: 24px;
}

.standAloneWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px;
  height: 100%;
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .standAloneWrapper {
    justify-content: flex-start;
    margin: 0;
    height: calc(100vh - 64px);
    border-radius: 0;
  }
}

@media screen and (max-width: 500px) {
  .standAloneWrapper {
    height: 90svh;
  }

  .wrapper {
    padding: 0;
  }
}
