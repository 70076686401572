.modalContent {
  display: flex;
  max-width: 60vw;
  height: 100%;
  max-height: 85vh;
  overflow-y: auto;
}

.customCloseStyle {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
}

.customCloseStyle:hover {
  background-color: transparent;
}

.titleStyle {
  width: 100%;
  border-bottom: 1px solid var(--adminGreyNormal);
  padding-bottom: 1rem;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
  z-index: 2;
}
