.viewContainer {
  display: block;
  height: 100vh;
}

.wrapper {
  height: calc(100vh - 190px);
  overflow-y: auto;
  padding: 2rem 1.5rem;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.rowContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: fit-content;
  border-bottom: 1px solid var(--adminGreyNormalHover);
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}

.accountInfoWrapper {
  margin-top: 1.5rem;
  max-width: 50%;
  height: fit-content;
  max-height: fit-content;
}

.saveBtn {
  background-color: var(--adminOrange600);
  border: none;
  border-radius: 0.5rem;
  color: var(--white);
  font-weight: var(--font-weight-semi);
  padding: 0.5rem 1.5rem;
}

.saveBtn:hover {
  background-color: var(--adminOrange700);
}

.saveBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
}

.desactivateAccountBtn {
  background-color: var(--transparent);
  border: 1px solid var(--adminRed500);
  border-radius: 8px;
  color: var(--adminRed500);
  padding: 0.5rem 1.5rem;
}

.desactivateAccountBtn:hover {
  background-color: var(--transparent);
  border-color: var(--adminRed600);
  color: var(--adminRed600);
}
