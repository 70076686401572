.title {
  margin: 0;
  color: var(--grey900);
  font-size: var(--display-sm);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-12);
  text-align: center;
}

.subTitle {
  margin: 2rem auto 5.25rem;
  max-width: 740px;
  color: var(--grey900);
  font-size: var(--font-size-lg);
  line-height: var(--line-height-15);
  text-align: center;
}

.priceModelsWrapper {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin-inline: 50px;
}

.previewWrapper {
  margin-inline: 0;
}

.plansMatrix {
  display: flex;
  margin-inline: 1rem;
}

@media screen and (max-width: 1440px) {
  .priceModelsWrapper {
    display: grid;
    width: fit-content;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    margin-inline: auto;
    row-gap: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper > div {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .subTitle {
    margin: 1rem auto 2rem;
    font-size: var(--font-size-md-lg);
  }

  .wrapper > div {
    padding: 0;
  }

  .plansMatrix {
    margin: 0;
  }

  .heading {
    margin-inline: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .priceModelsWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-inline: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .wrapper > div {
    padding: 0;
  }

  .plansPreviewMobile {
    left: -1rem;
    position: relative;
  }

  .priceModelsWrapper {
    margin-inline: 0;
  }

  .title {
    font-size: var(--font-size-xl);
  }

  .subTitle {
    margin: 1rem 0 2rem;
    font-size: var(--font-size-md);
  }
}
