.content {
  max-width: 40%;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-block: 24px;
}

.modalActions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 24px;
}

.cancelBtn {
  background-color: transparent;
  color: var(--primary500);
}

.cancelBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.confirmBtn {
  padding-inline: 24px;
}

@media screen and (max-width: 1200px) {
  .content {
    max-width: 60%;
  }
}
@media screen and (max-width: 762px) {
  .content {
    max-width: 100%;
  }
}
