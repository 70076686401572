.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 30rem;
  border-radius: 0.75rem;
  box-shadow: var(--shadowMD);
  padding: 2rem;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
