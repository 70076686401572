.mediaDetailWrapper {
  display: flex;
  gap: 8px;
}

.fileName {
  flex: 1;
}

.select {
  flex: 0.6;
}

@media screen and (max-width: 450px) {
  .mediaDetailWrapper {
    flex-direction: column;
    border: 1px solid var(--primary300);
    border-radius: 8px;
    padding: 8px;
  }

  .fileName {
    flex: 1;
  }

  .select {
    flex: 1;
  }
}