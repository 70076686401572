.icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  stroke-width: 2px;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--primary500);
  border: none;
  border-radius: 25px;
  color: var(--white);
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 12px;
  user-select: none;
}

.button:hover {
  background-color: var(--primary600);
}

.button:disabled {
  background-color: var(--primary300);
  cursor: auto;
}

.button:disabled .icon,
.button:disabled .label {
  cursor: auto;
}

.label {
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  text-wrap: nowrap;
}

.label:disabled {
  cursor: auto;
}

.button:disabled:active {
  cursor: auto;
  filter: none;
}
