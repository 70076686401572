.tableWrapper {
  padding-inline: 1.5rem;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: absolute;
  z-index: 1;
}

.pill {
  background-color: var(--adminGreyNormalActive);
  border: none;
  border-radius: 20px;
  color: var(--black);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 6px 12px;
}

.activeStatusPill {
  background-color: var(--adminOrange400);
}

.tableCustomBody {
  display: block;
  overflow: hidden;
  overflow-y: auto;
}

.select {
  width: 200px;
  max-width: 100px;
  background-color: var(--white);
  border: 1px solid var(--adminGreyNormalActive);
  border-radius: 8px;
  flex-grow: 0;
  font-family: var(--manrope);
}

.selectLabelStyle {
  color: var(--adminGreyDarkActive);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

