.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: calc(100vh - 20rem);
}

.title {
  color: var(--black);
  font-size: var(--font-size-regualar);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}
