.relatedSubscriptions {
  border-top: 1px solid var(--grey200);
  margin-block: 1rem;
  padding-top: 1rem;
}

.relatedSubscriptionsTitle {
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 1rem;
}

.relatedItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--grey50);
  border-radius: 0.5rem;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: 12px 14px;
  row-gap: 1rem;
}

.relatedBtn {
  background-color: transparent;
  color: var(--primary500);
  font-size: var(--font-size-xs);
}

.relatedBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}