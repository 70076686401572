.labelFileUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: clamp(120px, 20vw, 100%);
  border: 2px var(--primary200) dashed;
  border-radius: 8px;
}

.inputFileUpload {
  display: none;
}

.uploadButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  margin-inline: auto;
  outline: none;
  position: relative;
}

.dragAndDropTitle {
  border: 1px solid var(--primary500);
  border-radius: 2rem;
  color: var(--primary500);
  font-size: var(--font-size-regular);
  font-weight: 400;
  padding: 6px 12px;
}

.dragAndDropTitle:hover {
  background-color: transparent;
  color: var(--primary600);
}

.dragActive {
  background-color: var(--white);
}

.previewContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.imageCounter {
  display: flex;
  width: fit-content;
  bottom: 0;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  position: absolute;
  right: 0;
}

.alternateUpload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  background-color: var(--primary50);
  border: 1px solid var(--primary200);
  border-radius: 6px;
  color: var(--primary500);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.dragAndDropSupportingText {
  margin-top: 6px;
  width: 100%;
  font-size: var(--font-size-small);
}

.dragAndDropSupportingText:hover {
  color: var(--grey800);
  cursor: pointer;
}

.previewImages {
  display: grid;
  gap: 12px;
  justify-content: center;
  width: 100%;
  grid-template-columns: repeat(3, calc(100% / 3 - 24px));
  margin-inline: 12px;
  position: relative;
  row-gap: 12px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .previewContainer {
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    margin: 0 8px;
    align-items: center;
  }

  .imageCounter {
    left: 90%;
  }

  .labelFileUpload {
    flex-direction: column;
    height: 100%;
  }

  .previewImages {
    display: grid;
    gap: 12px;
    justify-content: center;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    margin-inline: 12px;
  }
}

@media screen and (max-width: 450px) {
  .previewImages {
    display: flex;
    flex-direction: column;
  }
}
