.toolsWrapper {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.searchInput {
  max-width: 40%;
  height: 50px;
  border: 1px solid var(--grey300);
  border-radius: 42px;
  color: var(--grey600);
  margin-right: 0;
}

.adminSearchInput {
  width: 35%;
  height: 2.5rem;
  max-height: 2.5rem;
  border: 1px solid var(--adminGreyNormalActive);
  border-radius: 8px;
}

.filterButton {
  gap: 0.75rem;
  background-color: var(--grey100);
  border: 1px solid var(--grey500);
  color: var(--grey900);
  padding: 0.75rem 1.5rem;
}

.filterButton:hover {
  background-color: var(--grey200);
}

.adminBtn,
.adminFilterBtn,
.adminResetBtn {
  display: flex;
  gap: 0.5rem;
  width: fit-content;
  height: 40px;
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
}

.adminFilterBtn {
  background-color: var(--adminOrange500);
  padding: 0.5rem 1.5rem;
}

.adminFilterBtn:hover {
  background-color: var(--adminOrange600);
}

.addCardBtn:hover {
  background-color: var(--adminOrange700);
}

.adminResetBtn:hover {
  background-color: var(--adminGreyNormalActive);
}

.adminResetBtn:disabled,
.adminFilterBtn:disabled {
  background-color: var(--adminGreyNormalHover);
  color: var(--adminGreyDarkHover);
  pointer-events: none;
}

.modalContent {
  max-width: 50vw;
}

.optionWrapper {
  display: flex;
  gap: 1.5rem;
  border-bottom: 1px solid var(--grey300);
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
  padding-bottom: 1rem;
  text-wrap: nowrap;
}

.key {
  color: var(--grey800);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 0.75rem;
}

.option {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--secondary500);
  border-radius: 2rem;
  color: var(--grey800);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  padding: 0.5rem 1rem;
  user-select: none;
}

.checkboxStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
}

.closeIcon {
  width: 1rem;
  height: 1rem;
  color: var(--grey600);
}

.option.selectedOption {
  background-color: var(--secondary50);
}

.actionsWrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-top: 1rem;
}

.resetBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  padding: 0.5rem 1.5rem;
}

.resetBtn:hover {
  background-color: var(--grey100);
}

.filterBtn {
  padding: 0.5rem 1.5rem;
}

.adminOption {
  border-color: var(--grey400);
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
}

.adminKey {
  color: var(--black);
  font-weight: var(--font-weight-semi);
}

.adminOption.selectedOption {
  background-color: var(--adminOrange500);
  color: var(--white);
}

.adminCalendarBtn {
  background-color: var(--white);
  border-radius: 0.5rem;
}

@media screen and (max-width: 1240px) {
  .modalContent {
    max-width: 80vw;
  }

}

@media screen and (max-width: 768px) {
  .searchInput {
    max-width: 60%;
  }
}

@media screen and (max-width: 450px) {
  .searchInput {
    max-width: 100%;
  }

  .filterButton {
    width: 100%;
    padding: .5rem 1.5rem;
  }

  .toolsWrapper {
    row-gap: .75rem;
  }

  .actionsWrapper {
    flex-direction: column;
  }

  .actionsWrapper>* {
    width: 100%;

  }
}