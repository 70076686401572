.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: hsl(210, 20%, 98%);
  border: 1px solid var(--grey200);
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 16px;
}

.title {
  max-width: 100%;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
}

.list::-webkit-scrollbar:vertical {

  display: none;
}

.list::-webkit-scrollbar {
  width: 8px;
}

.list::-webkit-scrollbar-track {
  background: var(--grey100);
}

.list::-webkit-scrollbar-thumb {
  background: var(--grey300);
  border-radius: 8px;
}

.headerCell {
  display: flex;
  flex-direction: row;
  color: var(--grey600);
  cursor: pointer;
  overflow: hidden;
  padding-left: 4px;
  padding-right: 4px;
}

.sortIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 10px;
  margin-left: 8px;
}

.sortIcon.rotated {
  transform: rotateZ(180deg);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--grey200);
  border-left: 1px solid var(--grey200);
  cursor: pointer;
  padding-block: 20px;
  padding-left: 16px;
  white-space: pre-wrap;
}

.row:hover {
  background-color: var(--grey100);
}

.row:last-of-type {
  border-bottom: 1px solid var(--grey100);
  margin-bottom: 2px;
}

.cell {
  color: var(--grey800);
  font-size: 14px;
  overflow: hidden;
  padding-left: 4px;
  padding-right: 4px;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--grey600);
  font-size: var(--font-size-lg);
  line-height: var(--line-height-24);
}