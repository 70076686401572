.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sortContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  align-self: flex-end;
  margin-bottom: 8px;
}

.sortSelect {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 6px 16px;
}

.spaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.informationBox {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--primary800);
  font-size: 14px;
}

.buttonSmall {
  display: none;
  flex-direction: row;
  gap: 10px;
  max-height: 45px;
  align-self: center;
  background-color: transparent;
  border: none;
  color: var(--primary500);
  font-weight: 500;
  padding: 6px 12px;
  stroke: var(--white);
}

.buttonSmall:hover {
  background-color: transparent;
  color: var(--primary600);
}

.body {
  display: grid;
  gap: 1.25rem;
  grid-template-columns: repeat(auto-fill, minmax(490px, 1fr));
  grid-template-rows: auto;
  margin-block: 1.5rem;
}

.loaderOverlay {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
  top: 0;
  z-index: 100;
}

@media screen and (max-width: 1920px) {
  .body {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .body {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 550px) {
  .body {
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 450px) {
  .sortContainer {
    justify-content: flex-end;
    box-sizing: border-box;
  }

  .sortSelect {
    margin-top: 24px;
    padding: 6px 16px;
  }

  .body {
    gap: 16px;
    row-gap: 16px;
  }

  .container {
    margin-top: 0;
    overflow-y: auto;
  }

  .container::-webkit-scrollbar {
    display: none;
  }

  .buttonSmall {
    display: block;
  }
}