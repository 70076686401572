.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 270px);
}

.headingStyle {
  margin-top: 16px;
  font-size: 20px;
  font-weight: 500;
}

.button {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 32px;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 24px;
  stroke: var(--white);
}

.iconStyle {
  margin: 0;
  stroke-width: 1.5px;
}

@media screen and (max-width: 450px) {
  .wrapper {
    justify-content: center;
    transform: translateY(0%);
  }
}
