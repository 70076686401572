.landingPage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

.landingPage::-webkit-scrollbar {
  display: none;
}

.landingPage > * {
  user-select: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  user-select: text;
}
