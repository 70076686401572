.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 2rem;
  align-items: center;
}

.navigateLanding {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  color: var(--primary500);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
}

.icon {
  width: 18px;
  height: 18px;
}

.priceModelsWrapper {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 2rem;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.loaderWrapper {
  height: auto;
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}

.moveDown {
  margin-top: 5rem;
}

.profileDetailsWrapper {
  max-width: 65%;
}

.priceModelsWrapper.trailInOffer>*,
.priceModelsWrapper>* {
  max-width: 320px;
  flex: 1 1 calc(25% - 1.5rem);
}

@media screen and (max-width: 1440px) {
  .priceModelsWrapper>*,
  .priceModelsWrapper.trailInOffer>* {
    max-width: calc(45% - 1.5rem);
    flex: 1 1 calc(45% - 1.5rem);
  }

  .rowContainer {
    flex-wrap: wrap;
  }

}

@media screen and (max-width: 1024px) {
  .rowContainer {
    flex-wrap: wrap;
  }

  .priceModelsWrapper>*,
  .priceModelsWrapper.trailInOffer>* {
    max-width: none;
  }
}

@media screen and (max-width: 600px) {
  .profileDetailsWrapper {
    max-width: none;
  }

  .priceModelsWrapper>*,
  .priceModelsWrapper.trailInOffer>* {
    display: flex;
    width: 100%;
    max-width: 90vw;
    flex: 1 1 auto;
  }
}