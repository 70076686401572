.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: space-between;
  align-items: flex-start;
}

.offerName {
  margin: 24px 0 0 0;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dateInput {
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.wrapperStyle {
  margin-top: 16px;
}

.expandableSectionContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  width: 100%;
  padding: 0;
}

.expandableSectionContainer:first-child {
  margin-top: 16px;
}

.offerDescription {
  min-height: 150px;
  color: var(--grey900);
  font-size: var(--font-size-md);
  line-height: var(--line-height-15);
  padding-block: 0.5rem;
  position: relative;
}

.noMargin {
  margin: 0;
}

.error {
  margin: 0;
  color: var(--red500);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.body:has(.error) .rowContainer .dateInput {
  margin-bottom: 4px;
}

.aiHelperContainer {
  position: relative;
}

.aiHelperWrapper {
  padding: 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.aiHelperContainerStatic {
  padding: 0;
  position: static;
}
@media screen and (max-width: 450px) {
  .rowContainer {
    flex-direction: column;
    gap: 15px;
  }
}
