.wrapper {
  width: 100%;
}

.sectionName {
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 14px;
}

.inputSelection {
  width: 100%;
  border-radius: 8px;
}

.inputSelect {
  margin: 0;
  width: 100%;
  height: 54px;
  box-shadow: none;
  padding: 0;
}

.horizontalLine {
  margin: 24px auto;
  width: 100%;
  border-bottom: 1px solid var(--grey300);
}

.profileNotVerified {
  margin-top: 12px;
  color: var(--secondary500);
  font-size: var(--font-size-sm);
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 2;
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
    box-sizing: border-box;
  }
}
