.emptyContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin: 24px;
  overflow: hidden;
}

.button {
  display: flex;
  gap: 8px;
  margin: 0 auto;
  align-items: center;
  color: var(--white);
  font-weight: 500;
  padding: 6px 16px;
}

.headingStyle {
  margin-top: 1rem;
  background-color: transparent;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  text-align: center;
}

.headingStyle:hover {
  background-color: transparent;
  color: var(--grey900);
}

.button:hover {
  background-color: var(--primary600);
}
