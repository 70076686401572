.tableSeoCardList {
  margin-top: 2rem;
  max-height: calc(100vh - 22rem);
  margin-bottom: 1rem;
  overflow: auto;
}

.tableSeoCardList::-webkit-scrollbar:vertical {
  display: none;
}

.tableSeoCardList thead,
.tableSeoCardList tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableSeoCardList thead {
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableBody {
  width: 100%;
  overflow: hidden;
}

.mobileList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-block: 2rem;
}

@media screen and (max-width: 1024px) {
  .tableSeoCardList {
    max-height: calc(100vh - 23.75rem);
  }
}

@media screen and (max-width: 450px) {
  .tableSeoCardList {
    max-height: calc(100svh - 28rem);
  }
}