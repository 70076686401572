.sectionWrapper {
  margin-top: 10rem;
}

.sectionTitle {
  display: flex;
  justify-content: center;
  margin: 0 0 68px;
  align-items: center;
  font-size: var(--display-sm);
  font-weight: var(--font-weight-bold);
}

.cardCollectionWrapper {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: stretch;
  max-width: 80vw;
  flex-wrap: wrap;
  margin-bottom: 8.25rem;
  margin-inline: auto;
}

.singleCardWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 400px;
  min-width: 20rem;
  background-color: var(--white);
  border-radius: 14px;
  box-shadow: 0px 4px 12px 0px #00000014;
  box-sizing: border-box;
  flex: 0 0 25%;
  padding: 2rem;
}

.doYouKnow {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  align-items: center;
  background-color: var(--primary500);
  overflow: hidden;
  padding-block: 7.5rem;
  position: relative;
}

.doYouKnowTitle {
  margin: 0;
  color: var(--white);
  font-size: var(--display-md);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-12);
  text-align: center;
  white-space: break-spaces;
  z-index: 2;
}

.doYouKnowDescription {
  margin: 0;
  max-width: 45%;
  color: var(--white);
  font-size: var(--font-size-lg);
  line-height: var(--line-height-15);
  position: relative;
  text-align: center;
  white-space: break-spaces;
  z-index: 2;
}

.signUpBtn {
  width: fit-content;
  background-color: var(--white);
  border-radius: 0.5rem;
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  padding: 0.75rem 2rem;
  position: relative;
  z-index: 2;
}

.signUpBtn:hover {
  background-color: var(--primary50);
  color: var(--primary500);
}

.blurCircle {
  display: none;
  width: 30rem;
  height: 30rem;
  background-color: #96c6ff;
  border-radius: 50%;
  bottom: -50%;
  filter: blur(100px);
  left: -10%;
  opacity: 0.5;
  position: absolute;
  z-index: 1;
}

.blurCircle:nth-of-type(2) {
  display: block;
  bottom: auto;
  left: auto;
  right: -10%;
  top: -10%;
}
@media screen and (min-width: 2000px) {
  .cardCollectionWrapper {
    max-width: 60vw;
  }

  .doYouKnowDescription {
    max-width: 30%;
  }
}
@media screen and (max-width: 1440px) {
  .cardCollectionWrapper {
    max-width: none;
  }

  .singleCardWrapper {
    max-width: none;
    min-width: 25%;
  }

  .doYouKnowDescription {
    max-width: none;
  }
}

@media screen and (max-width: 768px) {
  .cardCollectionWrapper {
    flex-direction: column;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-inline: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .sectionWrapper {
    margin-top: 9rem;
  }

  .sectionTitle {
    margin-bottom: 40px;
  }

  .singleCardWrapper {
    max-width: none;
    min-width: 40%;
  }

  .cardCollectionWrapper {
    margin-bottom: 60px;
  }

  .doYouKnow {
    width: 100vw;
    box-sizing: border-box;
    padding-inline: 40px;
  }

  .doYouKnowTitle {
    font-size: var(--display-sm);
    white-space: initial;
  }

  .doYouKnowDescription {
    font-weight: var(--font-weight-regular);
  }
}
@media screen and (max-width: 450px) {
  .cardCollectionWrapper {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2.5rem;
    margin-inline: 0;
  }

  .sectionTitle {
    justify-content: flex-start;
    margin: 0 0 40px;
    font-size: var(--font-size-xl);
  }

  .sectionWrapper {
    margin-top: 4rem;
    padding-inline: 1rem;
  }

  .singleCardWrapper {
    gap: 0;
  }

  .doYouKnow {
    width: 100vw;
    box-sizing: border-box;
    left: -1rem;
    padding-inline: 1rem;
    position: relative;
  }

  .doYouKnowTitle {
    font-size: var(--font-size-lg);
    line-height: var(--line-height-15);
  }

  .doYouKnowDescription {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
  }

  .blurCircle {
    opacity: 0.3;
  }
}
