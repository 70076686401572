.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  width: 60%;
  border: 1px solid var(--grey200);
  border-radius: 1rem;
  box-shadow: 0px 0.5rem 0.5rem -0.25rem #10182808;
  padding: 2rem 1.5rem;
}

.headerWrapper {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--grey300);
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
}

.leftSideRow,
.planDetailsWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.pinIcon {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--primary100);
  border: 0.5rem solid var(--primary50);
  border-radius: 50%;
  color: var(--primary500);
  padding: 0.5rem;
}

.changeButton {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  font-size: var(--font-size-sm);
  margin-left: auto;
  padding: 0.5rem 1.25rem;
}

.changeButton:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.invoiceTitle {
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  margin-bottom: 0.5rem;
}

.address,
.contactData {
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}

.address {
  margin-bottom: 1rem;
}

.infoIcon {
  width: 1rem;
  height: 1rem;
}

.invoiceType {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  color: var(--grey800);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-15);
  margin-bottom: 1rem;
}

.editInvoiceBtn {
  gap: 0.25rem;
  background-color: transparent;
  color: var(--primary500);
  margin-left: auto;
}

.editInvoiceBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.editInvoiceBtnIcon {
  width: 1.125rem;
  height: 1.125rem;
}

@media screen and (max-width: 1440px) {
  .wrapper {
    width: auto;
  }

  .wrapper::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .leftSideRow {
    flex-direction: column;
    row-gap: 2rem;
  }

  .changeButton {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: auto;
    padding: 1rem;
  }


}