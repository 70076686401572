.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border: 1px solid var(--grey200);
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 4px 16px;
  position: relative;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  color: var(--grey700);
  font-size: var(--font-size-xs);
}

.placeholder {
  color: var(--grey800);
  line-height: var(--line-height-15);
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--primary500);
  padding-left: 2rem;
  stroke-width: 1.5px;
}

.adminIconColor {
  color: var(--adminOrange500);
}

.calendarWithUser {
  position: fixed;
  right: 5rem;
  top: 4rem;
}

.adminWrapper {
  height: 50px;
  box-sizing: border-box;
}