.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 8px 12px -4px #1018280f;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
}

.leftSide {
  display: flex;
  gap: 1rem;
  width: 100%;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
}

.backIcon {
  width: 24px;
  cursor: pointer;
}

.title {
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: nowrap;
}
