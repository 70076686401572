.body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.addDescription {
  margin: 0;
  min-height: 210px;
  height: auto;
  border: 1px solid var(--grey300);
  border-radius: 8px;
  color: var(--grey900);
  font-weight: 400;
  line-height: var(--line-height-15);
  overflow: hidden;
  padding: 12px 16px;
  position: relative;
  white-space: pre-line;
}

.aiHelperWrapper {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.buttonDetail {
  margin: 0;
}

.subInformationBox {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin: 12px 0;
  align-items: center;
  color: var(--grey600);
  font-size: 12px;
  font-weight: 400;
}

.icon {
  width: 16px;
  height: 16px;
  stroke: var(--grey500);
}

.dragAndDrop {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttonDetailContainer:has(.subInformationBox).dragAndDrop {
  margin-top: 12px;
}

.aiWrapper {
  position: relative;
}

.aiComponent {
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.aiComponentStatic {
  padding: 0;
  position: static;
}
