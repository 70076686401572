.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  overflow: hidden;
  position: relative;
}

.screenWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .rightSide {
    display: none;
  }

  .wrapper {
    flex-direction: column;
    height: 100vh;
    max-height: 100svh;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
