.cardDetails {
  display: flex;
  gap: 2rem;
  clear: both;
  overflow: auto;
}

.cardDetails .expiration,
.cardDetails .cvv {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cardDetails aside {
  color: var(--grey800);
  font-weight: var(--font-weight-semi);
  margin-bottom: 6px;
}

.payuCardForm {
  background-color: var(--white);
  border: 1px solid var(--grey300);
  border-radius: 6px;
  font-size: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

@media screen and (max-width: 450px) {
  .cardDetails {
    flex-direction: column;
    gap: 1rem;
  }

  .cardDetails .expiration,
  .cardDetails .cvv {
    flex: 1;
  }
}
