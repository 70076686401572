.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  left: 0;
  position: fixed;
  top: 0;
  z-index: 2;
}

.wrapper {
  height: calc(100vh - 220px);
}
