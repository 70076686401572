.body {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  color: var(--secondary500);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  user-select: none;
}

.body:hover {
  color: var(--secondary600);
}

.aiContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  background-color: var(--secondary50);
  border: 1px solid var(--secondary500);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 1rem;

}

.aiContentTip {
  display: flex;
  color: var(--grey800);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
}

.actionsWrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
  width: 100%;
}

.backBtn {
  background-color: var(--grey200);
  border: 1px solid var(--grey600);
  color: var(--grey800);
}

.backBtn:hover {
  background-color: var(--grey300);
  color: var(--grey900);
}

.aiModelsCustomWrapper {
  margin-block: 0;
}

.aiModelSelectWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.aiEngineIcon {
  width: var(--icon-medium);
  height: var(--icon-medium);
  cursor: pointer;
}

.aiContentTipText {
  display: flex;
  flex: 3;
}

.modelWrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  animation: gradientAnimation 3s;
  -webkit-background-clip: text;
  color: var(--grey800);
  cursor: pointer;
  overflow: hidden;

}

.aiEngine {
  display: flex;
  justify-content: flex-end;
  flex: 0.8;
}

.loaderWrapper {
  backdrop-filter: blur(3px);
  inset: 0;
  position: fixed;
  z-index: 2;
}
@keyframes gradientAnimation {
  0% {
    background: -webkit-linear-gradient(45deg, red, blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  25% {
    background: -webkit-linear-gradient(60deg, red, blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  50% {
    background: -webkit-linear-gradient(75deg, red, blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  90% {
    background: -webkit-linear-gradient(90deg, red, blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  100% {
    background: none;
    -webkit-text-fill-color: initial;
  }
}
