.wrapper {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  row-gap: 1rem;
}

.list {
  height: calc(100vh - 320px);
}

.listHeader {
  color: var(--black);
  font-weight: var(--font-weight-semi);
}

.pill {
  background-color: var(--adminOrange400);
  border: none;
  border-radius: 5rem;
  color: var(--black);
  font-size: var(--font-size-sm);
  padding: 6px 0.75rem;
}

.rejected {
  background-color: var(--adminRed400);
}

.pending {
  background-color: var(--adminGreyNormalActive);
}

.cell {
  color: var(--black);
  font-family: var(--manrope);
  font-weight: var(--font-weight-medium);
}