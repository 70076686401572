.actionWrapper {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}

.modalContent {
  max-width: 40%;
}

.text {
  color: var(--grey800);
  font-size: 1rem;
  margin-bottom: 20px;
}

.cancelButton {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
}

.cancelButton:hover {
  background-color: var(--grey100);
}

@media screen and (max-width: 1024px) {
  .modalContent {
    max-width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .modalContent {
    width: 100vw;
    max-width: none;
    left: 0;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
