.invoiceTitle > * {
  color: var(--grey900);
  font-family: var(--manrope);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  margin-block: 0;
  margin-bottom: 0.5rem;  
  }

.invoiceTitle p {
  margin-block: 0;
}

.invoiceTitle:nth-child(2) {
  margin-top: 200px;
}

.address,
.contactData {
  color: var(--grey800);
  font-family: var(--manrope);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
  margin-bottom: 1rem;
}

.actions {
  display: flex;
  gap: 1rem;
  position: relative;
}

.editBtn:disabled,
.deleteBtn:disabled {
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
  border-radius: 0.5rem;
  color: var(--grey500);
  cursor: not-allowed;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid var(--adminOrange500);
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  padding: 24px 32px;
}

.row:nth-child(2) {
 border-color: var(--grey300)
}

.deafultAdressPill {
  border-color: var(--adminOrange500);
  color: var(--adminOrange500);
  margin-left: auto;
}

.invoiceBasicData {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: flex-start;
}
