.select {
  width: 100%;
  flex: 1;
}

.header {
  margin: 20px auto;
  width: 600px;
  color: var(--grey900);
  font-size: var(--display-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-12);
  text-align: center;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 14px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.horizontalLine {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background-color: var(--grey200);
  margin-bottom: 24px;
}

.all {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  margin-top: 24px;
  align-items: center;
  width: 100%;
  color: var(--grey700);
  font-size: var(--font-size-sm);
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.actionsBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 24px;
}

.label {
  color: var(--grey500);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--white);
  overflow-y: auto;
}

.wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  background-color: var(--white);
  border: 1px solid var(--grey200);
  border-radius: 20px;
  box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05), 0px 4px 16px 0px rgba(47, 47, 141, 0.09);
  margin-bottom: 20px;
  padding: 32px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}

.button {
  display: flex;
  gap: 14px;
  width: 175px;
  padding: 10px 24px;
}

.checkbox {
  border: 1px solid var(--secondary500);
}

.backButton {
  width: 175px;
  max-height: 48px;
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
  border-radius: 32px;
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  padding: 10px 24px;
}

.backButton:hover {
  background-color: var(--grey100);
  border: 1px solid var(--grey500);
}

.createBusinessProfile {
  display: flex;
  gap: 5px;
  margin-top: 8px;
  align-items: center;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
  margin-bottom: 12px;
}

.createCardBtn {
  background-color: transparent;
  color: var(--primary500);
  flex: 0.3;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
}

.createCardBtn:hover {
  background-color: transparent;
  color: var(--primary600);
}

.rwdHeader {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header {
    width: 70%;
    font-size: var(--font-size-lg);
    margin-bottom: 16px;
  }

  .wrapper {
    max-height: 100%;
  }

  .form {
    max-width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .header {
    margin-bottom: 16px;
    white-space: break-spaces;
  }

  .wrapper {
    max-height: calc(100vh - 0px);
  }

  .form {
    max-width: 70%;
    height: 100%;
    margin-bottom: auto;
  }

  .actionsBox {
    margin-block: auto;
  }

  .button,
  .backButton {
    width: 150px;
    padding: 8px 16px;
  }
}

@media screen and (max-width: 450px) {
  .header {
    display: none;
  }

  .rwdHeader {
    display: block;
    width: 100%;
    color: var(--grey900);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-15);
    margin-bottom: 24px;
    text-align: center;
  }

  .form {
    width: 100%;
    max-width: 100%;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    box-sizing: border-box;
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
  }

  .wrapper {
    justify-content: flex-start;
    margin-top: 24px;
    max-height: calc(100% - 120px);
    overflow-y: auto;
  }

  .row {
    gap: 8px;
  }

  .row:has(.backButton) {
    flex-direction: column;
    margin-top: auto;
  }

  .actionsBox {
    justify-content: flex-end;
    height: clamp(calc(100% - 90px), 20%, 100%);
    justify-self: flex-end;
  }

  .backButton {
    width: 100%;
  }

  .button {
    width: 100%;
  }

  .createCardBtn {
    margin: 0 auto;
    margin-right: auto;
  }
}
