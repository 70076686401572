.toolsWrapper,
.basicTools {
  display: flex;
  gap: 1.5rem;

}

.toolsWrapper {
  width: 100%;
  max-width: none;
}

.searchInputStyle {
  margin: 0;
  max-width: 20rem;
  min-width: 20rem;
  height: 3rem;
  border: 1px solid var(--grey300);
  border-radius: 2rem;
  box-sizing: border-box;
  padding: 0.25rem 1rem;
}

.pickDateBtn {
  gap: 0.75rem;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  padding: 0.75rem 1.5rem;
}

.pickDateBtn:hover {
  background-color: var(--grey100);
}

.calendar {
  z-index: 2;
}

@media screen and (max-width: 1440px) {
  .basicTools {
    justify-content: space-between;
    min-width: 100%;
  }

  .searchInputStyle {
    max-width: none;
    min-width: auto;
  }
}

@media screen and (max-width: 1200px) {
  .calendar {
    left: 15%;
  }
}

@media screen and (max-width: 1024px) {
  .calendar {
    max-width: 100%;
    left: 10%;
  }
}

@media screen and (max-width: 768px) {
  .calendar {
    position: static;
  }

  .toolsWrapper:has(.calendar) {
    flex-direction: column;
    gap: 1.5rem;
  }
}

@media screen and (max-width: 450px) {
  .basicTools {
    flex-direction: column;
    gap: 1.5rem;
    max-width: 100%;
    flex-wrap: wrap;
  }
}