.opinionActions {
  display: flex;
  gap: 2rem;
  margin-top: 1.5rem;
  align-items: center;
  color: var(--primary500);
  flex-wrap: wrap;
  stroke: var(--primary500);
}

.actionIcon {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  color: var(--priamry500);
  cursor: pointer;
  text-wrap: nowrap;
  user-select: none;
}

.icon {
  width: var(--icon-small);
  height: var(--icon-small);
}

.actionIcon:hover {
  color: var(--primary700);
  stroke: var(--primary700);
}

.withAI {
  color: var(--secondary500);
  stroke: var(--secondary500);
}

.withAI:hover {
  color: var(--secondary600);
  stroke: var(--secondary600);
}

@media screen and (max-width: 600px) {
  .opinionActions {
    justify-content: flex-end;
  }
}
