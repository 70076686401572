.actions {
  display: flex;
  gap: 12px;
  margin: 24px 12px 24px 24px;
  align-items: center;
}

.horizontalLine {
  width: 100%;
  border-bottom: 1px solid var(--grey200);
}

.selectBtn {
  gap: 8px;
  margin-left: auto;
  padding: 10px 24px;
}

.filterActions {
  display: flex;
  gap: 12px;
}

.selectAlternative {
  padding: 8px 20px;
}

.adminPreview {
  justify-content: flex-end;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .actions {
    margin-block: 12px;
  }
}
@media screen and (max-width: 550px) {
  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .selectBtn {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .actions {
    justify-content: flex-start;
    margin: 12px auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
  }
}
