.image {
  margin: 0 auto;
  width: 100%;
  height: 50%;
  aspect-ratio: 1;
  border-radius: 10px;
  margin-bottom: 6px;
  object-fit: cover;
  position: relative;
  stroke: var(--grey500);
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid var(--grey300);
  border-radius: 8px;
  margin-right: 24px;
  overflow: hidden;
  position: relative;
}

.vicinity {
  color: var(--grey500);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-16);
}

.name {
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
}

.popuptext {
  display: flex;
  flex-direction: column;
}

.popupwrapper {
  display: flex;
  flex-direction: column;
  max-width: 170px;
  height: fit-content;
  background-color: var(--primary50);
  border: 2px solid var(--primary300);
  border-radius: 16px;
  bottom: 50%;
  box-shadow: 0px 5px 20px 0px rgba(127, 122, 122, 0.1);
  padding: 16px;
  position: relative;
  z-index: 55;
}

.pill {
  min-height: 25px;
}
@media screen and (max-width: 768px) {
  .wrapper {
    margin-right: 0px;
  }
}
@media screen and (max-width: 450px) {
  .popupwrapper {
    width: 150px;
  }

  .image {
    display: none;
  }
}
