.sectionWrapper {
  display: flex;
  gap: 8vw;
  margin: 8rem auto 0;
  align-items: center;
  width: 100%;
  max-width: 80vw;
  box-sizing: border-box;
}

.sectionWrapper > p {
  color: var(--black);
}

.leftSide,
.rightSide {
  width: 100%;
}

.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--black);
}

.sectionTitle {
  margin: 0 0 0.5rem 0;
  color: var(--primary500);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.sectionHeading {
  margin: 0;
  font-size: var(--display-md);
  font-weight: var(--font-weight-bold);
  letter-spacing: -0.4px;
  line-height: var(--line-height-15);
}

.sectionHeading > span {
  color: var(--secondary500);
}

.leftSide > div > .headingParagraph {
  margin: 0;
  margin-top: 40px;
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}
@media screen and (max-width: 1024px) {
  .sectionWrapper {
    flex-direction: column;
    gap: 8rem;
    margin-top: 70px;
    align-items: flex-start;
    height: auto;
    padding-inline: 1rem;
  }

  .rightSide {
    max-width: 30rem;
  }
}

@media screen and (max-width: 768px) {
  .paragraphs {
    width: auto;
  }
}
@media screen and (max-width: 450px) {
  .sectionTitle {
    font-size: var(--font-size-sm);
  }

  .sectionHeading {
    font-size: var(--font-size-xl);
  }

  .sectionWrapper {
    gap: 3.75rem;
    max-width: none;
  }

  .paragraphs {
    width: auto;
  }
}
