.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 24px 32px;
  height: calc(100vh - 210px);
  background-color: var(--white);
  border-radius: 12px;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--primary200);
  flex-shrink: 0;
  margin-bottom: .5rem;
  padding-bottom: 12px;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}

.pagination {
  margin-top: auto;
  width: 100%;
}

.openLinkIcon {
  width: 18px;
  height: 18px;
  color: var(--primary500);
  cursor: not-allowed;
  pointer-events: none;
}

.payBtn {
  background-color: transparent;
  border: 1px solid var(--primary500);
  border-radius: 2rem;
  color: var(--primary500);
  font-size: var(--font-size-sm);
  padding: 6px 16px;
}

.payBtn:hover {
  background-color: transparent;
  border-color: var(--primary600);
  color: var(--primary600);
}

.actionCell {
  display: flex;
  align-items: center;
}

.adminIcon {
  color: var(--black);
}

.adminBtn {
  background-color: var(--adminOrange400);
  border: none;
  border-radius: 8px;
  color: var(--black);
  font-size: var(--font-size-sm);
  padding: 6px 0.75rem;
}

.adminBtn:disabled {
  background-color: var(--adminGreyNormalActive);
}

.adminBtn:hover,
.adminBtn:disabled:hover {
  background-color: var(--adminGreyNormalActive);
  color: black;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin: 1rem;
    height: calc(100vh - 270px);
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-height: calc(100vh - 270px);
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin: 0;
    height: 100%;
    max-height: calc(100vh - 240px);
    padding: 1rem;
  }
}