.title {
  margin: 3.375rem 0 1.25rem 1rem;
  color: var(--secondary500);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-15);
}

.wrapper {
  padding-bottom: 1rem;
}

.matrixHeading {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: var(--primary500);
  border-radius: 9px 9px 0 0;
  box-sizing: border-box;
  color: var(--white);
  grid-template-columns: repeat(5, 1fr);
  left: 0;
  padding: 2rem 1rem;
  position: sticky;
  top: 0;
  z-index: 3;
}

.planName {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-weight-bold);
}

.googleMatrix {
  padding: 1.25rem 1rem;
}

.googleBusienssCards,
.seoCards {
  color: var(--grey900);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  margin-bottom: 1.5rem;
}

.seoCards {
  margin-top: 1.5rem;
}

.gridItemTitle {
  color: var(--grey900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  margin-block: 1.5rem;
}

.gridItemSubFeature {
  margin: 1.5rem 0 1rem;
  color: var(--grey900);
  font-size: var(--font-size-sm);
}

.wrapperMobile {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.featureMatrixWrapper {
  max-height: 55vh;
  overflow: hidden;
  overflow-y: auto;
}

.featureMatrixWrapper::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobilePreviewHeading {
    max-width: calc(100vw - 2rem);
  }
}
@media screen and (max-width: 450px) {
  .mobilePreviewHeading {
    max-width: none;
  }
}
