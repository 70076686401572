.seletedPlanWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--grey300);
  border-radius: 0.75rem;
  box-shadow: 0px 5px 27px 0px #64646f14;
  padding: 1.5rem;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 1rem;
}

.subscriptionPill {
  background-color: var(--primary50);
  border: none;
  border-radius: 0.25rem;
  color: var(--primary500);
  font-size: var(--font-size-md);
  padding: 0.5rem 1rem;
}

.planPrice {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.pricePerPeriod {
  color: var(--grey600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}

.paymetType {
  color: var(--grey600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
  margin-bottom: 0.5rem;
}

.taxIncluded {
  margin-top: 0.25rem;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
}

@media screen and (max-width: 450px) {
  .seletedPlanWrapper {
    flex-direction: column;
    gap: 1rem;
  }
}
