.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  min-height: 80px;
  background-color: var(--white);
  box-sizing: border-box;
  padding: 18px 24px 18px 24px;
  position: relative;
}

.reportList {
  background-color: var(--primary500);
  border-radius: 32px;
  color: var(--white);
  font-weight: var(--font-weight-medium);
  padding: 10px 24px;
}

.arrowStyle {
  margin-left: 8px;
  order: 2;
  transform: rotate(180deg);
}

.version {
  color: var(--grey500);
  font-size: 12px;
  left: 5px;
  position: absolute;
  top: 5px;
}

.logoWrapper {
  position: relative;
}

.logoSign {
  position: absolute;
  right: -20%;
  top: -15%;
}

.logo {
  width: 164px;
  height: 30px;
  color: #424242;
}



.openDashboard:hover {
  background-color: var(--primary600);
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .logo {
    width: 183px;
    height: 30px;
  }

  .wrapper {
    width: 100%;
    height: 80px;
    min-height: 0px;
    border-bottom: 1px solid var(--primary50);
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
    height: 60px;
    min-height: 60px;
    padding-left: 24px;
  }

  .openDashboard {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
    height: 60px;
    min-height: 60px;
    border-bottom: none;
    padding-left: 16px;
  }

  .logo {
    width: 116px;
    height: 26px;
  }

  .logoSign {
    right: -30%;
  }

  .reportList {
    padding: 4px 16px!important;
  }
}
