.input {
  height: 40px;
}

.userDataWrapper {
  flex-basis: 50%;
}

.dataTitle {
  color: var(--black);
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-semi);
}

.inputDataLabel {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 4px;
}

.dataLabel {
  color: var(--adminGreyDarker);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  margin-bottom: 0.5rem;
}

.basicDataWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.dataValue {
  font-weight: var(--font-weight-semi);
}
