.section {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  padding: 14px;
  user-select: none;
}

.section:first-child {
  margin-top: 24px;
}

.section:hover {
  background-color: var(--primary50);
}

.section:active {
  background-color: var(--primary100);
}

.sectionTitle {
  color: var(--grey800);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  margin-bottom: 8px;
}

.sectionDescription {
  color: var(--grey700);
  line-height: var(--line-height-15);
}

.arrowIcon {
  width: var(--icon-medium);
  height: var(--icon-medium);
  color: var(--primary500);
  padding-top: 12px;
  transform: rotate(270deg);
}

.arrowIcon:active {
  width: 16px;
  height: 16px;
  fill: var(--primary700);
  stroke: var(--primary700);
}

@media screen and (max-width: 450px) {
  .section {
    border: 1px solid var(--primary300);
    margin-block: 24px;
    padding: 14px 16px;
  }
}
